import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getEncuestaByNombre, postEncuesta } from "../../actions/surveys";
import Spinner from "../layout/Spinner";

const Tests = ({ getEncuestaByNombre, postEncuesta, surveys }) => {
  useEffect(() => {
    getEncuestaByNombre("Encuesta Inicial");
  }, [getEncuestaByNombre]);

  const [answers, setAnswers] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const survey = surveys.survey;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      answers.length === survey.preguntas.length &&
      !answers.includes(undefined)
    ) {
      await postEncuesta({ id: survey._id, respuestas: answers });
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <Redirect to="/terapeutas" />;
  }

  return (
    <>
      <div className="w-full">
        <div className="mx-auto text-center text-white bg-higia-azul">
          <div className="container mx-auto px-8">
            <h2 className="pt-6 md:pt-12 text-3xl font-extrabold mx-3">
              ¡Ayúdanos con estas preguntas!
            </h2>
          </div>
        </div>
        <div className="text-higia-azul overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="fill-current shadow-lg w-full wavesShadow"
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
      </div>

      {surveys.loading || survey?.preguntas === undefined ? (
        <Spinner />
      ) : (
        <section
          className="container flex-grow flex flex-col mx-auto font-sans w-11/12"
          styles={{ height: "max-content" }}
        >
          <form className="text-higia-gray-3 font-bold" onSubmit={onSubmit}>
            {survey.preguntas.map((pregunta, noPregunta) => (
              <div key={pregunta.texto} className="mb-8">
                <div className="inline-block flex-none px-4 py-3 rounded-lg border border-solid border-gray-400 mb-3 text-lg">
                  {pregunta.texto}
                </div>
                <div className="ml-2">
                  {Object.entries(pregunta).map(([field, value]) => {
                    if (field === "texto") {
                      return null;
                    } else {
                      return (
                        <div className="mb-2 flex items-baseline" key={field}>
                          <input
                            type="radio"
                            className="h-5 w-5 text-gray-600 higiaRadio"
                            onChange={(e) => {
                              let curAnswers = [...answers];
                              curAnswers[noPregunta] = e.currentTarget.value;
                              setAnswers(curAnswers);
                            }}
                            name={noPregunta}
                            value={field}
                          />
                          <span>{value}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ))}
            <div className="flex justify-center mb-8">
              <input
                type="submit"
                className="w-3/4 lg:w-1/3 rounded-lg px-4 py-4 bg-higia-bermuda text-white cursor-pointer text-lg"
                value="Enviar"
              />
            </div>
          </form>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  surveys: state.surveys,
});

export default connect(mapStateToProps, { getEncuestaByNombre, postEncuesta })(
  Tests
);
