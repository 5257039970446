import React, { useRef, useState, useEffect } from 'react';

const Test = () => {
  const setMercadoPagoPreferences = async () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute(
      'data-preference-id',
      '589788715-2e52aeec-8275-487c-88ee-1a08cff37c08'
    );
    script.async = true;
    script.src =
      'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';
    script.onload = () => {
      console.log('aa');
    };
    document.getElementById('mercadoForm').appendChild(script);
  };

  useEffect(() => {
    setMercadoPagoPreferences();
  }, []);

  return <form action='/procesar-pago' method='POST' id='mercadoForm' />;
};

// const Test = () => {
//   const myDiv = useRef(null);
//   const [state, setState] = useState(1);
//   if (myDiv.current) {
//     let f = document.createElement('form');
//     f.setAttribute('method', 'post');
//     f.setAttribute('action', 'the-url-to-post');
//     f.setAttribute('id', 'mercadoForm');
//     const script = document.createElement('script');
//     script.src = 'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
//     script.async = true; // commenting or uncommenting seems to have no effect
//     script.setAttribute(
//       'data-preference-id',
//       '589788715-2e52aeec-8275-487c-88ee-1a08cff37c08'
//     );
//     f.appendChild(script);
//     // document.getElementById('mercadoForm').appendChild(script);

//     myDiv.current.appendChild(f);
//   }

//   return (
//     <form action='/procesar_pago.php' method='post' id='pay' name='pay'>
//       <fieldset>
//         <p>
//           <label for='description'>Descripción</label>
//           <input
//             type='text'
//             name='description'
//             id='description'
//             value='Ítem seleccionado'
//           />
//         </p>
//         <p>
//           <label for='transaction_amount'>Monto a pagar</label>
//           <input
//             name='transaction_amount'
//             id='transaction_amount'
//             value='100'
//           />
//         </p>
//         <p>
//           <label for='cardNumber'>Número de la tarjeta</label>
//           <input
//             type='text'
//             id='cardNumber'
//             data-checkout='cardNumber'
//             onselectstart='return false'
//             onpaste='return false'
//             onCopy='return false'
//             onCut='return false'
//             onDrag='return false'
//             onDrop='return false'
//             autocomplete='off'
//           />
//         </p>
//         <p>
//           <label for='cardholderName'>Nombre y apellido</label>
//           <input
//             type='text'
//             id='cardholderName'
//             data-checkout='cardholderName'
//           />
//         </p>
//         <p>
//           <label for='cardExpirationMonth'>Mes de vencimiento</label>
//           <input
//             type='text'
//             id='cardExpirationMonth'
//             data-checkout='cardExpirationMonth'
//             onselectstart='return false'
//             onpaste='return false'
//             onCopy='return false'
//             onCut='return false'
//             onDrag='return false'
//             onDrop='return false'
//             autocomplete='off'
//           />
//         </p>
//         <p>
//           <label for='cardExpirationYear'>Año de vencimiento</label>
//           <input
//             type='text'
//             id='cardExpirationYear'
//             data-checkout='cardExpirationYear'
//             onselectstart='return false'
//             onpaste='return false'
//             onCopy='return false'
//             onCut='return false'
//             onDrag='return false'
//             onDrop='return false'
//             autocomplete='off'
//           />
//         </p>
//         <p>
//           <label for='securityCode'>Código de seguridad</label>
//           <input
//             type='text'
//             id='securityCode'
//             data-checkout='securityCode'
//             onselectstart='return false'
//             onpaste='return false'
//             onCopy='return false'
//             onCut='return false'
//             onDrag='return false'
//             onDrop='return false'
//             autocomplete='off'
//           />
//         </p>
//         <p>
//           <label for='installments'>Cuotas</label>
//           <select
//             id='installments'
//             class='form-control'
//             name='installments'
//           ></select>
//         </p>
//         <p>
//           <label for='docType'>Tipo de documento</label>
//           <select id='docType' data-checkout='docType'></select>
//         </p>
//         <p>
//           <label for='docNumber'>Número de documento</label>
//           <input type='text' id='docNumber' data-checkout='docNumber' />
//         </p>
//         <p>
//           <label for='email'>Email</label>
//           <input type='email' id='email' name='email' value='test@test.com' />
//         </p>
//         <input type='hidden' name='payment_method_id' id='payment_method_id' />
//         <input type='submit' value='Pagar' />
//       </fieldset>
//     </form>
//   );
// };

// const Test = () => {
//   useEffect(() => {
//     let f = document.createElement('form');
//     f.setAttribute('method', 'post');
//     f.setAttribute('action', 'the-url-to-post');
//     f.setAttribute('id', 'mercadoForm');
//     document.getElementById('test').append(f);

//     const script = document.createElement('script');
//     script.src =
//       'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';
//     script.async = true; // commenting or uncommenting seems to have no effect
//     script.setAttribute(
//       'data-preference-id',
//       '589788715-2e52aeec-8275-487c-88ee-1a08cff37c08'
//     );
//     document.getElementById('mercadoForm').appendChild(script);
//   }, []);
//   return <div id='test'></div>;
// };

export default Test;
