import React, { useEffect } from 'react';
import { changeNavColors, changeNavLogo } from '../../../actions/nav';
import { connect } from 'react-redux';
import topics from '../TopicsIndex';

const PrimerPost = ({ match, changeNavColors, changeNavLogo }) => {
  const topic = match.params.topic;
  const post = topics[topic].posts.filter(
    (post) => post.link === 'hablemos-sobre-tdah'
  )[0];

  useEffect(() => {
    changeNavColors(topics[topic].bg);
    changeNavLogo({ showLogo: false, previous: topics[topic].previous });
    return () => {
      changeNavColors('');
      changeNavLogo({ showLogo: true, previous: '' });
    };
  }, [changeNavColors, changeNavLogo, topic]);

  return (
    <div className='container mx-auto md:w-6/12'>
      <div className='mx-8 md:mx-0'>
        <div className='font-bold mt-6 text-lg'>{post.title}</div>
        <div className='flex items-center justify-between mt-4'>
          <div className='h-16 w-16 flex justify-center items-center rounded-full bg-higia-bermuda'>
            {post.img ?? (
              <svg
                height='25'
                viewBox='0 0 25 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.8751 1.62754H3.12585C2.71147 1.62754 2.31406 1.79901 2.02104 2.10424C1.72803 2.40946 1.56342 2.82343 1.56342 3.25508V22.1118L4.68829 18.8567C5.27419 18.2462 6.06888 17.9031 6.89758 17.9029H21.8751C22.2895 17.9029 22.6869 17.7315 22.9799 17.4263C23.2729 17.121 23.4376 16.7071 23.4376 16.2754V3.25508C23.4376 2.82343 23.2729 2.40946 22.9799 2.10424C22.6869 1.79901 22.2895 1.62754 21.8751 1.62754ZM3.12585 0C2.29709 0 1.50226 0.342945 0.916232 0.953391C0.330204 1.56384 0.000976563 2.39178 0.000976562 3.25508L0.000976563 24.0762C0.0010094 24.2372 0.0469007 24.3946 0.132842 24.5285C0.218784 24.6623 0.340912 24.7666 0.483769 24.8281C0.626627 24.8896 0.783791 24.9056 0.935371 24.874C1.08695 24.8424 1.22613 24.7647 1.3353 24.6507L5.79294 20.0074C6.08588 19.7021 6.48323 19.5306 6.89758 19.5305H21.8751C22.7039 19.5305 23.4987 19.1875 24.0847 18.5771C24.6708 17.9666 25 17.1387 25 16.2754V3.25508C25 2.39178 24.6708 1.56384 24.0847 0.953391C23.4987 0.342945 22.7039 0 21.8751 0L3.12585 0Z'
                  fill='black'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.68823 5.69658C4.68823 5.48076 4.77054 5.27377 4.91705 5.12116C5.06355 4.96855 5.26226 4.88281 5.46945 4.88281H19.5314C19.7386 4.88281 19.9373 4.96855 20.0838 5.12116C20.2303 5.27377 20.3126 5.48076 20.3126 5.69658C20.3126 5.91241 20.2303 6.11939 20.0838 6.27201C19.9373 6.42462 19.7386 6.51035 19.5314 6.51035H5.46945C5.26226 6.51035 5.06355 6.42462 4.91705 6.27201C4.77054 6.11939 4.68823 5.91241 4.68823 5.69658ZM4.68823 9.76543C4.68823 9.54961 4.77054 9.34262 4.91705 9.19001C5.06355 9.0374 5.26226 8.95166 5.46945 8.95166H19.5314C19.7386 8.95166 19.9373 9.0374 20.0838 9.19001C20.2303 9.34262 20.3126 9.54961 20.3126 9.76543C20.3126 9.98126 20.2303 10.1882 20.0838 10.3409C19.9373 10.4935 19.7386 10.5792 19.5314 10.5792H5.46945C5.26226 10.5792 5.06355 10.4935 4.91705 10.3409C4.77054 10.1882 4.68823 9.98126 4.68823 9.76543ZM4.68823 13.8343C4.68823 13.6185 4.77054 13.4115 4.91705 13.2589C5.06355 13.1063 5.26226 13.0205 5.46945 13.0205H13.2816C13.4888 13.0205 13.6875 13.1063 13.8341 13.2589C13.9806 13.4115 14.0629 13.6185 14.0629 13.8343C14.0629 14.0501 13.9806 14.2571 13.8341 14.4097C13.6875 14.5623 13.4888 14.6481 13.2816 14.6481H5.46945C5.26226 14.6481 5.06355 14.5623 4.91705 14.4097C4.77054 14.2571 4.68823 14.0501 4.68823 13.8343Z'
                  fill='black'
                />
              </svg>
            )}
          </div>
          <div className='flex flex-col ml-5 flex-grow'>
            <div>{post.author}</div>
            <div>Psicoterapeuta y Psiquiatra</div>
          </div>
        </div>
        <div className='mt-6 mb-8 prose'>
          {/* start */}
          <p>
            Ahora que terminó el año escolar y los chicos están de vacaciones,
            es momento de evaluar, que es lo que podemos mejorar con nuestros
            hijos, uno de los temas más comunes en la etapa escolar son los
            problemas de conducta y atención, que muchas veces provocan un bajo
            rendimiento escolar, que sin pretender restarle importancia, no es
            el mayor de los problemas; como sí lo son las graves implicancias en
            el autoestima y el sufrimiento que esto causa en los chicos ya sea
            por las dificultades inherentes experimentan como por la marginación
            y a veces hasta maltrato de los que son víctimas por parte de pares
            o figuras de autoridad.
          </p>
          <p>
            Aquí trataremos de explicarles un poco qué es la Hiperactividad.
          </p>
          <p>
            El comportamiento hiperactivo se refiere a actividad constante,
            mayor movimiento, dificultades para prestar atención y distraerse
            con facilidad, impulsividad, dificultad para postergar recompensas,
            muchas veces son esos niños que no pueden hacer el turno para el
            tobogán, o los que interrumpen el show infantil, el que distrae a
            sus compañeros en clase, el que quiere salir primero, incluso
            atropellando a sus compañeros.
          </p>
          <p>
            Son los niños a los que los otros acusan como el travieso, el
            inquieto, el distraído y a veces hasta el malo; esto por supuesto
            causa un gran sufrimiento en ellos, los padres que también sufren
            con ellos agobiados, no pueden manejar la situación que los desborda
            y lejos de contribuir positivamente, empeoran las conductas.
          </p>
          <p>
            Si eso está pasando con Uds. Y sus hijos, deben buscar ayuda
            profesional, en los siguientes días iremos abordando cada tema, para
            orientarlos y absolver alguna dudas
          </p>
          <h3 id='tdah'>TDAH</h3>
          <p>
            Identificar la conducta hiperactiva muchas veces depende del
            observador, lo que parece excesivo para una persona puede no parecer
            tanto para otra, esto va a depender mucho de la tolerancia y del
            contexto social, entonces, lo que debemos valorar, es si esta
            inquietud perturba o interfiere en el desarrollo de los niños, es
            decir, sí limita su desarrollo académico social o familiar.
          </p>
          <p>Algunas características a tener en cuenta:</p>
          <ul>
            <li>
              <p>
                Inquietud o movimiento constante, le cuesta permanecer tranquilo
                aun en circunstancias que así lo ameritan, como estar en medio
                de una clase escolar.
              </p>
            </li>
            <li>
              <p>
                Hablar en exceso, interrumpe permanentemente las conversaciones
                de compañeros o adultos, inicia conversaciones en momentos no
                adecuados.
              </p>
            </li>
            <li>
              <p>Tiene dificultad finalizar tareas</p>
            </li>
          </ul>
          <p>
            A menudo la hiperactividad se cree que este es más un problema para
            maestros y padres que para el propio niño; sin embargo muchas veces
            los niños hiperactivos son infelices, e incluso depresivos; dado que
            su conducta puede provocar que sean el blanco del acoso escolar o
            bullying, ya sea con actos, como la exclusión o el maltrato físico,
            o con palabras como los sobrenombres y apelativos descalificativos;
            o que reciban castigos tanto en casa como en el colegio.
          </p>
          <p>
            El sufrimiento para padres e hijos es innecesario…. una atención
            adecuada y oportuna puede ayudar a resolver el problema, brindándole
            a sus niños un desarrollo integral más sano y armonioso y una mejor
            calidad de vida a toda la familia.
          </p>
          <p>
            El movimiento excesivo (comportamiento hiperquinético) suele
            disminuir a medida que el niño crece. Puede desaparecer por completo
            en la adolescencia.
          </p>
          <h3 id='déficit-de-atención'>Déficit de atención:</h3>
          <p>
            La falta de atención es de los problemas, menos sencillos de
            percibir, a diferencia de la hiperactividad, en el que las quejas
            son múltiples y en diferentes escenarios, la falta de atención es
            bastante más silente, salvo por la angustia que provoca a quien lo
            sufre debido a las dificultades de aprendizaje, que en ocasiones
            debido a la edad ni ellos perciben; pudiendo desembocar en problemas
            de autoestima e interacción social.
          </p>
          <p>Las características más comunes son:</p>
          <ul>
            <li>
              <p>se distrae con mucha facilidad</p>
            </li>
            <li>
              <p>
                dificultad para mantener la atención durante un tiempo
                prolongado
              </p>
            </li>
            <li>
              <p>
                limitación en las actividades grupales, por dificultades para
                seguir indicaciones
              </p>
            </li>
            <li>
              <p>no presta atención, ni percibe los detalles</p>
            </li>
            <li>
              <p>le cuesta finalizar tareas</p>
            </li>
            <li>
              <p>le cuesta escuchar, seguir órdenes e instrucciones</p>
            </li>
            <li>
              <p>desorganizado en sus tareas y actividades</p>
            </li>
            <li>
              <p>suele perder u olvidar objetos</p>
            </li>
            <li>
              <p>cambia frecuentemente de conversación</p>
            </li>
          </ul>
          <p>
            Este déficit de atención suele evidenciarse al inicio de la etapa
            escolar, debido a que se requiere realizar actividades más complejas
            y que implican un tiempo de atención sostenida, que no son
            necesarias en la etapa pre-escolar.
          </p>
          <p>
            Si su hijo o hija presenta estas dificultades, es probable que este
            teniendo problemas de déficit de atención, en cuyo caso requerirá
            intervención profesional, no olviden que todo aquello que perturba
            el mundo cognitivo de sus hijos, no solo afecta su aprendizaje, sino
            también influye en su mundo social y emocional, muchas veces provoca
            en ellos un gran sufrimiento, pues al no entender lo que ocurre
            suelen, responsabilizarse y auto descalificarse.
          </p>
          <p>
            Recibir atención a tiempo puede hacer la diferencia, acuda a un
            profesional.
          </p>
          <h3 id='impulsividad'>Impulsividad</h3>
          <p>
            La impulsividad es probablemente el síntoma menos frecuente de los
            tres síntomas nucleares del TDAH.
          </p>
          <p>La persona que padece impulsividad se caracteriza por que:</p>
          <ul>
            <li>
              <p>es impaciente</p>
            </li>
            <li>
              <p>tiene problemas para esperar su turno</p>
            </li>
            <li>
              <p>no piensa antes de actuar</p>
            </li>
            <li>
              <p>interrumpe constantemente a los demás</p>
            </li>
            <li>
              <p>tiene respuestas prepotentes: espontáneas y dominantes</p>
            </li>
            <li>
              <p>tiende a “toquetearlo” todo</p>
            </li>
            <li>
              <p>suele tener conflictos con los adultos</p>
            </li>
          </ul>
          <p>
            La impulsividad se refiere fundamentalmente a la dificultad para
            pensar las cosas antes de actuar. Esto supone un gran problema, ya
            que el paciente puede ponerse en peligro y vivir situaciones
            conflictivas principalmente en la edad adulta.
          </p>
          <p>
            El TDAH “puro” es poco frecuente. Cuando hablamos de TDAH “puro” nos
            referimos a que el trastorno se presente sin ningún otro trastorno
            asociado (comorbilidad). De hecho, se estima que el 70% de los
            pacientes con TDAH presentan alguna comorbilidad o trastorno
            psiquiátrico asociado.
          </p>
          <p>
            Es importante tener esto en cuenta, ya que cuando el TDAH está
            asociado a otros trastornos comórbidos, el diagnóstico es más
            complicado, la evolución de los síntomas empeora y la respuesta al
            tratamiento puede ser menor.
          </p>
          <h3 id='no-todo-lo-que-parece-tdah-lo-es'>
            No todo lo que parece TDAH lo es
          </h3>
          <p>
            Es frecuente encontrar estas características de falta de atención,
            hiperactividad o impulsividad en conductas propias de la infancia,
            puesto que es natural que los niños estén muy activos, presten poca
            atención, escuchen poco… Pero es importante diferenciar entre un
            comportamiento “normal” y uno que no lo es.
          </p>
          <p>
            Para que se pueda plantear un posible diagnóstico de TDAH, se deben
            cumplir los siguientes criterios marcados por el DSM-5 (Manual
            Diagnóstico y Estadístico de Trastornos Mentales, quinta edición):
          </p>
          <ul>
            <li>
              <p>
                manifiesta estas conductas o algunas de ellas de forma
                desproporcionada comparado con los otros niños de su edad y
                respecto a su grado de desarrollo.
              </p>
            </li>
            <li>
              <p>
                está presente desde una edad temprana (antes de los 12 años).
              </p>
            </li>
            <li>
              <p>
                afecta en al menos dos ambientes distintos de la vida del niño:
                escolar, social y/o familiar.
              </p>
            </li>
            <li>
              <p>deteriora significativamente su calidad de vida.</p>
            </li>
            <li>
              <p>
                no es causado por un problema médico, tóxico u otro problema
                psiquiátrico.
              </p>
            </li>
          </ul>
          <p>
            Es importante acudir a un profesional de la salud especializado en
            TDAH para que pueda realizar un diagnóstico clínico apropiado y
            evitar así un diagnóstico erróneo.
          </p>
          <h3 id='síntomas-nucleares-del-tdah'>Síntomas nucleares del TDAH</h3>
          <p>
            La hiperactividad es probablemente uno de los síntomas más fáciles
            de reconocer, por su evidencia y por ser el más conocido por el
            público en general.
          </p>
          <p>La persona que padece hiperactividad se caracteriza por que:</p>
          <ul>
            <li>se mueve en momentos en los que no resulta adecuado</li>
            <li>le cuesta permanecer quieto cuando es necesario</li>
            <li>habla en exceso</li>
            <li>
              hace ruidos constantemente, incluso en actividades tranquilas
            </li>
            <li>tiene dificultad para relajarse</li>
            <li>cambia de actividad sin finalizar ninguna</li>
            <li>tiene falta de constancia</li>
          </ul>
          <p>
            La hiperactividad puede manifestarse de forma distinta en las
            diferentes etapas de la vida, aunque la descripción de los síntomas
            sí que permanece igual para niños y adultos.
          </p>
          <p>
            En el Proyecto PANDAH se realizó una encuesta poblacional en la que
            se detectó que en la sociedad en general ha surgido la tendencia a
            trivializar el trastorno, utilizándose de forma superficial el
            término “hiperactivo” para personas o estados puntuales (“Este niño
            es hiperactivo”, para describir que no para quieto).
          </p>

          {/* end */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { changeNavColors, changeNavLogo })(
  PrimerPost
);
