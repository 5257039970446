import React, { useState } from 'react';
import { modifyClient } from '../../actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Profile = ({ auth, modifyClient }) => {
  const user = auth.user;
  const [data, setData] = useState(user);
  const { nombres, ciudad, celular, meta } = data;

  const onSubmit = async (e) => {
    e.preventDefault();
    await modifyClient({ nombres, celular, meta, ciudad });
    window.location.reload();
  };

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  if (auth.isSpecialist) {
    return <Redirect to='/profilespecialist' />;
  }

  return (
    <section
      className='container px-8 flex-grow flex flex-col mx-auto font-sans'
      styles={{ height: 'max-content' }}
    >
      <div className='mt-6'>
        <div className='font-bold text-4xl text-higia-cyan'>
          Hola{' '}
          <span className='text-higia-gray-3'>
            {user.nombres.split(' ')[0]}
          </span>
        </div>
        <div className='md:inline-block mt-2 mb-3 py-2 px-5 border-2 border-solid border-gray-400 rounded-lg'>
          "{user.meta ? user.meta : 'No estás sol@. Estoy aquí para ti'}"
        </div>
      </div>
      <form
        className='mb-3 mt-6'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <label className='block font-bold text-lg'>Nombres:</label>
          <input
            type='text'
            className='bg-gray-200 rounded-lg mt-1 px-2 w-full'
            placeholder='Nombres'
            name='nombres'
            value={data.nombres}
            onChange={onChange}
          />
        </div>
        <div className='mt-4'>
          <label className='block font-bold text-lg'>Ciudad:</label>
          <input
            type='text'
            className='bg-gray-200 rounded-lg mt-1 px-2 w-full'
            placeholder='Ciudad'
            name='ciudad'
            value={data.ciudad ? data.ciudad : ''}
            onChange={onChange}
          />
        </div>
        <div className='mt-4'>
          <label className='block font-bold text-lg'>Celular:</label>
          <input
            type='text'
            className='bg-gray-200 rounded-lg mt-1 px-2 w-full'
            placeholder='celular'
            name='celular'
            value={data.celular}
            onChange={onChange}
          />
        </div>
        <div className='mt-4'>
          <label className='block font-bold text-lg'>Qué deseo lograr:</label>
          <input
            type='text'
            className='bg-gray-200 rounded-lg mt-1 px-2 w-full'
            placeholder='Deseo ser más...'
            name='meta'
            value={data.meta ? data.meta : ''}
            onChange={onChange}
          />
        </div>
        <input
          type='submit'
          onClick={onSubmit}
          className='cursor-pointer mt-8 bg-higia-bermuda text-white text-xl w-full h-16 rounded-lg'
          value='Guardar Cambios'
        />
      </form>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifyClient })(Profile);
