import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import specialists from './specialists';
import checkout from './checkout';
import surveys from './surveys';
import nav from './nav';

export default combineReducers({
  alert,
  auth,
  specialists,
  checkout,
  surveys,
  nav,
});
