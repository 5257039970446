import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const RegisterSuccess = ({ auth }) => {
  const user = auth.user;
  return (
    user && (
      <>
        <section
          className="container px-8 flex-grow flex flex-col mx-auto font-sans"
          styles={{ height: "max-content" }}
        >
          <div className="flex h-4 items-center mt-6 border-b-2 border-gray-300 pb-4 mb-8"></div>
          <h2 className="text-higia-cyan font-extrabold text-center text-3xl mb-5">
            ¡Hola {user.nombres.split(" ")[0]}!
          </h2>
          <div className="text-center text-xl text-higia-gray-3 font-bold mx-auto md:w-1/2">
            Gracias por unirte a Higia. Queremos conectarte con el especialista
            que mejor conecte contigo y pueda ayudarte.
          </div>

          <Link
            to="/tests"
            className="mt-10 flex flex-col items-center mx-auto py-8 px-10 bg-higia-cyan rounded-lg shadow-lg"
          >
            <svg
              width="44"
              height="45"
              viewBox="0 0 44 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.4 9H0V40.5C0 42.975 1.98 45 4.4 45H35.2V40.5H4.4V9ZM39.6 0H13.2C10.78 0 8.8 2.025 8.8 4.5V31.5C8.8 33.975 10.78 36 13.2 36H39.6C42.02 36 44 33.975 44 31.5V4.5C44 2.025 42.02 0 39.6 0ZM37.4 20.25H15.4V15.75H37.4V20.25ZM28.6 29.25H15.4V24.75H28.6V29.25ZM37.4 11.25H15.4V6.75H37.4V11.25Z"
                fill="white"
              />
            </svg>
            <div className="mt-4 text-white text-xl leading-none">Tomar</div>
            <div className="text-white text-xl leading-none">Encuesta</div>
          </Link>
          <Link
            to="/terapeutas"
            className="mt-10 flex flex-col items-center mx-auto py-4 px-5 bg-higia-cyan rounded-lg shadow-lg"
          >
            <div className="text-white text-xl leading-none">Omitir</div>
          </Link>
        </section>
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RegisterSuccess);
