import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import laidDown from "../../img/laidDown.jpg";
import api from "../../utils/api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ResetPasswordToken = ({ match, isAuthenticated, alerts }) => {
  const [formData, setFormData] = useState({
    newPw: "",
    newPw2: "",
    token: match.params.token,
  });
  const [response, setResponse] = useState("");

  const { newPw, newPw2 } = formData;
  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (hasChecks) {
      setResponse("Procesando");
      const res = await api.post("/resetPassword/doreset", formData);
      setResponse(res.data.msg);
    }
  };

  const hasChecks = newPw === newPw2 && newPw.length >= 6;

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <header
        className={`bg-no-repeat bg-cover relative header-login flex flex-col-reverse`}
        style={{
          backgroundImage: `url(${laidDown})`,
        }}
      >
        <div className="text-white relative overflow-hidden">
          <svg
            className="fill-current relative"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 62"
            transform="scale(1,-1)"
            style={{ bottom: "-2px" }}
          >
            <path d="M0 41.32l35.56 3.45c35.55 3.33 106.66 10.43 177.77 5.17 71.11-5.07 142.23-22.5 213.34-22.41 71.11-.09 142.22 17.34 177.77 25.83 2.37.57 14.23 3.45 35.56 8.62V0H0v41.32z" />
          </svg>
        </div>
      </header>
      <section
        className="container flex-grow flex flex-col mx-auto font-sans"
        styles={{ height: "max-content" }}
      >
        <h2 className="text-higia-gray-3 font-bold text-center mb-5 mt-4 w-8/12 mx-auto leading-none">
          Ingresa la nueva contraseña que deseas utilizar. Recuerda que debe
          tener como mínimo 6 caracteres.
        </h2>

        <form className="flex flex-col items-center mt-4" onSubmit={onSubmit}>
          <div className="w-3/4 lg:w-1/3 px-4 py-3 rounded-lg border border-solid border-gray-400">
            <input
              className="outline-none w-full bg-white"
              type="password"
              autoComplete="new-password"
              placeholder="Ingresa tu nueva contraseña"
              name="newPw"
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div className="w-3/4 lg:w-1/3 px-4 py-3 mt-3 rounded-lg border border-solid border-gray-400 mb-2">
            <input
              className="outline-none w-full bg-white"
              type="password"
              autoComplete="new-password"
              placeholder="Repite tu nueva contraseña"
              name="newPw2"
              value={email}
              onChange={onChange}
              required
            />
          </div>
          {newPw && newPw.length < 6 ? (
            <div className="text-sm text-red-400">
              La contraseña tiene menos de 6 caracteres.
            </div>
          ) : null}
          {newPw && newPw2 && newPw !== newPw2 ? (
            <div className="text-sm text-red-400">
              Las contraseñas no son iguales
            </div>
          ) : null}
          <input
            type="submit"
            className={`${
              hasChecks ? "bg-higia-bermuda cursor-pointer" : "bg-higia-gray-2"
            } w-3/4 lg:w-1/3 mt-4 rounded-lg px-4 py-4 text-white text-lg outline-none`}
            value="Reestablecer contraseña"
          />
        </form>
        <div className="text-center mt-4 text-lg">
          {response ? (
            <>
              <div className="text-higia-gray-3">{response}</div>
              <Link to="/login" className="text-higia-bermuda mt-3 underline">
                Regresar
              </Link>
            </>
          ) : null}
        </div>
      </section>
    </>
  );
};

ResetPasswordToken.propTypes = {
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  alerts: state.alert,
});

export default connect(mapStateToProps)(ResetPasswordToken);
