import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import Flicking from '@egjs/react-flicking';
import { connect } from 'react-redux';
import { isAfter } from 'date-fns';
import { add } from 'date-fns';

const Home = ({ auth }) => {
  const [redirection, setRedirection] = useState(false);
  const user = auth.user;

  let orderedSes = false;
  if (user) {
    orderedSes = Object.entries(user.sessions)
      .sort((a, b) => (a[1].horaPactada > b[1].horaPactada ? 1 : -1))
      .filter((a) =>
        // Will still appear after 30min
        isAfter(
          add(parseISO(a[1].horaPactada), {
            minutes: 200,
          }),
          new Date()
        )
      );
  }

  const selectSes = ({ index }) => {
    setRedirection(
      <Redirect push to={`/sesion/${orderedSes[(index - 1) / 2][1]._id}`} />
    );
  };

  if (!auth.isSpecialist) {
    return <Redirect to='/home' />;
  }

  if (redirection) {
    return redirection;
  }

  return user ? (
    <section
      className='container px-8 flex-grow flex flex-col mx-auto font-sans'
      styles={{ height: 'max-content' }}
    >
      <div className='mt-6'>
        <div className='font-bold text-4xl text-higia-cyan'>
          Hola{' '}
          <span className='text-higia-gray-3'>
            {user.nombres.split(' ')[0]}
          </span>
        </div>
        <div className='md:inline-block mt-2 mb-3 py-2 px-5 border-2 border-solid border-gray-400 rounded-lg'>
          "{user.meta ? user.meta : 'No estás sol@. Estoy aquí para ti'}"
        </div>
      </div>

      <div className='flex flex-col mt-6 text-lg'>
        <div className='flex items-center mb-3'>
          <div className='font-bold mr-2'>Mis sesiones</div>
          <svg
            width='19'
            height='21'
            viewBox='0 0 19 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.375 9.4585H4.29167V11.5418H6.375V9.4585ZM10.5417 9.4585H8.45833V11.5418H10.5417V9.4585ZM14.7083 9.4585H12.625V11.5418H14.7083V9.4585ZM16.7917 2.16683H15.75V0.0834961H13.6667V2.16683H5.33333V0.0834961H3.25V2.16683H2.20833C1.05208 2.16683 0.135417 3.10433 0.135417 4.25016L0.125 18.8335C0.125 19.9793 1.05208 20.9168 2.20833 20.9168H16.7917C17.9375 20.9168 18.875 19.9793 18.875 18.8335V4.25016C18.875 3.10433 17.9375 2.16683 16.7917 2.16683ZM16.7917 18.8335H2.20833V7.37516H16.7917V18.8335Z'
              fill='#868686'
            />
          </svg>
        </div>

        <Flicking
          onSelect={(e) => {
            selectSes(e);
          }}
          tag='div'
          viewportTag='div'
          cameraTag='div'
          classPrefix='eg-flick-ses'
          deceleration={0.0075}
          horizontal={true}
          isConstantSize={true}
          defaultIndex={0}
          inputType={['touch', 'mouse']}
          bounce={10}
          autoResize={false}
          adaptive={false}
          zIndex={0}
          bound={true}
          overflow={false}
          hanger={'0%'}
          anchor={'0%'}
          gap={0}
          moveType={{ type: 'freeScroll', count: 1 }}
          collectStatistics={false}
        >
          <div className='w-4'></div>
          {orderedSes.length === 0 ? (
            <div className='bg-higia-azul text-white font-bold rounded-lg text-center w-32 break-words whitespace-normal'>
              No tienes sesiones agendadas
            </div>
          ) : null}
          {orderedSes &&
            orderedSes.map(([index, sesion], mapIndex) => {
              const sesionDate = parseISO(sesion.horaPactada);

              return (
                <Fragment key={index}>
                  <div
                    className={`w-32 h-32 cursor-pointer ${
                      mapIndex === 0 ? 'bg-higia-cyan' : 'bg-higia-azul'
                    } capitalize rounded-lg text-lg text-center break-words whitespace-normal text-white flex flex-col justify-center`}
                  >
                     <b>
                      {sesion.nombreCliente}
                    </b>
                    <b>
                      {format(sesionDate, 'EEEE', {
                        locale: es,
                      })}
                    </b>
                    {format(sesionDate, "d 'de' LLLL", {
                      locale: es,
                    })}{' '}
                    {format(sesionDate, 'HH:mm')}
                  </div>
                  <div className='w-4'></div>
                </Fragment>
              );
            })}
        </Flicking>
      </div>

      <div className='h-12'></div>
    </section>
  ) : null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
