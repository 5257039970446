import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Progress from './Progress';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const Confirmed = ({ user, checkout, specialists }) => {
  if (!checkout.success) {
    return <Redirect to='/home' />;
  }

  const info = specialists.specialist;
  const sesionDate = checkout.horaPactada;

  const whatsappString = `
_*Hola Higia!*_%20%0A
Soy *${user.nombres}*, tengo una cita con *${info.nombres}* el *${format(
    checkout.horaPactada,
    "d 'de' LLLL",
    {
      locale: es,
    }
  )}* a la(s) *${format(checkout.horaPactada, 'hh:mm aaaa', {
    locale: es,
  })}*, me gustaría realizar el pago de la sesión por aquí.%20%0A%20%0A
*Email:* ${user.email}%20%0A
*Tarifa:* ${
    checkout.tarifa.split(' ').length > 1
      ? checkout.tarifa.split(' ')[1]
      : checkout.tarifa.split(' ')[0]
  }
  `;
  return (
    <>
      <section className='container px-8 pt-8 flex flex-col mx-auto font-sans'>
        <span className='font-bold mb-2'>Confirmación</span>
        <Progress selected={3} />
        <div className='mt-12 mb-6 font-bold mx-auto text-2xl text-higia-cyan'>
          ¡Sesión confirmada!
        </div>
        <img
          className='h-32 w-32 object-cover rounded-full mx-auto border-higia-bermuda border-8'
          src={info.avatar}
          alt='Foto de Perfil'
        />
        <div className='mx-auto text-lg font-bold mt-8 mb-4'>
          Tienes una cita confirmada con
        </div>
        <div className='mx-auto text-xl font-bold text-center'>
          {info.nombres} el
          {format(sesionDate, ' EEEE', {
            locale: es,
          })}{' '}
          {format(sesionDate, "d 'de' LLLL", {
            locale: es,
          })}
          {' a las '}
          {format(sesionDate, 'HH:mm')}
        </div>
        <div className='mx-auto mt-6 text-lg text-higia-gray-3'>
          ¡Muchas gracias por confiar en Higia!
        </div>
      </section>
      <div
        className='mt-6 mb-0 py-4 font-bold text-lg flex justify-center'
        style={{ backgroundColor: '#CEE7E2' }}
      >
        <a
          href={`https://api.whatsapp.com/send?phone=+51915091096&text=${whatsappString}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Comunicate con Higia
        </a>
      </div>
      <div
        className='mt-2 mb-8 py-4 font-bold text-lg flex justify-center'
        style={{ backgroundColor: '#CEE7E2' }}
      >
        <a href='https://higia.pe/home'>De vuelta a Casa</a>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
  checkout: state.checkout,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Confirmed);
