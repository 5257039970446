import React, { useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useCountUp } from "react-countup";
import { useInView } from "react-intersection-observer";
import { setCameFrom } from "../../actions/auth";
import { connect } from "react-redux";
import Footer from "./Footer";
import "react-multi-carousel/lib/styles.css";
import "./Landing.css";
import laidDown from "../../img/laidDown.jpg";
import giovany from "../../img/giovany.png";
import kira from "../../img/kira.jpg";
import jesus from "../../img/jesus.png";
import chilling from "../../img/chilling.svg";
import equipu from "../../img/equipu.jpg";
import hacktech from "../../img/hacktech.jpg";
import directv from "../../img/directv.jpg";
import escuelaplus from "../../img/escuelaplus.jpg";
import saludablemente from "../../img/saludablemente.jpg";
import aiesec from "../../img/aiesec.jpg";
import world from "../../img/world.svg";
import hearthand from "../../img/hearthand.svg";
import clock from "../../img/clock.svg";

const Landing = ({ setCameFrom, location }) => {
  const [impacto, setImpacto] = useState({
    imp1: false,
    imp2: false,
    imp3: false,
  });
  const { imp1, imp2, imp3 } = impacto;

  const [impacto1Ref, impacto1InView] = useInView();
  const [impacto2Ref, impacto2InView] = useInView();
  const [impacto3Ref, impacto3InView] = useInView();

  const { countUp: count1, start: start1 } = useCountUp({
    start: 0,
    end: 570,
    delay: 10000,
    duration: 3,
  });
  const { countUp: count2, start: start2 } = useCountUp({
    start: 0,
    end: 18000,
    delay: 10000,
    duration: 3,
  });
  const { countUp: count3, start: start3 } = useCountUp({
    start: 0,
    end: 20,
    delay: 10000,
    duration: 3,
  });

  if (impacto1InView && !imp1) {
    setImpacto({ ...impacto, imp1: true });
    start1();
  }
  if (impacto2InView && !imp2) {
    setImpacto({ ...impacto, imp2: true });
    start2();
  }
  if (impacto3InView && !imp3) {
    setImpacto({ ...impacto, imp3: true });
    start3();
  }

  const responsive = {
    every: {
      breakpoint: { max: 10000, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <header
        className="bg-no-repeat bg-cover relative header-landing"
        style={{
          backgroundImage: `url(${laidDown})`,
          height: "24rem",
        }}
      >
        <div className="flex items-center justify-center">
          <div className="mx-2 text-center">
            <h1 className="mt-12 font-bold text-2xl xs:text-3xl sm:text-4xl">
              <span className="text-higia-gray-3">¡Estamos aquí para ti!</span>
            </h1>
            <h2 className="font-bold text-md xs:text-2xl sm:text-3xl leading-tight">
              <div className="text-higia-gray-3">
                No tienes que <br />
                enfrentar todo tú solo
              </div>
            </h2>
            <div className="mt-40 sm:mt-32 inline-flex">
              <Link
                to="/register"
                className="px-6 py-2 my-5 mx-2 bg-higia-cyan rounded-lg font-bold text-white text-xl"
                style={{ borderRadius: "3rem" }}
              >
                Quiero Empezar
              </Link>
            </div>
          </div>
        </div>
      </header>

      <section className="bg-white">
        <div className="text-white bg-higia-bermuda flex flex-col pt-8 items-center">
          <h3 className="w-8/12 text-center text-xl font-bold leading-tight">
            Tu tranquilidad y estabilidad emocional a un Hola de distancia
          </h3>
          <div className="w-8/12 text-center text-lg mt-2">
            Terapia psicológica online
          </div>
        </div>
        <div className="text-higia-bermuda overflow-hidden relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className={`fill-current w-full wavesShadow relative`}
            style={{ top: "-2px" }}
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-higia-bermuda text-center text-lg font-semibold w-8/12">
            De manera confiable, te conecto con el especialista en salud mental
            ideal para ti. El cuál siempre estará disponible para atenderte y
            buscar tu bienestar.
          </div>

          <div className="text-gray-700 mt-8 text-center text-xl font-bold w-8/12">
            Nuestros servicios
          </div>
          <div className="flex justify-around pt-4 flex-wrap md:flex-no-wrap text-center text-gray-700 font-bold w-full md:w-auto">
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <div
                className="transform scale-75 h-12 mt-3 mb-1 text-higia-cyan mx-auto"
                style={{ width: "3.45rem" }}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current"
                >
                  <path d="M17.2004 12.3984C14.5504 12.3984 12.4004 14.5484 12.4004 17.1984C12.4004 18.0984 12.6504 18.9484 13.1004 19.6684C13.9304 21.0584 15.4504 21.9984 17.2004 21.9984C18.9504 21.9984 20.4804 21.0584 21.3004 19.6684C21.7404 18.9484 22.0004 18.0984 22.0004 17.1984C22.0004 14.5484 19.8504 12.3984 17.2004 12.3984ZM19.5804 16.5684L17.0204 18.9284C16.8804 19.0584 16.6904 19.1284 16.5104 19.1284C16.3204 19.1284 16.1304 19.0584 15.9804 18.9084L14.8004 17.7284C14.5104 17.4384 14.5104 16.9584 14.8004 16.6684C15.0904 16.3784 15.5704 16.3784 15.8604 16.6684L16.5304 17.3384L18.5604 15.4584C18.8604 15.1784 19.3404 15.1984 19.6204 15.4984C19.9004 15.8084 19.8804 16.2884 19.5804 16.5684Z" />
                  <path d="M22 8.73062C22 9.92062 21.81 11.0206 21.48 12.0406C21.42 12.2506 21.17 12.3106 20.99 12.1806C19.9 11.3706 18.57 10.9406 17.2 10.9406C13.73 10.9406 10.9 13.7706 10.9 17.2406C10.9 18.3206 11.18 19.3806 11.71 20.3206C11.87 20.6006 11.68 20.9606 11.38 20.8506C8.97 20.0306 4.1 17.0406 2.52 12.0406C2.19 11.0206 2 9.92062 2 8.73062C2 5.64062 4.49 3.14063 7.56 3.14063C9.37 3.14063 10.99 4.02062 12 5.37062C13.01 4.02062 14.63 3.14063 16.44 3.14063C19.51 3.14062 22 5.64062 22 8.73062Z" />
                </svg>
              </div>
              <div>Terapia individual</div>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <div
                className="transform scale-75 h-12 mt-3 mb-1 text-higia-cyan mx-auto"
                style={{ width: "3.35rem" }}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="fill-current"
                >
                  <path
                    d="M344.278,16.969c-22.624-22.624-59.435-22.625-82.061,0l-6.034,6.034l-6.034-6.034c-22.627-22.625-59.437-22.624-82.061,0
			c-23.188,23.187-22.547,59.514,0,82.061l88.095,88.096l88.095-88.096C366.826,76.482,367.467,40.156,344.278,16.969z
			 M317.126,71.878l-60.942,60.942l-60.942-60.942c-7.653-7.653-7.653-20.105-0.001-27.757c7.386-7.386,19.635-8.122,27.757,0
			l33.187,33.188l33.185-33.188c8.122-8.122,20.371-7.386,27.757,0C324.779,51.774,324.779,64.225,317.126,71.878z"
                  />
                  <path
                    d="M507.399,335.804c-3.976-9.6-13.26-15.804-23.651-15.804h-74.149c-10.588,0-19.2-8.613-19.2-19.2v-63.201
			c0-31.342-25.119-57.53-55.995-58.376c-15.693-0.448-30.531,5.356-41.77,16.292c-11.244,10.939-17.435,25.6-17.435,41.285v8.461
			h-38.4v-8.461c0-15.685-6.191-30.346-17.435-41.286c-11.24-10.936-26.077-16.739-41.77-16.292
			c-30.876,0.847-55.995,27.035-55.995,58.377v63.201c0,10.587-8.613,19.2-19.2,19.2H28.25c-10.391,0-19.675,6.204-23.651,15.804
			c-3.977,9.6-1.798,20.55,5.549,27.898l58.127,58.127c14.58,14.581,33.303,23.95,53.323,27.069V512h70.4v-38.4h-32v-26.504
			c20.589-5.225,38.973-17.19,51.98-34.267c16.238-21.313,24.82-46.775,24.82-73.629V283.66h38.4V339.2
			c0,26.854,8.582,52.316,24.82,73.629c13.007,17.076,31.392,29.042,51.979,34.267v26.504h-32V512h70.4v-63.101
			c20.021-3.121,38.743-12.489,53.324-27.069l58.127-58.127C509.198,356.353,511.377,345.404,507.399,335.804z M198.4,339.2
			c0,18.376-5.866,35.791-16.966,50.36c-9.943,13.053-24.534,20.856-41.082,21.97c-16.701,1.135-33.087-5.019-44.924-16.854
			l-36.275-36.276h43.247c31.761,0,57.6-25.838,57.6-57.6v-63.201c0-10.742,8.361-19.991,18.647-19.991
			c10.793,0,19.753,8.952,19.753,19.192V339.2z M416.57,394.676c-11.837,11.835-28.223,17.989-44.924,16.854
			c-16.548-1.114-31.137-8.916-41.082-21.97c-11.099-14.569-16.965-31.985-16.965-50.36v-102.4c0-10.24,8.96-19.192,19.753-19.192
			c10.286,0,18.647,9.251,18.647,19.991v63.201c0,31.761,25.838,57.6,57.6,57.6h43.247L416.57,394.676z"
                  />
                </svg>
              </div>
              <div>Terapia de Pareja</div>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <div
                className="transform scale-75 md:scale-100 h-12 w-12 mt-3 mb-1 text-higia-cyan mx-auto"
                style={{ width: "3.35rem" }}
              >
                <svg
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-width="3"
                  className="fill-current"
                >
                  <circle cx="31.89" cy="22.71" r="5.57" />
                  <path d="M43.16,43.74A11.28,11.28,0,0,0,31.89,32.47h0A11.27,11.27,0,0,0,20.62,43.74Z" />
                  <circle cx="48.46" cy="22.71" r="5.57" />
                  <path d="M46.87,43.74H59.73A11.27,11.27,0,0,0,48.46,32.47h0a11.24,11.24,0,0,0-5.29,1.32" />
                  <circle cx="15.54" cy="22.71" r="5.57" />
                  <path d="M17.13,43.74H4.27A11.27,11.27,0,0,1,15.54,32.47h0a11.24,11.24,0,0,1,5.29,1.32" />
                </svg>
              </div>
              <div>Terapia de Grupo</div>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <div
                className="transform scale-75 h-12 mt-3 mb-1 text-higia-cyan mx-auto"
                style={{ width: "3.25rem" }}
              >
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.1581 7.81489C15.883 8.5467 16.8886 9 18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 5.37703 14.0522 5.74188 14.1497 6.08773L8.84197 9.18518C8.11707 8.45333 7.11147 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.11165 16 8.11739 15.5465 8.84231 14.8145L14.1509 17.9079C14.0526 18.255 14 18.6214 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C16.8902 15 15.8859 15.452 15.1613 16.1819L9.85049 13.0872C9.94789 12.7415 10 12.3768 10 12C10 11.623 9.94784 11.2582 9.85036 10.9124L15.1581 7.81489ZM18 3C16.8954 3 16 3.89543 16 5C16 5.3474 16.0886 5.67412 16.2444 5.95879C16.2545 5.97417 16.2643 5.98992 16.2737 6.00602C16.283 6.022 16.2918 6.03813 16.3002 6.05439C16.6531 6.62207 17.2824 7 18 7C19.1046 7 20 6.10457 20 5C20 3.89543 19.1046 3 18 3ZM7.69959 10.9453C7.708 10.9617 7.71691 10.978 7.72632 10.9941C7.73579 11.0103 7.74565 11.0262 7.75588 11.0417C7.91152 11.3262 8 11.6528 8 12C8 12.3474 7.9114 12.6742 7.75556 12.9589C7.74533 12.9744 7.73547 12.9903 7.726 13.0066C7.71682 13.0223 7.70812 13.0382 7.69989 13.0543C7.34703 13.622 6.71765 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.71745 10 7.34667 10.3778 7.69959 10.9453ZM16 19C16 18.6749 16.0776 18.368 16.2152 18.0966C16.2399 18.0641 16.2629 18.0297 16.284 17.9935C16.3042 17.9588 16.3221 17.9234 16.3378 17.8874C16.6967 17.3522 17.3072 17 18 17C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19Z"
                  />
                </svg>
              </div>
              <div>Compartiendo tu viaje</div>
            </div>
          </div>

          <div className="text-gray-700 mt-8 text-center text-xl font-bold w-8/12">
            Beneficios de tomar sesiones en Higia
          </div>
          <div className="flex justify-around pt-4 flex-wrap md:flex-no-wrap text-center text-gray-700 font-bold w-full md:w-auto">
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <Link to="beneficios">
                <div
                  className="transform scale-75 h-12 mt-3 mb-1 text-higia-cyan mx-auto"
                  style={{ width: "3.45rem" }}
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-current"
                  >
                    <path d="M0.833496 17.5H4.16683V7.50004H0.833496V17.5ZM19.1668 8.33337C19.1668 7.41671 18.4168 6.66671 17.5002 6.66671H12.2418L13.0335 2.85837L13.0585 2.59171C13.0585 2.25004 12.9168 1.93337 12.6918 1.70837L11.8085 0.833374L6.32516 6.32504C6.01683 6.62504 5.8335 7.04171 5.8335 7.50004V15.8334C5.8335 16.75 6.5835 17.5 7.50016 17.5H15.0002C15.6918 17.5 16.2835 17.0834 16.5335 16.4834L19.0502 10.6084C19.1252 10.4167 19.1668 10.2167 19.1668 10V8.33337Z" />
                  </svg>
                </div>
                <div>El mejor psicólogo para ti</div>
              </Link>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <Link to="beneficios">
                <div
                  className="transform scale-75 h-12 mt-3 mb-1 mx-auto"
                  style={{ width: "3.35rem" }}
                >
                  <svg
                    viewBox="0 0 22 20"
                    fill="none"
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.35 5V17.5C1.35 17.8315 1.49223 18.1495 1.74541 18.3839C1.99858 18.6183 2.34196 18.75 2.7 18.75H18.9C19.258 18.75 19.6014 18.6183 19.8546 18.3839C20.1078 18.1495 20.25 17.8315 20.25 17.5V5H1.35ZM2.7 1.25C1.98392 1.25 1.29716 1.51339 0.790812 1.98223C0.284464 2.45107 0 3.08696 0 3.75L0 17.5C0 18.163 0.284464 18.7989 0.790812 19.2678C1.29716 19.7366 1.98392 20 2.7 20H18.9C19.6161 20 20.3028 19.7366 20.8092 19.2678C21.3155 18.7989 21.6 18.163 21.6 17.5V3.75C21.6 3.08696 21.3155 2.45107 20.8092 1.98223C20.3028 1.51339 19.6161 1.25 18.9 1.25H2.7Z"
                      fill="#55D1F8"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.7248 0C4.90383 0 5.07551 0.065848 5.2021 0.183058C5.32869 0.300269 5.3998 0.45924 5.3998 0.625V1.25C5.3998 1.41576 5.32869 1.57473 5.2021 1.69194C5.07551 1.80915 4.90383 1.875 4.7248 1.875C4.54578 1.875 4.37409 1.80915 4.24751 1.69194C4.12092 1.57473 4.0498 1.41576 4.0498 1.25V0.625C4.0498 0.45924 4.12092 0.300269 4.24751 0.183058C4.37409 0.065848 4.54578 0 4.7248 0V0ZM16.8748 0C17.0538 0 17.2255 0.065848 17.3521 0.183058C17.4787 0.300269 17.5498 0.45924 17.5498 0.625V1.25C17.5498 1.41576 17.4787 1.57473 17.3521 1.69194C17.2255 1.80915 17.0538 1.875 16.8748 1.875C16.6958 1.875 16.5241 1.80915 16.3975 1.69194C16.2709 1.57473 16.1998 1.41576 16.1998 1.25V0.625C16.1998 0.45924 16.2709 0.300269 16.3975 0.183058C16.5241 0.065848 16.6958 0 16.8748 0V0Z"
                      fill="#55D1F8"
                    />
                  </svg>
                </div>
                <div>Horarios flexibles</div>
              </Link>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <Link to="beneficios">
                <div
                  className="transform scale-75 md:scale-100 h-12 w-12 mt-3 mb-1 text-higia-cyan mx-auto"
                  style={{ width: "3.35rem" }}
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.59 5.58L8 12.17L4.41 8.59L3 10L8 15L16 7L14.59 5.58ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
                  </svg>
                </div>
                <div>Espacio de confianza</div>
              </Link>
            </div>
            <div className="flex flex-col bg-higia-crema rounded-lg p-2 md:p-4 m-2 items-center shadow-lg w-5/12 md:w-40 md:h-40 md:mx-6">
              <Link to="beneficios">
                <div
                  className="transform scale-75 h-12 mt-3 mb-1 mx-auto"
                  style={{ width: "3.25rem" }}
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1227 6.39545L17.1214 7.35C18.9237 9.06818 18.9237 11.7409 17.1214 13.5545L18.1227 14.5091C20.6258 12.3136 20.6258 8.68636 18.1227 6.39545V6.39545ZM16.02 8.4L15.0188 9.35455C15.5194 10.0227 15.5194 10.8818 15.0188 11.55L16.02 12.5045C17.2215 11.3591 17.2215 9.64091 16.02 8.4ZM12.015 0H2.0025C0.901126 0 0 0.859091 0 1.90909V19.0909C0 20.1409 0.901126 21 2.0025 21H12.015C13.1164 21 14.0175 20.1409 14.0175 19.0909V1.90909C14.0175 0.859091 13.1164 0 12.015 0ZM12.015 18.1364H2.0025V2.86364H12.015V18.1364Z"
                      fill="#55D1F8"
                    />
                  </svg>
                </div>
                <div>Menos inconvenientes</div>
              </Link>
            </div>
          </div>
          <div className="text-gray-700 mt-8 text-center text-xl font-bold">
            ¡Tomar terapia online es muy fácil!
          </div>
        </div>
      </section>

      <section>
        <div className="text-white bg-higia-bermuda flex flex-col pt-6 mt-8 items-center">
          <div className="w-8/12 text-center text-2xl font-bold">
            ¡Muchas personas han encontrado la calma que buscaban!
          </div>
        </div>
        <div className="text-higia-bermuda overflow-hidden relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="fill-current w-full wavesShadow relative"
            style={{ top: "-2px" }}
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
        <div className="flex flex-col md:flex-row md:container md:mx-auto md:justify-around md:mb-8 items-center text-higia-bermuda">
          <div className="flex flex-col items-center mb-10 lg md:w-4/12 md:justify-around">
            <img
              className="text-higia-bermuda fill-current h-16 w-16 mb-3"
              src={hearthand}
              alt="heart & hand"
            />
            <div
              ref={impacto1Ref}
              className="text-higia-bermuda text-4xl font-bold leading-none"
            >
              +{count1}
            </div>
            <div className="text-higia-gray-3 text-xl font-bold">Consultas</div>
          </div>
          <div className="flex flex-col items-center mb-10 md:w-4/12">
            <img
              className="text-higia-bermuda fill-current h-16 w-16 mb-3"
              src={clock}
              alt="reloj"
            />
            <div
              ref={impacto2Ref}
              className="text-higia-bermuda text-4xl font-bold leading-none"
            >
              +{count2}
            </div>
            <div className="text-higia-gray-3 text-xl font-bold">
              Minutos en consulta
            </div>
          </div>
          <div className="flex flex-col items-center mb-10 md:w-4/12">
            <img
              className="text-higia-bermuda fill-current h-16 w-16 mb-3"
              src={world}
              alt="mundo"
            />
            <div
              ref={impacto3Ref}
              className="text-higia-bermuda text-4xl font-bold leading-none"
            >
              +{count3}
            </div>
            <div className="text-higia-gray-3 text-xl font-bold">
              Consultas internacionales
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex flex-col">
          <div className="flex justify-start w-10/12 md:w-6/12 mx-auto items-center mb-4">
            <h3 className="text-gray-700 text-xl inline-block mr-2 font-bold">
              Testimonios
            </h3>
            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2133 1.50397H2.88762C2.50469 1.50397 2.13746 1.66242 1.86669 1.94447C1.59592 2.22652 1.44381 2.60906 1.44381 3.00793V20.4329L4.33142 17.425C4.87283 16.8608 5.60719 16.5438 6.37297 16.5436H20.2133C20.5962 16.5436 20.9635 16.3852 21.2342 16.1031C21.505 15.8211 21.6571 15.4385 21.6571 15.0397V3.00793C21.6571 2.60906 21.505 2.22652 21.2342 1.94447C20.9635 1.66242 20.5962 1.50397 20.2133 1.50397ZM2.88762 0C2.12177 0 1.3873 0.316906 0.845763 0.881003C0.30423 1.4451 0 2.21018 0 3.00793L0 22.2482C3.03414e-05 22.397 0.0424372 22.5424 0.121853 22.6661C0.20127 22.7898 0.314125 22.8861 0.446136 22.943C0.578146 22.9998 0.723378 23.0146 0.863449 22.9854C1.00352 22.9562 1.13213 22.8844 1.23301 22.7791L5.3522 18.4883C5.6229 18.2062 5.99008 18.0477 6.37297 18.0476H20.2133C20.9792 18.0476 21.7136 17.7307 22.2552 17.1666C22.7967 16.6025 23.1009 15.8374 23.1009 15.0397V3.00793C23.1009 2.21018 22.7967 1.4451 22.2552 0.881003C21.7136 0.316906 20.9792 0 20.2133 0L2.88762 0Z"
                fill="#85CEC1"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.33154 5.26389C4.33154 5.06445 4.4076 4.87318 4.54298 4.73215C4.67837 4.59113 4.86199 4.5119 5.05345 4.5119H18.0477C18.2392 4.5119 18.4228 4.59113 18.5582 4.73215C18.6936 4.87318 18.7696 5.06445 18.7696 5.26389C18.7696 5.46332 18.6936 5.65459 18.5582 5.79562C18.4228 5.93664 18.2392 6.01587 18.0477 6.01587H5.05345C4.86199 6.01587 4.67837 5.93664 4.54298 5.79562C4.4076 5.65459 4.33154 5.46332 4.33154 5.26389ZM4.33154 9.0238C4.33154 8.82436 4.4076 8.63309 4.54298 8.49207C4.67837 8.35104 4.86199 8.27182 5.05345 8.27182H18.0477C18.2392 8.27182 18.4228 8.35104 18.5582 8.49207C18.6936 8.63309 18.7696 8.82436 18.7696 9.0238C18.7696 9.22324 18.6936 9.41451 18.5582 9.55553C18.4228 9.69656 18.2392 9.77578 18.0477 9.77578H5.05345C4.86199 9.77578 4.67837 9.69656 4.54298 9.55553C4.4076 9.41451 4.33154 9.22324 4.33154 9.0238ZM4.33154 12.7837C4.33154 12.5843 4.4076 12.393 4.54298 12.252C4.67837 12.111 4.86199 12.0317 5.05345 12.0317H12.2725C12.4639 12.0317 12.6476 12.111 12.7829 12.252C12.9183 12.393 12.9944 12.5843 12.9944 12.7837C12.9944 12.9832 12.9183 13.1744 12.7829 13.3154C12.6476 13.4565 12.4639 13.5357 12.2725 13.5357H5.05345C4.86199 13.5357 4.67837 13.4565 4.54298 13.3154C4.4076 13.1744 4.33154 12.9832 4.33154 12.7837Z"
                fill="#85CEC1"
              />
            </svg>
          </div>
          <div className="flex flex-col w-10/12 md:w-6/12 mx-auto bg-higia-crema shadow-lg rounded-lg text-gray-700 font-bold text-center p-4 md:p-8">
            <Carousel
              responsive={responsive}
              arrows={false}
              showDots
              infinite
              autoPlay
              autoPlaySpeed={10000}
            >
              <div className="mb-10">
                <p>
                  “Temía que las sesiones no sean del todo satisfactorias por el
                  hecho de ser virtuales o que no se logre el objetivo. Pero fue
                  todo lo contrario, voy 4 sesiones con Kira y me siento mucho
                  mejor que hace unas semanas”
                </p>
                <div className="mt-1">– Rosario (25)</div>
              </div>
              <div className="mb-10">
                <p>
                  Una amiga me recomendó Higia. Desde que les mandé mensaje su
                  respuesta fue casi instantánea y súper amables. La sesión fue
                  cómoda, por lo menos para mí que soy una persona muy retraída
                  con como se siente, me gustó mucho, pude sentirme en confianza
                  con la psicóloga y me ayudó bastante. Realmente fueron muy
                  amables!
                </p>
                <div className="mt-1">– Patricia (29)</div>
              </div>
              <div className="mb-10">
                <p>
                  La verdad recomendaría mucho este programa, la verdad
                  necesitaba ser escuchada por alguien que estuviese instruido y
                  fuera psicólogo para que me ayude a entender el porqué de mis
                  acciones y que no solo me insulte o trate distinto por ellas,
                  además me gusta que pueda ser virtualmente así no me
                  avergüenzo
                </p>
                <div className="mt-1">- Leydy (19)</div>
              </div>
              <div className="mb-10">
                <p>
                  La verdad que muy recomendado, me sentia abrumado en esta
                  cuarentena y ellos tenian las palabras correctas y los metodos
                  para apoyarme, super reconfortante
                </p>
                <div className="mt-1">-Nicolas (34)</div>
              </div>
              <div className="mb-10">
                <p>
                  Fue una experiencia única, senti tranquilidad , paz y mucha
                  confianza en la sesión pude liberar mis preocupaciones y estoy
                  dispuesta a poner en práctica las pautas que me dieron en mi
                  vida diaria.
                </p>
                <div className="mt-1">– Mariela (42)</div>
              </div>
            </Carousel>
          </div>
        </div>
      </section>
      <section>
        <div className="text-white bg-higia-bermuda flex flex-col pt-6 mt-10 items-center">
          <div className="w-8/12 text-center text-2xl font-bold">
            Tenemos al especialista ideal para ti:
          </div>
        </div>
        <div className="text-higia-bermuda overflow-hidden relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="fill-current w-full wavesShadow relative"
            style={{ top: "-2px" }}
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex w-10/12 font-bold">
            <div className="flex flex-col w-6/12 pr-2 md:pr-8">
              <Link
                to="/terapeutas/Giovany-Rivera"
                className="flex flex-col bg-higia-crema rounded-lg shadow-lg p-2 items-center md:w-64 md:self-end md:mb-2"
              >
                <img
                  className="h-20 w-20 object-cover rounded-full mt-2"
                  src={giovany}
                  alt="Giovany"
                />
                <div className="text-higia-cyan text-lg mt-2">
                  Giovany Rivera
                </div>
                <div className="text-gray-700 mb-2 text-center">
                  Psiquiatra y psicoterapeuta
                </div>
              </Link>
              <Link
                to="/terapeutas/Jesús-Medina"
                className="mt-12 w-full md:mt-18 flex flex-col bg-higia-crema rounded-lg shadow-lg p-2 items-center md:w-64 md:self-end"
              >
                <img
                  className="h-20 w-20 object-cover rounded-full mt-2"
                  src={jesus}
                  alt="Jesús"
                />
                <div className="text-higia-cyan text-lg mt-2">Jesús Medina</div>
                <div className="text-gray-700 mb-2">Psicoterapeuta</div>
              </Link>
            </div>
            <div className="flex flex-col w-6/12 pl-2 md:pl-8 justify-center">
              <Link to="/terapeutas/Kira-Serguienko">
                <div className="flex flex-col bg-higia-crema rounded-lg shadow-lg p-2 items-center md:w-64">
                  <img
                    className="h-20 w-20 object-cover rounded-full mt-2"
                    src={kira}
                    alt="Kira"
                  />
                  <div className="text-higia-cyan text-lg mt-2 text-center">
                    Kira Serguienko
                  </div>
                  <div className="text-gray-700 mb-2">Psicoterapeuta</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mt-4 inline-flex">
            <Link
              to="/register"
              className="px-6 py-2 my-5 mx-2 bg-higia-cyan rounded-lg font-bold text-white text-xl"
              style={{ borderRadius: "3rem" }}
            >
              Ver más psicoterapeutas
            </Link>
          </div>
        </div>
        <div className="bg-higia-azul mt-2 mb-1 pt-3 pb-6 text-center">
          <div className="text-higia-gray-3 font-bold text-xl mt-3 mb-2">
            ¿Eres psicoterapeuta?
          </div>
          <a
            className="text-white underline text-xl font-extrabold"
            target="_blank"
            rel="noopener noreferrer"
            href="https://forms.gle/Ac8r7cBWGmhEL1Sq5"
          >
            Únete aquí
          </a>
        </div>
      </section>

      <section>
        <div className="text-white bg-higia-bermuda flex flex-col pt-6 mt-12 items-center">
          <div className="w-8/12 text-center text-2xl font-bold">
            Estos son algunos motivos de consulta en los que nuestro equipo te
            podrá ayudar:
          </div>
        </div>
        <div className="text-higia-bermuda overflow-hidden relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="fill-current w-full wavesShadow relative"
            style={{ top: "-2px" }}
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
        <div>
          <div className="flex flex-col items-center">
            <div className="flex bg-higia-crema shadow-lg rounded-lg p-1 sm:p-4 md:p-8 w-11/12 md:w-8/12 lg:w-6/12">
              <div className="w-7/12 my-auto">
                <img
                  src={chilling}
                  className="w-full h-auto px-2 md:px-4"
                  alt="chilling"
                ></img>
              </div>
              <div className="w-5/12 my-auto">
                <ul className="text-gray-700 font-bold">
                  <li>• Depresión</li>
                  <li>• Ansiedad y estrés</li>
                  <li>• Problemas de pareja</li>
                  <li>• Duelo y pérdida</li>
                  <li>• Autoestima</li>
                  <li>• Desarrollo personal</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mt-4 inline-flex">
            <Link
              to="/register"
              className="px-6 py-2 my-5 mx-2 bg-higia-cyan rounded-lg font-bold text-white text-xl"
              style={{ borderRadius: "3rem" }}
            >
              Quiero Empezar
            </Link>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="flex justify-start w-10/12 md:w-6/12 mx-auto items-center mb-4">
            <h3 className="text-gray-700 text-xl inline-block mr-2 font-bold">
              Ellos nos respaldan
            </h3>
            <svg
              width="23"
              height="23"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.59 5.58L8 12.17L4.41 8.59L3 10L8 15L16 7L14.59 5.58ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
                fill="#85CEC1"
              />
            </svg>
          </div>
          <div className="flex w-10/12 md:w-6/12 mx-auto justify-around flex-wrap">
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2 mb-4"
              src={hacktech}
              alt="Hacktech"
            />
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2"
              src={equipu}
              alt="Equipu"
            />
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2"
              src={directv}
              alt="directv"
            />
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2"
              src={aiesec}
              alt="aiesec"
            />
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2"
              src={escuelaplus}
              alt="escuelaplus"
            />
            <img
              className="h-20 w-20 object-cover rounded-full shadow-lg ml-2 mr-2"
              src={saludablemente}
              alt="saludablemente"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setCameFrom })(Landing);
