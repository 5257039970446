import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import laidDown from "../../img/laidDown.jpg";
import api from "../../utils/api";

const ResetPassword = ({ isAuthenticated, alerts }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [msg, setMsg] = useState("");

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setMsg("Procesando");
      const res = await api.post("/resetPassword/reset", formData);
      setMsg(res.data.msg);
    } catch {
      setMsg(
        "Ocurrió un error. Quizás ese correo no está registrado o no estás conectado al internet."
      );
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <header
        className={`bg-no-repeat bg-cover relative header-login flex flex-col-reverse`}
        style={{
          backgroundImage: `url(${laidDown})`,
        }}
      >
        <div className="text-white relative overflow-hidden">
          <svg
            className="fill-current relative"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 62"
            transform="scale(1,-1)"
            style={{ bottom: "-2px" }}
          >
            <path d="M0 41.32l35.56 3.45c35.55 3.33 106.66 10.43 177.77 5.17 71.11-5.07 142.23-22.5 213.34-22.41 71.11-.09 142.22 17.34 177.77 25.83 2.37.57 14.23 3.45 35.56 8.62V0H0v41.32z" />
          </svg>
        </div>
      </header>
      <section
        className="container flex-grow flex flex-col mx-auto font-sans"
        styles={{ height: "max-content" }}
      >
        <h2 className="text-higia-gray-3 font-bold text-center mb-5 mt-4 w-8/12 mx-auto leading-none">
          Ingresa tu correo, te enviaremos un link para que puedas cambiar de
          contraseña.
        </h2>

        <form className="flex flex-col items-center mt-4" onSubmit={onSubmit}>
          <div className="w-3/4 lg:w-1/3 px-4 py-3 rounded-lg border border-solid border-gray-400">
            <input
              className="outline-none w-full bg-white"
              type="email"
              placeholder="¿Cuál es tu correo?"
              name="email"
              value={email}
              onChange={onChange}
              autoComplete="username"
              required
            />
          </div>
          {alerts.length > 0 ? (
            <div className="text-sm text-red-400">Credenciales inválidas</div>
          ) : null}
          <input
            type="submit"
            className="w-3/4 lg:w-1/3 mt-4 rounded-lg px-4 py-4 bg-higia-bermuda text-white cursor-pointer text-lg outline-none"
            value="Reestablecer contraseña"
          />
          {msg ? <div className="text-higia-gray-3 mt-4">{msg}</div> : null}
          <Link className="text-higia-cyan underline mt-4 text-sm" to="/login">
            Regresar
          </Link>
        </form>
      </section>
    </>
  );
};

ResetPassword.propTypes = {
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  alerts: state.alert,
});

export default connect(mapStateToProps)(ResetPassword);
