import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { connect } from 'react-redux';
import laidDown from '../../img/laidDown.jpg';

const Login = ({ login, isAuthenticated, alerts }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to='/home' />;
  }

  return (
    <>
      <header
        className={`bg-no-repeat bg-cover relative header-login flex flex-col-reverse xl:container xl:mx-auto`}
        style={{
          backgroundImage: `url(${laidDown})`,
        }}
      >
        <div className='text-white relative overflow-hidden'>
          <svg
            viewBox='0 0 640 62'
            xmlns='http://www.w3.org/2000/svg'
            className='fill-current relative'
            style={{ bottom: '-2px' }}
          >
            <path fill='none' d='M-1-1h802v602H-1z' />
            <path d='M640.043 20.486l-35.56-3.45c-35.55-3.33-106.66-10.43-177.77-5.17-71.11 5.07-142.23 22.5-213.34 22.41-71.11.09-142.22-17.34-177.77-25.83-2.37-.57-14.23-3.45-35.56-8.62v61.98h640v-41.32z' />
          </svg>
        </div>
      </header>
      <section
        className='container flex-grow flex flex-col mx-auto font-sans'
        styles={{ height: 'max-content' }}
      >
        <h2 className='text-higia-cyan font-extrabold text-center text-3xl mb-5'>
          ¡Hola!
        </h2>

        <form className='flex flex-col items-center' onSubmit={onSubmit}>
          <div className='w-3/4 lg:w-1/3 px-4 py-3 rounded-lg border border-solid border-gray-400'>
            <input
              className='outline-none w-full bg-white'
              type='email'
              placeholder='¿Cuál es tu correo?'
              name='email'
              value={email}
              onChange={onChange}
              autoComplete='username'
              required
            />
          </div>
          <div className='w-3/4 lg:w-1/3 px-4 py-3 my-2 rounded-lg border border-solid border-gray-400'>
            <input
              className='outline-none w-full'
              type='password'
              placeholder='Ingresa tu contraseña'
              name='password'
              value={password}
              onChange={onChange}
              autoComplete='current-password'
              minLength='6'
            />
          </div>
          {alerts.length > 0 ? (
            <div className='text-sm text-red-400'>Credenciales inválidas</div>
          ) : null}
          <input
            type='submit'
            className='w-3/4 lg:w-1/3 mt-4 rounded-lg px-4 py-4 bg-higia-bermuda text-white cursor-pointer text-lg'
            value='Entrar'
          />
          <Link
            className='text-higia-cyan underline mt-4 text-sm'
            to='/register'
          >
            ¿Eres nuevo? Registrate aquí
          </Link>
          <Link
            className='text-higia-cyan underline mt-1 md:mt-2 text-sm'
            to='/loginspecialist'
          >
            ¿Eres terapeuta? Entra aquí
          </Link>
          <Link
            className='text-higia-cyan underline mt-1 md:mt-2 text-sm'
            to='/resetpassword'
          >
            ¿Olvidaste tu contraseña?
          </Link>
        </form>
      </section>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  alerts: state.alert,
});

export default connect(mapStateToProps, { login })(Login);
