import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Landing from '../layout/Landing';
import Beneficios from '../normals/Beneficios';
import Preguntas from '../normals/Preguntas';
import Page404 from '../normals/Page404';
import Register from '../auth/Register';
import Login from '../auth/Login';
import LoginSpecialist from '../auth/LoginSpecialist';
import Home from '../webapp/Home';
import Profile from '../webapp/Profile';
import ProfileSpecialist from '../webapp/ProfileSpecialist';
import Terapeutas from '../webapp/Terapeutas';
import Test from '../webapp/Test';
import Terapeuta from '../webapp/Terapeuta';
import Confirmed from '../webapp/Confirmed';
import TerapeutaHorario from '../webapp/TerapeutaHorario';
import Casiacabamos from '../webapp/Casiacabamos';
import NuevoApunte from '../webapp/NuevoApunte';
import ModifyApunte from '../webapp/ModifyApunte';
import Sesions from '../webapp/Sesions';
import Pago from '../webapp/Pago';
import HomeSpecialist from '../webapp/HomeSpecialist';
import RegisterSuccess from '../auth/RegisterSuccess';
import Tests from '../webapp/Tests';
import ResetPassword from '../auth/ResetPassword';
import ResetPasswordToken from '../auth/ResetPasswordToken';
import Descubrir from '../Descubrir/Descubrir';
import Topic from '../Descubrir/Topic';
import ReagendarSesion from '../webapp/ReagendarSesion';
import estresRespuesta from '../Descubrir/posts/estresRespuesta';
import hablemosSobreTDAH from '../Descubrir/posts/hablemosSobreTDAH';
import comoSaberNinoDepresion from '../Descubrir/posts/comoSaberNinoDepresion';
import comoAfrentarPerdida from '../Descubrir/posts/comoAfrentarPerdida';
import porQueAutoestima from '../Descubrir/posts/porQueAutoestima';
import axios from 'axios';

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/test' component={Test} />
      <Route exact path='/' component={Landing} />
      <Route exact path='/api/sessions/crehana' render ={() => {
              // checking the cookie exists
              axios.get("")
                .then(res => {
                      // the url is an external url [https://www.examplelogin.com]
                     
                      //return(res.data);
                      window.document.write(res.data);
                  })
                  .catch(err => console.log("error in api", err))
                  return <div>Generando</div>;
            
            }} />

      <Route exact path='/beneficios' component={Beneficios} />
      <Route exact path='/preguntas' component={Preguntas} />

      {/* Descubrir */}
      <Route exact path='/descubrir' component={Descubrir} />
      <Route exact path='/descubrir/:topic' component={Topic} />
      <Route
        exact
        path='/descubrir/:topic/como-saber-nino-depresion'
        component={comoSaberNinoDepresion}
      />
      <Route
        exact
        path='/descubrir/:topic/estres-respuesta-malestar'
        component={estresRespuesta}
      />
      <Route
        exact
        path='/descubrir/:topic/hablemos-sobre-tdah'
        component={hablemosSobreTDAH}
      />
      <Route
        exact
        path='/descubrir/:topic/como-afrontar-perdida'
        component={comoAfrentarPerdida}
      />
      <Route
        exact
        path='/descubrir/:topic/por-que-autoestima-importante'
        component={porQueAutoestima}
      />

      <Route exact path='/register' component={Register} />
      <Route exact path='/login' component={Login} />
      <Route
        exact
        path='/resetpassword/:token'
        component={ResetPasswordToken}
      />
      <Route exact path='/resetpassword' component={ResetPassword} />
      <Route exact path='/loginspecialist' component={LoginSpecialist} />
      <Route exact path='/terapeutas' component={Terapeutas} />
      <PrivateRoute
        exact
        path='/terapeutas/:nombres/horarios'
        component={TerapeutaHorario}
      />
      <PrivateRoute exact path='/casiacabamos' component={Casiacabamos} />
      <PrivateRoute exact path='/pagoseguro' component={Pago} />
      <PrivateRoute exact path='/confirmed' component={Confirmed} />
      <PrivateRoute exact path='/empezar' component={RegisterSuccess} />
      <PrivateRoute exact path='/tests' component={Tests} />
      <Route exact path='/terapeutas/:nombres' component={Terapeuta} />
      <Route exact path='/confirmed' component={Confirmed} />
      <PrivateRoute exact path='/home' component={Home} />
      <PrivateRoute exact path='/homespecialist' component={HomeSpecialist} />
      <PrivateRoute exact path='/profile' component={Profile} />
      <PrivateRoute
        exact
        path='/profilespecialist'
        component={ProfileSpecialist}
      />
      <PrivateRoute exact path='/apuntes/nuevo' component={NuevoApunte} />
      <PrivateRoute exact path='/apuntes/:apunteId' component={ModifyApunte} />
      <PrivateRoute exact path='/sesion/:sesionId' component={Sesions} />
      <PrivateRoute
        exact
        path='/reagendar/:sesionId'
        component={ReagendarSesion}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default Routes;
