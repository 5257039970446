import React, { useState, useEffect } from 'react';
import {
  add,
  format,
  getDay,
  getHours,
  getMinutes,
  isAfter,
  isWithinInterval,
  parseISO,
} from 'date-fns';
import Calendar from 'react-calendar';
import { connect } from 'react-redux';
import { getSpecialistByID } from '../../actions/especialistas';
import './Calendar.css';
import { registerSession, updateCheckout } from '../../actions/checkout';
import { Redirect, useHistory } from 'react-router-dom';

const ReagendarSesion = ({
  match,
  auth,
  specialists,
  registerSession,
  updateCheckout,
  checkout,
  getSpecialistByID,
}) => {
  let history = useHistory();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState('');

  const user = auth.user;
  const currentSession = user.sessions.filter(
    (session) => session._id === match.params.sesionId
  )[0];

  if (
    currentSession.reagendo ||
    isAfter(
      new Date(),
      add(parseISO(currentSession.horaPactada), {
        minutes: -60 * 3,
      })
    )
  ) {
    history.push('/home');
  }

  useEffect(() => {
    getSpecialistByID(currentSession.especialista);
  }, [getSpecialistByID, match.params.sesionId, currentSession.especialista]);

  const info = specialists.specialist;

  let availableHorarios = [];
  if (info && info.horarios) {
    const sesionesOcupadas = info.sessions.map((sessionInfo) =>
      parseISO(sessionInfo.horaPactada)
    );

    Object.values(info.horarios).forEach((val) => {
      let horario = parseISO(val);
      let fechaDisponible = add(selectedDay, {
        hours: getHours(horario),
        minutes: getMinutes(horario),
      });
      if (
        getDay(horario) === getDay(selectedDay) && // Same day
        isAfter(selectedDay, add(new Date(), { days: 1 })) && // After one day
        sesionesOcupadas.every(
          (sesion) =>
            !isWithinInterval(fechaDisponible, {
              start: sesion,
              end: add(sesion, { minutes: 50 }),
            })
        ) // Is not already taken
      ) {
        availableHorarios.push(format(fechaDisponible, 'HH:mm'));
      }
    });
  }

  const completed = async () => {
    if (selectedHour) {
      let checkoutObj = {
        ...currentSession,
        sesId: match.params.sesionId,
        cliente: user._id,
        horaPactada: add(selectedDay, {
          hours: selectedHour.split(':')[0],
          minutes: selectedHour.split(':')[1],
        }),
        newSession: false,
        reagendo: true,
      };
      await updateCheckout(checkoutObj);
      await registerSession(checkoutObj);
    }
    history.push('/confirmed');
  };

  if (currentSession.reagendo) {
    return <Redirect to='/home' />;
  }

  return (
    <section
      className='container px-8 pt-8 flex-grow flex flex-col mx-auto font-sans'
      styles={{ height: 'max-content' }}
    >
      <Calendar
        onChange={(value) => {
          setSelectedDay(value);
          setSelectedHour('');
        }}
        value={selectedDay}
        locale='es-ES'
      />
      <div className='font-bold mt-8 mb-4'>Horarios Disponibles</div>
      <div className='flex flex-wrap mb-2'>
        {availableHorarios.length > 0 ? (
          availableHorarios.sort().map((hora) => (
            <div
              className={`flex align-center border-solid cursor-pointer border-2 rounded px-3 mr-3 mb-2 ${
                selectedHour === hora
                  ? 'border-higia-bermuda'
                  : 'border-higia-gray-2'
              }`}
              key={hora}
              style={{ alignItems: 'center' }}
              onClick={() => {
                setSelectedHour(hora);
              }}
            >
              <div
                className={`w-3 h-3 rounded-full inline border border-solid border-black mr-2 ${
                  selectedHour === hora ? 'bg-higia-bermuda' : ''
                }`}
              ></div>
              <div className=''>{hora}</div>
            </div>
          ))
        ) : (
          <div>No hay horarios diponibles este día</div>
        )}
      </div>
      <div className='flex justify-center mt-4'>
        <button
          className={`text-white py-3 px-5 rounded-lg flex justify-center ${
            selectedHour ? 'bg-higia-bermuda' : 'bg-gray-400'
          }`}
          to={`${selectedHour ? `/casiacabamos` : '#'}`}
          onClick={() => completed()}
        >
          Reagendar
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  specialists: state.specialists,
  checkout: state.checkout,
});

export default connect(mapStateToProps, {
  getSpecialistByID,
  updateCheckout,
  registerSession,
})(ReagendarSesion);
