import React from "react";
import { Link } from "react-router-dom";

const Page404 = ({ location }) => {
  return (
    <div className="text-gray-700 w-9/12 mx-auto">
      <div className="text-center mt-16 md:mt-32 lg:mt-40 font-bold text-2xl">
        404: ¡Esta página no existe!
      </div>
      <div className="flex flex-col items-center">
        <Link
          to="/"
          className="mt-20 font-bold mx-auto text-center text-xl rounded-lg px-8 py-4 bg-higia-bermuda text-white cursor-pointer text-lg"
        >
          Regresar
        </Link>
      </div>
    </div>
  );
};

export default Page404;
