import React, { useState, Fragment, useEffect } from "react";
import Flicking from "@egjs/react-flicking";
import { Link, Redirect } from "react-router-dom";
import { changeNavColors, changeNavLogo } from "../../actions/nav";
import { connect } from "react-redux";
import topics from "./TopicsIndex";

const Topic = ({ match, changeNavColors, changeNavLogo }) => {
  const [redirection, setRedirection] = useState(false);

  const topic = match.params.topic;
  const selectEscuchar = ({ index }) => {
    setRedirection(
      <Redirect push to={topics[topic].escuchar[(index - 1) / 2].link} />
    );
  };
  topics[topic].posts.forEach((post) => {});

  useEffect(() => {
    changeNavColors(topics[topic].bg);
    changeNavLogo({ showLogo: false, previous: "/descubrir" });
    return () => {
      changeNavColors("");
      changeNavLogo({ showLogo: true, previous: "" });
    };
  }, [changeNavColors, changeNavLogo, topic]);

  if (redirection) {
    return redirection;
  }

  return (
    <>
      <div className="w-full relative">
        <div className={`mx-auto ${topics[topic].bg}`}>
          <div className="container mx-auto px-8">
            <h1 className="text-higia-gray-3 md:pt-4 text-3xl font-semibold pt-2">
              {topics[topic].topicTitle}
            </h1>
          </div>
        </div>
        <div
          className={`${topics[topic].text} overflow-hidden relative`}
          style={{ top: "-2px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            height="4rem"
            className="fill-current shadow-lg w-full wavesShadow"
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="mx-8">
          <h2 className="text-sm mx-4 font-semibold">
            {topics[topic].topicDescription}
          </h2>
          {topics[topic].escuchar.length > 0 && (
            <div className="mt-4">
              <div className="font-bold flex items-center">
                Escuchar
                <svg
                  height="20"
                  viewBox="0 0 25 25"
                  className="ml-4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8366 0.136974C16.0712 0.250017 16.2692 0.426939 16.4079 0.64743C16.5466 0.86792 16.6203 1.12304 16.6205 1.38351V23.5442C16.6201 23.8048 16.5462 24.06 16.4073 24.2805C16.2684 24.501 16.07 24.6779 15.8351 24.7907C15.6002 24.9035 15.3382 24.9478 15.0792 24.9184C14.8203 24.889 14.5748 24.7871 14.3712 24.6245L7.82548 19.389H1.38504C1.01771 19.389 0.665415 19.2431 0.405669 18.9834C0.145924 18.7236 0 18.3713 0 18.004V6.92368C0 6.55634 0.145924 6.20405 0.405669 5.9443C0.665415 5.68456 1.01771 5.53863 1.38504 5.53863H7.82548L14.3712 0.303179C14.575 0.140265 14.8207 0.0382293 15.0799 0.00882462C15.3392 -0.0205801 15.6014 0.0238419 15.8366 0.136974V0.136974ZM13.8504 4.2644L9.17451 8.00401C8.92934 8.2007 8.62456 8.30816 8.31025 8.30872H2.77008V16.619H8.31025C8.62456 16.6195 8.92934 16.727 9.17451 16.9237L13.8504 20.6633V4.2644Z"
                    fill="black"
                  />
                  <path
                    d="M21.3491 21.2782C22.5083 20.122 23.4276 18.748 24.0542 17.2354C24.6807 15.7227 25.0022 14.1011 25 12.4638C25.0022 10.8265 24.6807 9.20495 24.0542 7.69228C23.4276 6.17961 22.5083 4.80567 21.3491 3.64941L19.3906 5.60786C20.2922 6.50722 21.0072 7.5759 21.4945 8.75248C21.9818 9.92906 22.2317 11.1903 22.23 12.4638C22.23 15.1397 21.1441 17.5635 19.3906 19.3198L21.3491 21.2782Z"
                    fill="black"
                  />
                </svg>
              </div>
              <Flicking
                onSelect={(e) => {
                  selectEscuchar(e);
                }}
                tag="div"
                viewportTag="div"
                cameraTag="div"
                classPrefix="eg-flick-escuchar"
                deceleration={0.0075}
                horizontal={true}
                isConstantSize={true}
                defaultIndex={0}
                inputType={["touch", "mouse"]}
                bounce={10}
                autoResize={false}
                adaptive={false}
                zIndex={0}
                bound={true}
                overflow={false}
                hanger={"0%"}
                anchor={"0%"}
                gap={0}
                moveType={{ type: "freeScroll", count: 1 }}
                collectStatistics={false}
              >
                <div className="w-4"></div>
                {topics[topic].escuchar &&
                  topics[topic].escuchar.map((escucha, index) => {
                    return (
                      <Fragment key={index}>
                        <div className="w-32 h-32 cursor-pointer bg-higia-gray-4 shadow-lg capitalize rounded-lg text-lg text-center break-words whitespace-normal text-white flex flex-col justify-center">
                          play
                        </div>
                        <div className="w-4"></div>
                      </Fragment>
                    );
                  })}
              </Flicking>
            </div>
          )}
        </div>
        <div className="mx-8">
          <div className="mt-8">
            <div className="text-lg font-bold mb-4 flex items-center">
              Leer
              <svg
                height="20"
                viewBox="0 0 25 25"
                className="ml-4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.8751 1.62754H3.12585C2.71147 1.62754 2.31406 1.79901 2.02104 2.10424C1.72803 2.40946 1.56342 2.82343 1.56342 3.25508V22.1118L4.68829 18.8567C5.27419 18.2462 6.06888 17.9031 6.89758 17.9029H21.8751C22.2895 17.9029 22.6869 17.7315 22.9799 17.4263C23.2729 17.121 23.4376 16.7071 23.4376 16.2754V3.25508C23.4376 2.82343 23.2729 2.40946 22.9799 2.10424C22.6869 1.79901 22.2895 1.62754 21.8751 1.62754ZM3.12585 0C2.29709 0 1.50226 0.342945 0.916232 0.953391C0.330204 1.56384 0.000976563 2.39178 0.000976562 3.25508L0.000976563 24.0762C0.0010094 24.2372 0.0469007 24.3946 0.132842 24.5285C0.218784 24.6623 0.340912 24.7666 0.483769 24.8281C0.626627 24.8896 0.783791 24.9056 0.935371 24.874C1.08695 24.8424 1.22613 24.7647 1.3353 24.6507L5.79294 20.0074C6.08588 19.7021 6.48323 19.5306 6.89758 19.5305H21.8751C22.7039 19.5305 23.4987 19.1875 24.0847 18.5771C24.6708 17.9666 25 17.1387 25 16.2754V3.25508C25 2.39178 24.6708 1.56384 24.0847 0.953391C23.4987 0.342945 22.7039 0 21.8751 0L3.12585 0Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.68823 5.69658C4.68823 5.48076 4.77054 5.27377 4.91705 5.12116C5.06355 4.96855 5.26226 4.88281 5.46945 4.88281H19.5314C19.7386 4.88281 19.9373 4.96855 20.0838 5.12116C20.2303 5.27377 20.3126 5.48076 20.3126 5.69658C20.3126 5.91241 20.2303 6.11939 20.0838 6.27201C19.9373 6.42462 19.7386 6.51035 19.5314 6.51035H5.46945C5.26226 6.51035 5.06355 6.42462 4.91705 6.27201C4.77054 6.11939 4.68823 5.91241 4.68823 5.69658ZM4.68823 9.76543C4.68823 9.54961 4.77054 9.34262 4.91705 9.19001C5.06355 9.0374 5.26226 8.95166 5.46945 8.95166H19.5314C19.7386 8.95166 19.9373 9.0374 20.0838 9.19001C20.2303 9.34262 20.3126 9.54961 20.3126 9.76543C20.3126 9.98126 20.2303 10.1882 20.0838 10.3409C19.9373 10.4935 19.7386 10.5792 19.5314 10.5792H5.46945C5.26226 10.5792 5.06355 10.4935 4.91705 10.3409C4.77054 10.1882 4.68823 9.98126 4.68823 9.76543ZM4.68823 13.8343C4.68823 13.6185 4.77054 13.4115 4.91705 13.2589C5.06355 13.1063 5.26226 13.0205 5.46945 13.0205H13.2816C13.4888 13.0205 13.6875 13.1063 13.8341 13.2589C13.9806 13.4115 14.0629 13.6185 14.0629 13.8343C14.0629 14.0501 13.9806 14.2571 13.8341 14.4097C13.6875 14.5623 13.4888 14.6481 13.2816 14.6481H5.46945C5.26226 14.6481 5.06355 14.5623 4.91705 14.4097C4.77054 14.2571 4.68823 14.0501 4.68823 13.8343Z"
                  fill="black"
                />
              </svg>
            </div>
            {topics[topic].posts.map((post) => (
              <Link
                to={`/descubrir/${topic}/${post.link}`}
                key={post.link}
                className="flex items-center  md:w-6/12 lg:w-4/12 mt-6"
              >
                <div
                  className="h-16 w-16 flex justify-center items-center rounded-full bg-higia-bermuda flex-grow"
                  style={{ flex: "0 0 4rem" }}
                >
                  {post.img ?? (
                    <svg
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.8751 1.62754H3.12585C2.71147 1.62754 2.31406 1.79901 2.02104 2.10424C1.72803 2.40946 1.56342 2.82343 1.56342 3.25508V22.1118L4.68829 18.8567C5.27419 18.2462 6.06888 17.9031 6.89758 17.9029H21.8751C22.2895 17.9029 22.6869 17.7315 22.9799 17.4263C23.2729 17.121 23.4376 16.7071 23.4376 16.2754V3.25508C23.4376 2.82343 23.2729 2.40946 22.9799 2.10424C22.6869 1.79901 22.2895 1.62754 21.8751 1.62754ZM3.12585 0C2.29709 0 1.50226 0.342945 0.916232 0.953391C0.330204 1.56384 0.000976563 2.39178 0.000976562 3.25508L0.000976563 24.0762C0.0010094 24.2372 0.0469007 24.3946 0.132842 24.5285C0.218784 24.6623 0.340912 24.7666 0.483769 24.8281C0.626627 24.8896 0.783791 24.9056 0.935371 24.874C1.08695 24.8424 1.22613 24.7647 1.3353 24.6507L5.79294 20.0074C6.08588 19.7021 6.48323 19.5306 6.89758 19.5305H21.8751C22.7039 19.5305 23.4987 19.1875 24.0847 18.5771C24.6708 17.9666 25 17.1387 25 16.2754V3.25508C25 2.39178 24.6708 1.56384 24.0847 0.953391C23.4987 0.342945 22.7039 0 21.8751 0L3.12585 0Z"
                        fill="black"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.68823 5.69658C4.68823 5.48076 4.77054 5.27377 4.91705 5.12116C5.06355 4.96855 5.26226 4.88281 5.46945 4.88281H19.5314C19.7386 4.88281 19.9373 4.96855 20.0838 5.12116C20.2303 5.27377 20.3126 5.48076 20.3126 5.69658C20.3126 5.91241 20.2303 6.11939 20.0838 6.27201C19.9373 6.42462 19.7386 6.51035 19.5314 6.51035H5.46945C5.26226 6.51035 5.06355 6.42462 4.91705 6.27201C4.77054 6.11939 4.68823 5.91241 4.68823 5.69658ZM4.68823 9.76543C4.68823 9.54961 4.77054 9.34262 4.91705 9.19001C5.06355 9.0374 5.26226 8.95166 5.46945 8.95166H19.5314C19.7386 8.95166 19.9373 9.0374 20.0838 9.19001C20.2303 9.34262 20.3126 9.54961 20.3126 9.76543C20.3126 9.98126 20.2303 10.1882 20.0838 10.3409C19.9373 10.4935 19.7386 10.5792 19.5314 10.5792H5.46945C5.26226 10.5792 5.06355 10.4935 4.91705 10.3409C4.77054 10.1882 4.68823 9.98126 4.68823 9.76543ZM4.68823 13.8343C4.68823 13.6185 4.77054 13.4115 4.91705 13.2589C5.06355 13.1063 5.26226 13.0205 5.46945 13.0205H13.2816C13.4888 13.0205 13.6875 13.1063 13.8341 13.2589C13.9806 13.4115 14.0629 13.6185 14.0629 13.8343C14.0629 14.0501 13.9806 14.2571 13.8341 14.4097C13.6875 14.5623 13.4888 14.6481 13.2816 14.6481H5.46945C5.26226 14.6481 5.06355 14.5623 4.91705 14.4097C4.77054 14.2571 4.68823 14.0501 4.68823 13.8343Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col flex-grow mx-2 ">
                  <div className="font-bold">{post.title}</div>
                  <div>Por: {post.author}</div>
                </div>
                <div
                  className="h-12 w-12 rounded-full bg-higia-gray-5 flex items-center justify-center shadow-lg"
                  style={{ flex: "0 0 3rem" }}
                >
                  <svg
                    width="11"
                    className="ml-1"
                    height="16"
                    viewBox="0 0 11 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.0415183 13.6777L6.5702 8.09335L0.244753 2.27982L1.57081 0.0231646L10.4264 8.16212L1.28628 15.9802L0.0415183 13.6777Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { changeNavColors, changeNavLogo })(
  Topic
);
