import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import {
  parseISO,
  getDay,
  format,
  add,
  isAfter,
  getHours,
  getMinutes,
  isWithinInterval,
} from "date-fns";
import { getSpecialistByNombres } from "../../actions/especialistas";
import { updateCheckout } from "../../actions/checkout";
import Spinner from "../layout/Spinner";
import Progress from "./Progress";
import "./Calendar.css";

const TerapeutaHorario = ({
  match,
  updateCheckout,
  specialists,
  user,
  getSpecialistByNombres,
}) => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState("");

  useEffect(() => {
    getSpecialistByNombres(match.params.nombres.replace("-", " "));
  }, [getSpecialistByNombres, match.params.nombres]);

  const info = specialists.specialist;
  const completed = () => {
    if (selectedHour) {
      updateCheckout({
        cliente: user._id,
        newSession: true,
        especialista: info._id,
        tarifa: info.tarifas,
        horaPactada: add(selectedDay, {
          hours: selectedHour.split(":")[0],
          minutes: selectedHour.split(":")[1],
        }),
        reagendo: false,
      });
    }
  };

  let availableHorarios = [];
  if (info && info.horarios) {
    const sesionesOcupadas = info.sessions.map((sessionInfo) =>
      parseISO(sessionInfo.horaPactada)
    );

    Object.values(info.horarios).forEach((val) => {
      let horario = parseISO(val);
      let fechaDisponible = add(selectedDay, {
        hours: getHours(horario),
        minutes: getMinutes(horario),
      });
      if (
        getDay(horario) === getDay(selectedDay) && // Same day
        isAfter(selectedDay, add(new Date(), { days: 1 })) && // After one day
        sesionesOcupadas.every(
          (sesion) =>
            !isWithinInterval(fechaDisponible, {
              start: sesion,
              end: add(sesion, { minutes: 50 }),
            })
        ) // Is not already taken
      ) {
        availableHorarios.push(format(fechaDisponible, "HH:mm"));
      }
    });
  }

  return specialists.loading || !info ? (
    <Spinner />
  ) : (
    <section
      className="container px-8 pt-8 flex-grow flex flex-col mx-auto font-sans"
      styles={{ height: "max-content" }}
    >
      <span className="font-bold mb-2">2. Horario</span>
      <Progress selected={1} />
      <div className="font-bold text-lg mt-8 mb-6">Escoge la fecha</div>
      <Calendar
        onChange={(value) => {
          setSelectedDay(value);
          setSelectedHour("");
        }}
        value={selectedDay}
        locale="es-ES"
      />
      <span className="font-bold mt-8 mb-4">Horarios Disponibles</span>
      <div className="flex flex-wrap mb-2">
        {availableHorarios.length > 0 ? (
          availableHorarios.sort().map((hora) => (
            <div
              className={`flex align-center border-solid cursor-pointer border-2 rounded px-3 mr-3 mb-2 ${
                selectedHour === hora
                  ? "border-higia-bermuda"
                  : "border-higia-gray-2"
              }`}
              key={hora}
              style={{ alignItems: "center" }}
              onClick={() => {
                setSelectedHour(hora);
              }}
            >
              <div
                className={`w-3 h-3 rounded-full inline border border-solid border-black mr-2 ${
                  selectedHour === hora ? "bg-higia-bermuda" : ""
                }`}
              ></div>
              <div className="">{hora}</div>
            </div>
          ))
        ) : (
          <div>No hay horarios diponibles este día</div>
        )}
      </div>
      <div className="flex justify-between mt-4 mb-10">
        <Link
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to={`${match.url.replace("/horarios", "")}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
            style={{ transform: "scale(-1, 1)" }}
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
        <Link
          className={`w-20 text-white p-2 rounded flex justify-center ${
            selectedHour ? "bg-higia-bermuda" : "bg-gray-400"
          }`}
          to={`${selectedHour ? `/casiacabamos` : "#"}`}
          onClick={() => completed()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getSpecialistByNombres,
  updateCheckout,
})(TerapeutaHorario);
