import { format } from "date-fns";
import { es } from "date-fns/locale";
import api from "../utils/api";
import { setAlert } from "./alert";
import {
  SELECT_DAY,
  REGISTER_SESSION_SUCCESS,
  REGISTER_SESSION_FAILURE,
} from "./types";

// Update Checkout
export const updateCheckout = (data) => async (dispatch) => {
  dispatch({
    type: SELECT_DAY,
    payload: data,
  });
};

// Register session
export const registerSession = (data) => async (dispatch) => {
  try {
    const res = await api.post("/sessions/client", {
      ...data,
      horaLocal: format(data.horaPactada, "hh:mm aaaa", {
        locale: es,
      }),
      fechaLocal: format(data.horaPactada, "d 'de' LLLL", {
        locale: es,
      }),
    });
    dispatch({
      type: REGISTER_SESSION_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_SESSION_FAILURE,
    });
  }
};
