import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loginSpecialist } from '../../actions/auth';
import { connect } from 'react-redux';
import laidDown from '../../img/laidDown.jpg';

const Login = ({ loginSpecialist, isAuthenticated, alerts }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    loginSpecialist(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to='/homeSpecialist' />;
  }

  return (
    <>
      <section
        className='w-full flex-grow flex flex-col mx-auto font-sans'
        styles={{ height: 'max-content' }}
      >
        <header
          className={`bg-no-repeat bg-cover relative header-login flex flex-col-reverse`}
          style={{
            backgroundImage: `url(${laidDown})`,
          }}
        >
          <div className='text-white relative overflow-hidden'>
            <svg
              className='fill-current relative'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 640 62'
              transform='scale(1,-1)'
              style={{ bottom: '-2px' }}
            >
              <path d='M0 41.32l35.56 3.45c35.55 3.33 106.66 10.43 177.77 5.17 71.11-5.07 142.23-22.5 213.34-22.41 71.11-.09 142.22 17.34 177.77 25.83 2.37.57 14.23 3.45 35.56 8.62V0H0v41.32z' />
            </svg>
          </div>
        </header>

        <div
          className='container flex-grow flex flex-col mx-auto font-sans'
          styles={{ height: 'max-content' }}
        >
          <h2 className='text-higia-cyan font-extrabold text-center text-3xl mb-5'>
            ¡Hola!
          </h2>
          <form className='flex flex-col items-center' onSubmit={onSubmit}>
            <div className='w-3/4 lg:w-1/3 px-4 py-3 rounded-lg border border-solid border-gray-400'>
              <input
                className='outline-none w-full bg-white'
                type='email'
                placeholder='¿Cuál es tu correo?'
                name='email'
                value={email}
                onChange={onChange}
                autoComplete='username'
                required
              />
            </div>
            <div className='w-3/4 lg:w-1/3 px-4 py-3 my-2 rounded-lg border border-solid border-gray-400'>
              <input
                className='outline-none w-full'
                type='password'
                placeholder='Ingresa tu contraseña'
                name='password'
                value={password}
                onChange={onChange}
                autoComplete='current-password'
                minLength='6'
              />
            </div>
            {alerts.length > 0 ? (
              <div className='text-sm text-red-400'>Credenciales inválidas</div>
            ) : null}
            <input
              type='submit'
              className='w-3/4 lg:w-1/3 mt-4 rounded-lg px-4 py-4 bg-higia-bermuda text-white cursor-pointer text-lg'
              value='Entrar'
            />
            <Link
              className='text-higia-cyan underline mt-4 text-sm'
              to='/login'
            >
              ¿Eres usuario? Entra aquí
            </Link>
          </form>
        </div>
      </section>
    </>
  );
};

Login.propTypes = {
  loginSpecialist: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  alerts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  alerts: state.alert,
});

export default connect(mapStateToProps, { loginSpecialist })(Login);
