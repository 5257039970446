import React from "react";
import Footer from "../layout/Footer";
import preguntas from "../../img/Preguntas.jpg";

const Preguntas = () => {
  return (
    <>
      <div className="flex flex-col">
        <header
          className={`bg-no-repeat bg-cover relative header-beneficios flex flex-col-reverse xl:container xl:mx-auto`}
          style={{
            backgroundImage: `url(${preguntas})`,
          }}
        >
          <div className="text-white relative overflow-hidden">
            <svg
              viewBox="0 0 640 62"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current relative"
              style={{ bottom: "-2px" }}
            >
              <path fill="none" d="M-1-1h802v602H-1z" />
              <path d="M640.043 20.486l-35.56-3.45c-35.55-3.33-106.66-10.43-177.77-5.17-71.11 5.07-142.23 22.5-213.34 22.41-71.11.09-142.22-17.34-177.77-25.83-2.37-.57-14.23-3.45-35.56-8.62v61.98h640v-41.32z" />
            </svg>
          </div>
        </header>
        <div className="flex flex-col text-center">
          <div>
            <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
              ¿Qué es la psicoterapia?
            </div>
            <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
              <div className="font-semibold mt-4">
                La psicoterapia es un proceso de colaboración basado en la
                relación de una persona y un psicólogo.
              </div>
              <div className="font-semibold mt-4">
                Como su base fundamental es el diálogo, esta te proporcionará un
                ambiente de apoyo que te permitirá hablar abiertamente con
                alguien objetivo, neutral e imparcial.
              </div>
              <div className="font-semibold mt-4">
                Tu psicólogo y tú, trabajarán juntos para identificar y cambiar
                los patrones de pensamiento y comportamiento que impiden
                sentirte bien.
              </div>
              <div className="font-semibold mt-4">
                Al concluir este proceso, no sólo habrás resuelto el problema
                que te trajo a la consulta, sino que, además, habrás aprendido
                nuevas destrezas para enfrentar con mayor efectividad cualquier
                desafío futuro.
              </div>
            </div>
            <div className="mt-4">
              <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
                ¿Qué es Higia?
              </div>
              <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
                <div className="font-semibold mt-4">
                  Higia hace las terapias psicológicas más accesibles y al
                  alcance de todos. Te brindamos un espacio seguro, privado y de
                  confianza donde te conectes con el especialista que mejor
                  conecte contigo para que juntos recuperes tu bienestar
                  emocional y tengas una mejor calidad de vida.
                </div>
                <div className="font-semibold mt-4">
                  Nuestra misión es ser el aliado que te ayude a encontrar el
                  bienestar que tanto buscas.
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
                ¿Cómo funciona Higia?
              </div>
              <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
                <div className="font-semibold mt-4">
                  Higia es una plataforma donde puedes escoger al psicólogo
                  ideal para ti. Aquí podrás agendar y tomar tus sesiones por
                  video o llamada de una manera rápida, segura y privada.
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
                ¿Cómo Higia puede ayudarme?
              </div>
              <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
                <div className="font-semibold mt-4 whitespace-pre-wrap">
                  Contamos con un equipo de terapeutas experimentados que pueden
                  ayudarte en: {"\n"}- Ansiedad y estrés {"\n"}- Depresión{" "}
                  {"\n"}- Autoestima {"\n"}- Parejas {"\n"}- Desarrollo personal{" "}
                  {"\n"}- Fobia social {"\n"}- Duelo {"\n"}- Manejo de emociones{" "}
                  {"\n"}- Terapia de pareja
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
                ¿Cuáles son los servicios de Higia?
              </div>
              <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
                <div className="font-semibold mt-4">
                  - Terapia individual : te ayudamos a resolver situaciones que
                  compliquen tu vida cotidiana, aprendiendo a reconocer aceptar
                  y gestionar tus emociones, pensamientos y conductas
                  <br />
                  - Terapia de pareja: te ayudamos a resolver diferencias o
                  conflictos con tu pareja, para lograr una mayor estabilidad
                  emocional y una vida más plena
                  <br />
                  - Terapia familiar: te ayudamos a conocer comprender y aceptar
                  a tu familia, a través del diálogo y las transacciones
                  saludables
                  <br />
                  - Terapia de grupo: tenemos grupos específicos según temas de
                  interés que les permitirán a través del aprendizaje colectivo
                  mejorar conductas y cogniciones para así lograr un mejor
                  desarrollo emocional
                  <br />- Compartiendo tu viaje: espacio de escucha activa
                  diseñado para que puedas contarnos tus experiencias,
                  progresos, preocupaciones y también proponer temas de tu
                  interés para talleres y conversatorios. El acceso es gratuito
                  para usuarios de Higia, las sesiones son el primer y tercer
                  miércoles de cada mes 8:15-9:30pm.
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="font-bold mt-12 mx-8 md:w-6/12 md:mx-auto flex-none px-3 py-3 text-higia-gray-3 rounded-lg border-2 border-solid border-gray-400">
                ¿Quiénes forman parte de Higia?
              </div>
              <div className="mx-10 mt-4 md:w-6/12 md:mx-auto text-left">
                <div className="font-semibold mt-4">
                  Contamos con terapeutas con bastante experiencia, todos
                  seleccionados cuidadosamentea para poder ayudarte en nuestra
                  plataforma.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Preguntas;
