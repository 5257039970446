import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { upsertApuntes } from '../../actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const NuevoApunte = ({ match, auth, upsertApuntes }) => {
  const [redirectHome, setRedirectHome] = useState(false);
  const apunteRef = useRef(null);
  const tituloRef = useRef(null);

  if (redirectHome) {
    return <Redirect to='/home' />;
  }

  const data = auth.user;
  const apunte = data.apuntes[match.params.apunteId];

  const saveApuntes = async () => {
    try {
      if (tituloRef.current.innerText) {
        const newApuntes = {
          apuntes: {
            ...data.apuntes,
            [match.params.apunteId]: {
              ultimaEdicion: new Date(),
              title: tituloRef.current.innerText,
              contents: apunteRef.current.innerText,
            },
          },
        };
        await upsertApuntes(newApuntes);
        setRedirectHome(true);
      }
    } catch {
      return null;
    }
  };

  const deleteApunte = async () => {
    const { [match.params.apunteId]: currentApunte, ...otherApuntes } = {
      ...data.apuntes,
    };
    await upsertApuntes({ apuntes: otherApuntes });
  };

  return apunte ? (
    <div className='container px-8 mx-auto mt-6'>
      <div className='flex items-center'>
        <div className='font-bold text-xl mr-2'>Mis apuntes</div>
        <svg
          width='25'
          height='25'
          viewBox='0 0 25 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.04158 17.1416L9.87596 16.6124L20.9208 5.43536C21.0079 5.34608 21.0564 5.22612 21.0558 5.10138C21.0552 4.97665 21.0056 4.85715 20.9176 4.76869L20.2562 4.09994C20.2134 4.05602 20.1624 4.02106 20.106 3.99708C20.0496 3.97311 19.9889 3.96062 19.9277 3.96033C19.8664 3.96004 19.8056 3.97196 19.749 3.99539C19.6924 4.01882 19.641 4.0533 19.5978 4.09682L8.58221 15.2447L8.04054 17.1406L8.04158 17.1416ZM21.5655 2.77494L22.227 3.44473C23.1395 4.36869 23.1478 5.85932 22.2437 6.7739L10.8624 18.2927L6.94159 19.4218C6.70221 19.4888 6.44602 19.4581 6.22925 19.3365C6.01248 19.2148 5.85283 19.0121 5.78534 18.7729C5.73511 18.6009 5.73439 18.4182 5.78325 18.2458L6.92388 14.2458L18.2749 2.75723C18.4908 2.5399 18.7477 2.36771 19.0308 2.25073C19.3139 2.13374 19.6174 2.07428 19.9237 2.07584C20.23 2.07739 20.533 2.13991 20.8148 2.25976C21.0967 2.37961 21.3519 2.55439 21.5655 2.7739V2.77494ZM9.56659 3.97598C10.0833 3.97598 10.502 4.39994 10.502 4.92286C10.5028 5.04647 10.4793 5.16903 10.4327 5.28354C10.3861 5.39804 10.3174 5.50224 10.2306 5.59018C10.1437 5.67812 10.0403 5.74808 9.92639 5.79604C9.81247 5.84401 9.6902 5.86905 9.56659 5.86973H5.82492C4.79159 5.86973 3.95409 6.71765 3.95409 7.76244V19.1229C3.95409 20.1687 4.79159 21.0166 5.82492 21.0166H17.0499C18.0833 21.0166 18.9218 20.1687 18.9218 19.1229V15.3364C18.9218 14.8135 19.3405 14.3895 19.8572 14.3895C20.3739 14.3895 20.7926 14.8135 20.7926 15.3374V19.1229C20.7926 21.2145 19.1166 22.9104 17.0499 22.9104H5.82492C3.75825 22.9104 2.08325 21.2145 2.08325 19.1229V7.76244C2.08325 5.67182 3.75825 3.97598 5.82492 3.97598H9.56659V3.97598Z'
            fill='black'
          />
        </svg>
      </div>
      <div className='bg-higia-crema rounded-lg p-4 mt-6'>
        <div>
          <div
            suppressContentEditableWarning
            ref={tituloRef}
            tabIndex='0'
            className='overflow-auto flex-auto text-lg font-bold outline-none whitespace-pre-wrap'
            role='textbox'
            style={{ minHeight: '2.5rem' }}
            contentEditable
          >
            {apunte.title}
          </div>
        </div>

        <div>
          <div
            suppressContentEditableWarning
            ref={apunteRef}
            id='apunte'
            tabIndex='0'
            className='overflow-auto flex-auto outline-none mt-2 whitespace-pre-wrap'
            role='textbox'
            style={{ minHeight: '12rem' }}
            contentEditable
          >
            {apunte.contents ? apunte.contents : ''}
          </div>
        </div>
        <div onClick={deleteApunte} className='cursor-pointer inline-block'>
          <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M23.75 5H19.375L18.125 3.75H11.875L10.625 5H6.25V7.5H23.75V5ZM7.5 8.75V23.75C7.5 25.125 8.625 26.25 10 26.25H20C21.375 26.25 22.5 25.125 22.5 23.75V8.75H7.5ZM17.5 17.5V22.5H12.5V17.5H10L15 12.5L20 17.5H17.5Z'
              fill='#868686'
            />
          </svg>
        </div>
      </div>
      <div className='flex mt-6 justify-between'>
        <Link
          className='w-20 text-white p-2 rounded-lg flex justify-center bg-higia-bermuda'
          to='home'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 8 12'
            className='fill-current pt-1'
            width='12'
            height='18'
            style={{ transform: 'scale(-1, 1)' }}
          >
            <path
              d='M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z'
            />
          </svg>
        </Link>
        <button
          className='bg-higia-bermuda text-white py-2 px-3 rounded-lg'
          onClick={saveApuntes}
        >
          Guardar
        </button>
      </div>
    </div>
  ) : (
    <Redirect to='/home' />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { upsertApuntes })(NuevoApunte);
