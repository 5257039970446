import React, { useState, useRef } from "react";

const Input = ({ sendMessage, placeHolder, datePicker }) => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(true);
  const [oneTime, setOneTime] = useState(false);
  const msgRef = useRef(null);
  if (msgRef.current) {
    msgRef.current.focus();
  }

  if (placeHolder && !showPlaceHolder && !oneTime) {
    setShowPlaceHolder(true);
    setOneTime(true);
  }

  const accept = (e) => {
    e.preventDefault();
    sendMessage(msgRef.current.value);
    msgRef.current.value = "";
  };

  return (
    <form
      className="mt-auto flex m-w-full justify-between align-center p-3 bg-gray-200"
      onClick={() => {
        return setShowPlaceHolder(false);
      }}
    >
      {showPlaceHolder ? (
        <span className="text-gray-500 absolute overflow-auto flex-auto min-w-8 mr-3 p-2 outline-none pointer-events-none">
          {placeHolder ? placeHolder : "Escribe un mensaje"}
        </span>
      ) : null}
      <input
        autoComplete="off"
        id="test"
        ref={msgRef}
        tabIndex="0"
        className="overflow-auto flex-auto mr-3 p-2 rounded-lg bg-white outline-none"
        style={{ minHeight: "2.5rem" }}
        contentEditable
        onKeyPress={(event) => {
          if (
            datePicker &&
            event.key !== "/" &&
            (msgRef.current.value.length === 2 ||
              msgRef.current.value.length === 5)
          ) {
            msgRef.current.value = msgRef.current.value + "/";
            msgRef.current.selectionStart = msgRef.current.selectionEnd = 100;
          }
          if (event.key === "Enter") {
            return accept(event);
          }
          setShowPlaceHolder(false);
          return null;
        }}
      ></input>
      <button
        className="items-end mr-2 text-gray-600"
        onClick={(e) => accept(e)}
      >
        <svg
          className="fill-current"
          enableBackground="new 0 0 512 512"
          height="24"
          viewBox="0 0 512 512"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d="m329.065 512h-53.065c-19.333 0-35.156-15.319-35.967-34.457l-29.902-175.674-175.674-29.901c-19.138-.812-34.457-16.635-34.457-35.968v-53.065c0-39.738 26.958-74.089 65.557-83.535l402.08-98.402c.056-.014.112-.027.168-.04 12.2-2.847 24.775.742 33.635 9.602 8.859 8.86 12.449 21.434 9.603 33.636-.013.056-.026.112-.04.168l-98.402 402.08c-9.447 38.599-43.798 65.556-83.536 65.556zm146.065-479.892-401.966 98.375c-24.237 5.931-41.164 27.5-41.164 52.452v53.065c0 2.206 1.794 4 4 4 .9 0 1.798.076 2.685.227l188 32c6.7 1.14 11.948 6.389 13.088 13.088l32 188c.151.887.227 1.785.227 2.685 0 2.206 1.794 4 4 4h53.065c24.952 0 46.521-16.927 52.452-41.164l98.376-401.966c.389-1.76-.37-2.973-1.08-3.683-.71-.709-1.924-1.468-3.683-1.079z" />
            <path d="m224 304c-4.095 0-8.189-1.562-11.313-4.687-6.249-6.248-6.249-16.379 0-22.627l160-160c6.248-6.249 16.379-6.249 22.627 0 6.249 6.248 6.249 16.379 0 22.627l-160 160c-3.125 3.125-7.219 4.687-11.314 4.687z" />
          </g>
        </svg>
      </button>
    </form>
  );
};

export default Input;
