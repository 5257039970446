import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { register } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import ChatMsg from "../chat/ChatMsg";
import Input from "../input/Input";
import laidDown from "../../img/laidDown.jpg";

const Register = ({ alert, register, isAuthenticated, cameFrom }) => {
  const [placeHolder, setPlaceHolder] = useState(null);
  const [formData, setFormData] = useState({
    nombres: "",
    celular: "",
    fechaNac: "",
    showFechaNac: "",
    email: "",
    password: "",
  });
  const messagesEndRef = useRef(null);

  useEffect(() => {
    document.getElementById("wsp_button").classList.add("hidden");

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }

    return () => {
      document.getElementById("wsp_button").classList.remove("hidden");
    };
  });

  if (isAuthenticated) {
    return <Redirect to="/empezar" />;
  }

  const { nombres, celular, fechaNac, email, password, showFechaNac } =
    formData;

  const sendMessage = (message) => {
    if (message.slice(-4) === "<br>") {
      message = message.slice(0, -4);
    }
    if (message.slice(-6) === "&nbsp;") {
      message = message.slice(0, -6);
    }

    if (!nombres) {
      setFormData({ ...formData, nombres: message });
      return null;
    }
    if (!celular) {
      setFormData({ ...formData, celular: message });
      setPlaceHolder("DD/MM/YYYY");
      return null;
    }
    if (!fechaNac) {
      const dateArr = message.split("/");
      let year = dateArr[2] ? dateArr[2] : "1";
      if (year.length === 2) {
        year = (parseInt(year) < 25 ? "20" : "19") + year;
      }

      try {
        setFormData({
          ...formData,
          fechaNac: format(
            new Date(year, parseInt(dateArr[1]) - 1, dateArr[0]),
            "P"
          ),
          showFechaNac: message,
        });
      } catch {
        setFormData({ ...formData, fechaNac: "-", showFechaNac: message });
      }
      return null;
    }
    if (!email) {
      setFormData({ ...formData, email: message });
      return null;
    }
    if (!password) {
      setFormData({ ...formData, password: message });
      register({ ...formData, password: message, cameFrom: cameFrom });
      return null;
    }
  };

  return (
    <Fragment>
      <header
        className={`bg-no-repeat bg-cover relative header-login flex flex-col-reverse xl:container xl:mx-auto`}
        style={{
          backgroundImage: `url(${laidDown})`,
        }}
      >
        <div className="text-white relative overflow-hidden">
          <svg
            viewBox="0 0 640 62"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current relative"
            style={{ bottom: "-2px" }}
          >
            <path fill="none" d="M-1-1h802v602H-1z" />
            <path d="M640.043 20.486l-35.56-3.45c-35.55-3.33-106.66-10.43-177.77-5.17-71.11 5.07-142.23 22.5-213.34 22.41-71.11.09-142.22-17.34-177.77-25.83-2.37-.57-14.23-3.45-35.56-8.62v61.98h640v-41.32z" />
          </svg>
        </div>
      </header>
      <section
        className="container flex-grow flex flex-col mx-auto font-sans"
        styles={{ height: "max-content" }}
      >
        <h2 className="text-higia-cyan font-extrabold text-center text-3xl mb-5">
          ¡Hola!
        </h2>

        <div
          className="flex-auto overflow-auto flex flex-col-reverse h-0 p-3"
          style={{ minHeight: "250px" }}
        >
          <div className="flex flex-col flex-grow overflow-auto">
            <ChatMsg text="Por favor completa tus datos, para empezar ¿Cómo te llamas?" />
            {nombres ? <ChatMsg fstMsg alignRight text={nombres} /> : null}
            {nombres ? (
              <>
                <ChatMsg
                  fstMsg
                  text={`¡Hola ${
                    nombres.split(" ")[0]
                  }! Bienvenid@ a Higia por favor ayúdanos con los siguientes datos para continuar.`}
                />
                <ChatMsg fstMsg text="¿Cuál es tu número de celular?" />
              </>
            ) : null}
            {celular ? <ChatMsg fstMsg alignRight text={celular} /> : null}
            {celular ? (
              <ChatMsg text="¡Gracias! ¿Cuándo naciste? (DD/MM/YYYY)" />
            ) : null}
            {fechaNac ? (
              <ChatMsg fstMsg alignRight text={showFechaNac} />
            ) : null}
            {fechaNac ? (
              <ChatMsg text="¿Cuál es tu correo electrónico?" />
            ) : null}
            {email ? <ChatMsg fstMsg alignRight text={email} /> : null}
            {email ? (
              <ChatMsg text="Por favor ingresa una contraseña (Mínimo 6 caracteres)" />
            ) : null}
            {password ? (
              <ChatMsg
                fstMsg
                alignRight
                text={[...password].map(() => "*").join("")}
              />
            ) : null}
            {alert.length ? (
              <>
                <ChatMsg fstMsg text={`Surgieron los siguientes problemas:`} />
                {alert.map((el, i) => (
                  <ChatMsg key={i} text={el.msg} />
                ))}
                <div className="flex justify-center">
                  <button
                    className="bg-higia-bermuda rounded text-white p-3 m-3 max-w-xs"
                    onClick={() => window.location.reload()}
                  >
                    Intentar de nuevo
                  </button>
                </div>
              </>
            ) : null}
            {!alert.length && password ? (
              <ChatMsg fstMsg text={`Estamos procesando tus datos...`} />
            ) : null}
            <div className="flex-grow" ref={messagesEndRef}></div>
          </div>
        </div>
        <Input
          sendMessage={sendMessage}
          placeHolder={placeHolder}
          datePicker={nombres && celular && !fechaNac}
        />
      </section>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  cameFrom: state.auth.cameFrom,
  alert: state.alert,
});

export default connect(mapStateToProps, { register, setAlert })(Register);
