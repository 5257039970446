import { CHANGE_NAV_COLORS, CHANGE_NAV_LOGO } from '../actions/types';

const initialState = { showLogo: true };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_NAV_COLORS:
    case CHANGE_NAV_LOGO:
      return { ...state, ...payload };
    default:
      return state;
  }
}
