import React, { useEffect } from 'react';
import { changeNavColors, changeNavLogo } from '../../../actions/nav';
import { connect } from 'react-redux';
import topics from '../TopicsIndex';

const PrimerPost = ({ match, changeNavColors, changeNavLogo }) => {
  const topic = match.params.topic;
  const post = topics[topic].posts.filter(
    (post) => post.link === 'por-que-autoestima-importante'
  )[0];

  useEffect(() => {
    changeNavColors(topics[topic].bg);
    changeNavLogo({ showLogo: false, previous: topics[topic].previous });
    return () => {
      changeNavColors('');
      changeNavLogo({ showLogo: true, previous: '' });
    };
  }, [changeNavColors, changeNavLogo, topic]);

  return (
    <div className='container mx-auto md:w-6/12'>
      <div className='mx-8 md:mx-0'>
        <div className='font-bold mt-6 text-lg'>{post.title}</div>
        <div className='flex items-center justify-between mt-4'>
          <div className='h-16 w-16 flex justify-center items-center rounded-full bg-higia-bermuda'>
            {post.img ?? (
              <svg
                height='25'
                viewBox='0 0 25 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.8751 1.62754H3.12585C2.71147 1.62754 2.31406 1.79901 2.02104 2.10424C1.72803 2.40946 1.56342 2.82343 1.56342 3.25508V22.1118L4.68829 18.8567C5.27419 18.2462 6.06888 17.9031 6.89758 17.9029H21.8751C22.2895 17.9029 22.6869 17.7315 22.9799 17.4263C23.2729 17.121 23.4376 16.7071 23.4376 16.2754V3.25508C23.4376 2.82343 23.2729 2.40946 22.9799 2.10424C22.6869 1.79901 22.2895 1.62754 21.8751 1.62754ZM3.12585 0C2.29709 0 1.50226 0.342945 0.916232 0.953391C0.330204 1.56384 0.000976563 2.39178 0.000976562 3.25508L0.000976563 24.0762C0.0010094 24.2372 0.0469007 24.3946 0.132842 24.5285C0.218784 24.6623 0.340912 24.7666 0.483769 24.8281C0.626627 24.8896 0.783791 24.9056 0.935371 24.874C1.08695 24.8424 1.22613 24.7647 1.3353 24.6507L5.79294 20.0074C6.08588 19.7021 6.48323 19.5306 6.89758 19.5305H21.8751C22.7039 19.5305 23.4987 19.1875 24.0847 18.5771C24.6708 17.9666 25 17.1387 25 16.2754V3.25508C25 2.39178 24.6708 1.56384 24.0847 0.953391C23.4987 0.342945 22.7039 0 21.8751 0L3.12585 0Z'
                  fill='black'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.68823 5.69658C4.68823 5.48076 4.77054 5.27377 4.91705 5.12116C5.06355 4.96855 5.26226 4.88281 5.46945 4.88281H19.5314C19.7386 4.88281 19.9373 4.96855 20.0838 5.12116C20.2303 5.27377 20.3126 5.48076 20.3126 5.69658C20.3126 5.91241 20.2303 6.11939 20.0838 6.27201C19.9373 6.42462 19.7386 6.51035 19.5314 6.51035H5.46945C5.26226 6.51035 5.06355 6.42462 4.91705 6.27201C4.77054 6.11939 4.68823 5.91241 4.68823 5.69658ZM4.68823 9.76543C4.68823 9.54961 4.77054 9.34262 4.91705 9.19001C5.06355 9.0374 5.26226 8.95166 5.46945 8.95166H19.5314C19.7386 8.95166 19.9373 9.0374 20.0838 9.19001C20.2303 9.34262 20.3126 9.54961 20.3126 9.76543C20.3126 9.98126 20.2303 10.1882 20.0838 10.3409C19.9373 10.4935 19.7386 10.5792 19.5314 10.5792H5.46945C5.26226 10.5792 5.06355 10.4935 4.91705 10.3409C4.77054 10.1882 4.68823 9.98126 4.68823 9.76543ZM4.68823 13.8343C4.68823 13.6185 4.77054 13.4115 4.91705 13.2589C5.06355 13.1063 5.26226 13.0205 5.46945 13.0205H13.2816C13.4888 13.0205 13.6875 13.1063 13.8341 13.2589C13.9806 13.4115 14.0629 13.6185 14.0629 13.8343C14.0629 14.0501 13.9806 14.2571 13.8341 14.4097C13.6875 14.5623 13.4888 14.6481 13.2816 14.6481H5.46945C5.26226 14.6481 5.06355 14.5623 4.91705 14.4097C4.77054 14.2571 4.68823 14.0501 4.68823 13.8343Z'
                  fill='black'
                />
              </svg>
            )}
          </div>
          <div className='flex flex-col ml-5 flex-grow'>
            <div>{post.author}</div>
            <div>Psicóloga</div>
          </div>
        </div>
        <div className='mt-6 mb-8 prose'>
          {/* start */}

          <p>
            Prácticamente todas las esferas o áreas de nuestra vida están
            relacionadas con la autoestima. Se puede considerarla como base de
            la personalidad. También se la considera como la base de relaciones
            interpersonales, rendimiento académico, metas, emociones y
            sentimientos, estados de ánimo, relaciones con los padres,
            motivación hacía el logro, relación con los hermanos, círculo
            familiar.
          </p>
          <p>
            Muy a menudo, las fluctuaciones en la autoestima están asociadas con
            nuestros miedos y dudas internas en el estilo de “¿Y si no puedo
            hacer frente?”, “Es demasiado bueno para ser verdad”, “No me lo
            merezco”, etc. El trato interno que le damos a nosotros mismos esta
            directamente relacionado con el trato que recibiremos de los demás.
            Si no nos tratamos bien ¿nos trataran bien nuestros amigos,
            compañeros, pareja, padres, hijos?
          </p>
          <p>
            Cuando la autoestima es inestable y fluctúa entre alta y baja
            nuestro comportamiento en relación a otros será de agradar a los
            demás o de lo contrario trataremos a los demás con un aire de
            superioridad. En ambos casos sin posibilidad de mostrarnos como
            realmente somos y sin la posibilidad de construir relaciones
            confortables y armoniosas.
          </p>
          <p>
            Se recomienda informarse sobre el tema y buscar apoyo a aquellas
            personas que frecuentemente presentan estas dificultades que se
            mencionan a continuación:
          </p>
          <ul>
            <li>
              <p>Dificultad para elegir o tomar una decisión.</p>
            </li>
            <li>
              <p>Sensación de no vivir su propia vida.</p>
            </li>
            <li>
              <p>No llegar a la meta/tener pocos logros.</p>
            </li>
            <li>
              <p>Incomprensión de los deseos y metas propios.</p>
            </li>
            <li>
              <p>Sentirse dependiente de las opiniones de los demás.</p>
            </li>
            <li>
              <p>
                Tener inestabilidad emocional y temor al fracaso o al logro.
              </p>
            </li>
          </ul>
          <p>
            ¿Sabías que estas dificultades desencadenan en nosotros pensamientos
            autodestructivos? De dónde vienen y cómo neutralizarlos, lo puedes
            descubrir asistiendo a las sesione individuales con especialistas o
            informándote a través de artículos que se publican en nuestra
            plataforma.
          </p>
          <p>
            <strong>¿Que ofrecemos en las sesiones individuales?</strong>
          </p>
          <ul>
            <li>
              <p>Reconocimiento de las dificultades.</p>
            </li>
            <li>
              <p>Diagnóstico del tipo de la autoestima.</p>
            </li>
            <li>
              <p>
                Practicas dirigidas a modificar los pensamientos y las
                conductas.
              </p>
            </li>
            <li>
              <p>Aprendizaje de nuevas habilidades y hábitos.</p>
            </li>
            <li>
              <p>Estabilización del ánimo.</p>
            </li>
          </ul>
          <p>
            <strong>
              Ahora, queremos compartir contigo una práctica que te ayudará a
              mejorar tu amor propio, sentirte más valorada(o).
            </strong>
          </p>
          <p>
            <strong>Practica</strong> <em>"Date amor a ti mismo(a)"</em>
          </p>
          <p>
            Toma una hoja de papel y usa un bolígrafo para dividirla en dos
            columnas.
          </p>
          <p>
            En la parte superior de la columna de la izquierda, ponga un signo
            +. Y en 5 minutos, escribe el número máximo de puntos de tus logros,
            cualidades positivas, todo aquello por lo que te pueden elogiar.
            Elógiate a ti mismo(a). No seas tímido(a).
          </p>

          {/* end */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { changeNavColors, changeNavLogo })(
  PrimerPost
);
