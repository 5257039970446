import {
  GET_SPECIALISTS,
  SPECIALISTS_ERROR,
  GET_SPECIALIST,
  SPECIALIST_ERROR,
} from '../actions/types';

const initialState = {
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SPECIALISTS:
      return { ...state, specialists: payload, loading: false };
    case GET_SPECIALIST:
      return { ...state, specialist: payload, loading: false };
    case SPECIALISTS_ERROR:
    case SPECIALIST_ERROR:
      return { ...state };
    default:
      return state;
  }
}
