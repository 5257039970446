import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import Flicking from '@egjs/react-flicking';
import { connect } from 'react-redux';
import { isAfter } from 'date-fns';
import { add } from 'date-fns';

const Home = ({ auth }) => {
  const [redirection, setRedirection] = useState(false);
  const user = auth.user;

  let orderedSes = false;
  let orderedApuntes = false;
  if (user) {
    orderedSes = Object.entries(user.sessions)
      .sort((a, b) => (a[1].horaPactada > b[1].horaPactada ? 1 : -1))
      .filter((a) =>
        // Will still appear after 30min
        isAfter(
          add(parseISO(a[1].horaPactada), {
            minutes: 30,
          }),
          new Date()
        )
      );

    orderedApuntes = Object.entries(user.apuntes).sort((a, b) =>
      a[1].ultimaEdicion > b[1].ultimaEdicion ? -1 : 1
    );
  }

  const selectSes = ({ index }) => {
    if (index === 1) {
      setRedirection(<Redirect push to='/terapeutas' />);
    } else {
      setRedirection(
        <Redirect push to={`/sesion/${orderedSes[(index - 3) / 2][1]._id}`} />
      );
    }
  };
  const selectApuntes = ({ index }) => {
    if (index === 1) {
      setRedirection(<Redirect push to='/apuntes/nuevo' />);
    } else {
      setRedirection(
        <Redirect push to={`/apuntes/${orderedApuntes[(index - 3) / 2][0]}`} />
      );
    }
  };

  if (redirection) {
    return redirection;
  }

  return user ? (
    <section
      className='container px-8 flex-grow flex flex-col mx-auto font-sans text-higia-gray-3'
      styles={{ height: 'max-content' }}
    >
      <div className='mt-6'>
        <div className='font-bold text-4xl text-higia-cyan'>
          Hola{' '}
          <span className='text-higia-gray-3'>
            {user.nombres.split(' ')[0]}
          </span>
        </div>
        <div className='md:inline-block mt-2 mb-3 py-2 px-5 border-2 border-solid border-gray-400 rounded-lg'>
          "{user.meta ? user.meta : 'No estás sol@. Estoy aquí para ti'}"
        </div>
      </div>

      <div className='flex flex-col mt-6 text-lg'>
        <div className='flex items-center mb-3'>
          <div className='font-bold mr-2'>Mis sesiones</div>
          <svg
            width='19'
            height='21'
            viewBox='0 0 19 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.375 9.4585H4.29167V11.5418H6.375V9.4585ZM10.5417 9.4585H8.45833V11.5418H10.5417V9.4585ZM14.7083 9.4585H12.625V11.5418H14.7083V9.4585ZM16.7917 2.16683H15.75V0.0834961H13.6667V2.16683H5.33333V0.0834961H3.25V2.16683H2.20833C1.05208 2.16683 0.135417 3.10433 0.135417 4.25016L0.125 18.8335C0.125 19.9793 1.05208 20.9168 2.20833 20.9168H16.7917C17.9375 20.9168 18.875 19.9793 18.875 18.8335V4.25016C18.875 3.10433 17.9375 2.16683 16.7917 2.16683ZM16.7917 18.8335H2.20833V7.37516H16.7917V18.8335Z'
              fill='#868686'
            />
          </svg>
        </div>

        <Flicking
          onSelect={(e) => {
            selectSes(e);
          }}
          tag='div'
          viewportTag='div'
          cameraTag='div'
          classPrefix='eg-flick-ses'
          deceleration={0.0075}
          horizontal={true}
          isConstantSize={true}
          defaultIndex={0}
          inputType={['touch', 'mouse']}
          bounce={10}
          autoResize={false}
          adaptive={false}
          zIndex={0}
          bound={true}
          overflow={false}
          hanger={'0%'}
          anchor={'0%'}
          gap={0}
          moveType={{ type: 'freeScroll', count: 1 }}
          collectStatistics={false}
        >
          <div className='w-4'></div>
          <div
            className={`w-32 h-32 cursor-pointer ${
              orderedSes.length > 0 ? 'bg-higia-azul' : 'bg-higia-cyan'
            } rounded-lg text-lg text-center break-words whitespace-normal text-white font-extrabold flex flex-col justify-center`}
          >
            <span className='leading-none text-3xl'>+</span>
            Agendar Nueva Sesión
          </div>
          <div className='w-4'></div>
          {orderedSes &&
            orderedSes.map(([index, sesion], mapIndex) => {
              const sesionDate = parseISO(sesion.horaPactada);

              return (
                <Fragment key={index}>
                  <div
                    className={`w-32 h-32 cursor-pointer ${
                      mapIndex === 0 ? 'bg-higia-cyan' : 'bg-higia-azul'
                    } capitalize rounded-lg text-lg text-center break-words whitespace-normal text-white flex flex-col justify-center`}
                  >
                    <b>
                      {format(sesionDate, 'EEEE', {
                        locale: es,
                      })}
                    </b>
                    {format(sesionDate, "d 'de' LLLL", {
                      locale: es,
                    })}{' '}
                    {format(sesionDate, 'HH:mm')}
                  </div>
                  <div className='w-4'></div>
                </Fragment>
              );
            })}
        </Flicking>
      </div>

      <div className='flex flex-col mt-8 text-lg'>
        <div className='flex items-center mb-3'>
          <div className='font-bold mr-2'>Mis apuntes</div>
          <svg
            width='25'
            height='25'
            viewBox='0 0 25 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.04158 17.1416L9.87596 16.6124L20.9208 5.43536C21.0079 5.34608 21.0564 5.22612 21.0558 5.10138C21.0552 4.97665 21.0056 4.85715 20.9176 4.76869L20.2562 4.09994C20.2134 4.05602 20.1624 4.02106 20.106 3.99708C20.0496 3.97311 19.9889 3.96062 19.9277 3.96033C19.8664 3.96004 19.8056 3.97196 19.749 3.99539C19.6924 4.01882 19.641 4.0533 19.5978 4.09682L8.58221 15.2447L8.04054 17.1406L8.04158 17.1416ZM21.5655 2.77494L22.227 3.44473C23.1395 4.36869 23.1478 5.85932 22.2437 6.7739L10.8624 18.2927L6.94159 19.4218C6.70221 19.4888 6.44602 19.4581 6.22925 19.3365C6.01248 19.2148 5.85283 19.0121 5.78534 18.7729C5.73511 18.6009 5.73439 18.4182 5.78325 18.2458L6.92388 14.2458L18.2749 2.75723C18.4908 2.5399 18.7477 2.36771 19.0308 2.25073C19.3139 2.13374 19.6174 2.07428 19.9237 2.07584C20.23 2.07739 20.533 2.13991 20.8148 2.25976C21.0967 2.37961 21.3519 2.55439 21.5655 2.7739V2.77494ZM9.56659 3.97598C10.0833 3.97598 10.502 4.39994 10.502 4.92286C10.5028 5.04647 10.4793 5.16903 10.4327 5.28354C10.3861 5.39804 10.3174 5.50224 10.2306 5.59018C10.1437 5.67812 10.0403 5.74808 9.92639 5.79604C9.81247 5.84401 9.6902 5.86905 9.56659 5.86973H5.82492C4.79159 5.86973 3.95409 6.71765 3.95409 7.76244V19.1229C3.95409 20.1687 4.79159 21.0166 5.82492 21.0166H17.0499C18.0833 21.0166 18.9218 20.1687 18.9218 19.1229V15.3364C18.9218 14.8135 19.3405 14.3895 19.8572 14.3895C20.3739 14.3895 20.7926 14.8135 20.7926 15.3374V19.1229C20.7926 21.2145 19.1166 22.9104 17.0499 22.9104H5.82492C3.75825 22.9104 2.08325 21.2145 2.08325 19.1229V7.76244C2.08325 5.67182 3.75825 3.97598 5.82492 3.97598H9.56659V3.97598Z'
              fill='#868686'
            />
          </svg>
        </div>

        <Flicking
          onSelect={(e) => {
            selectApuntes(e);
          }}
          tag='div'
          viewportTag='div'
          cameraTag='div'
          classPrefix='eg-flick-apuntes'
          deceleration={0.0075}
          horizontal={true}
          isConstantSize={true}
          defaultIndex={0}
          inputType={['touch', 'mouse']}
          bounce={10}
          autoResize={false}
          adaptive={false}
          zIndex={0}
          bound={true}
          overflow={false}
          hanger={'0%'}
          anchor={'0%'}
          gap={0}
          moveType={{ type: 'freeScroll', count: 1 }}
          collectStatistics={false}
        >
          <div className='w-4'></div>
          <div className='w-32 h-32 cursor-pointer bg-higia-crema rounded-lg text-lg text-center break-words whitespace-normal font-extrabold flex flex-col justify-center'>
            Crear nuevo apunte
          </div>
          <div className='w-4'></div>
          {orderedApuntes &&
            orderedApuntes.map(([uuid, apunte]) => (
              <Fragment key={uuid}>
                <div className='w-32 h-32 cursor-pointer bg-higia-crema rounded-lg text-lg text-center break-words whitespace-normal flex flex-col justify-center'>
                  {apunte.title}
                </div>
                <div className='w-4'></div>
              </Fragment>
            ))}
        </Flicking>
      </div>
      <div className='h-12'></div>
    </section>
  ) : null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
