import React from 'react';
import { Link } from 'react-router-dom';
import depresion from '../../img/depresion.svg';
import ansiedad from '../../img/ansiedad.svg';
import autoestima from '../../img/autoestima.svg';
import estres from '../../img/estres.svg';
import motivacion from '../../img/motivacion.svg';
import relaciones from '../../img/relaciones.svg';

const Descubrir = () => {
  return (
    <div className='container mx-auto'>
      <h2 className='text-higia-cyan font-extrabold text-3xl mx-8 mt-4 md:text-center'>
        Descubrir
      </h2>
      <div className='flex flex-col mx-8 mt-4 md:w-4/12 md:mx-auto'>
        <Link
          to='/descubrir/depresion'
          className='flex rounded-lg justify-between items-end bg-higia-crema shadow-lg p-4 pb-2 h-32'
        >
          <div className='text-xl font-semibold'>Depresión</div>
          <img src={depresion} alt='' />
        </Link>
        <Link
          to='/descubrir/ansiedad'
          className='flex rounded-lg justify-between items-end bg-higia-softlime shadow-lg p-4 pb-2 mt-6 h-32'
        >
          <div className='text-xl font-semibold'>Ansiedad</div>
          <img src={ansiedad} alt='' />
        </Link>
        <Link
          to='/descubrir/estres'
          className='flex rounded-lg justify-between items-end bg-higia-lime shadow-lg p-4 pb-2 mt-6 h-32'
        >
          <div className='text-xl font-semibold'>Estrés</div>
          <img src={estres} alt='' />
        </Link>
        <Link
          to='/descubrir/autoestima'
          className='flex rounded-lg justify-between items-end bg-higia-pink shadow-lg p-4 pb-2 mt-6 h-32'
        >
          <div className='text-xl font-semibold'>Autoestima</div>
          <img src={autoestima} alt='' />
        </Link>
        {/*
        <Link
          to='/descubrir/relaciones'
          className='flex rounded-lg justify-between items-end bg-higia-azul shadow-lg p-4 pb-2 mt-6 h-32'
        >
          <div className='text-xl font-semibold'>Relaciones Personales</div>
          <img src={relaciones} alt='' />
        </Link>
        <Link
          to='/descubrir/motivacion'
          className='flex rounded-lg justify-between items-end bg-higia-orange shadow-lg p-4 pb-2 mt-6 h-32 mb-6'
        >
          <div className='text-xl font-semibold'>
            Motivación y desarrollo personal
          </div>
          <img src={motivacion} alt='' />
        </Link> */}
      </div>
    </div>
  );
};

export default Descubrir;
