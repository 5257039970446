import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import TimePicker from "react-time-picker";
import { v4 as uuid } from "uuid";
import { parseISO, setDay, getDay, format } from "date-fns";
import { modifySpecialist } from "../../actions/auth";
import { connect } from "react-redux";
import "./Calendar.css";

const ProfileSpecialist = ({ auth, modifySpecialist }) => {
  const user = auth.user;
  const [data, setData] = useState(user);
  const [nTarifa, setNTarifa] = useState({ serv: "", val: "" });
  const { nombres, desc, tarifas, newHorarios } = data;

  useEffect(() => {
    let newHorarios = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {} };
    if (data.horarios && Object.keys(data.horarios).length > 0) {
      Object.keys(data.horarios).forEach((id) => {
        newHorarios = {
          ...newHorarios,
          [getDay(parseISO(data.horarios[id]))]: {
            ...newHorarios[getDay(parseISO(data.horarios[id]))],
            [id]: format(parseISO(data.horarios[id]), "HH:mm"),
          },
        };
      });
    }
    setData({ ...data, newHorarios });
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formatNewHorarios = {};
    Object.entries(newHorarios).forEach(([dayNumber, dayHorarios]) => {
      Object.entries(dayHorarios).forEach(([id, hora]) => {
        let [hh, mm] = hora.split(":");
        formatNewHorarios[id] = setDay(
          new Date(2020, 10, 10, hh, mm),
          dayNumber,
          hora
        );
      });
    });
    await modifySpecialist({
      nombres,
      desc,
      tarifas,
      horarios: formatNewHorarios,
    });
    window.location.reload();
  };

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const removeTarifa = (serv) => {
    const { [serv]: value, ...newTarifas } = data.tarifas;
    setData({ ...data, tarifas: { ...newTarifas } });
  };

  if (!auth.isSpecialist) {
    return <Redirect to="/profile" />;
  }

  return (
    <section
      className="container px-8 flex-grow flex flex-col mx-auto font-sans"
      styles={{ height: "max-content" }}
    >
      <h2 className="mt-6 text-3xl font-bold">Mi Perfil</h2>

      <form
        className="mb-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <label className="block font-bold text-lg">Nombres:</label>
          <input
            type="text"
            className="bg-gray-200 rounded-lg mt-1 px-2 w-full"
            placeholder="nombres"
            name="nombres"
            value={data.nombres}
            onChange={onChange}
          />
        </div>
        <div className="mt-4">
          <label className="block font-bold text-lg">Descripción:</label>
          <textarea
            type="text"
            rows={4}
            className="bg-gray-200 rounded-lg mt-1 px-2 w-full"
            placeholder="Descripción"
            name="desc"
            value={data.desc}
            onChange={onChange}
          />
        </div>
        <div className="mt-4">
          <div className="block font-bold text-lg">Tarifas:</div>
          <div className="flex flex-col">
            {data.tarifas && Object.keys(data.tarifas).length > 0
              ? Object.keys(data.tarifas).map((serv) => (
                  <div className="flex mt-1" key={serv}>
                    <div className="w-7/12 bg-gray-200 rounded-lg mt-1 mr-4 px-2 pt-1">
                      {`${serv}`}
                    </div>
                    <div className="w-3/12 bg-gray-200 rounded-lg mt-1 mr-4 px-2 pt-1">
                      S/ {`${data.tarifas[serv]}`}
                    </div>
                    <div className="w-2/12 flex justify-center">
                      <button
                        className="mt-1 mx-auto bg-higia-bermuda font-white rounded-lg px-2 text-white font-bold text-xl"
                        onClick={() => removeTarifa(serv)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                ))
              : null}
            <div className="flex mt-1">
              <input
                type="text"
                className="w-7/12 bg-gray-200 rounded-lg mt-1 mr-4 px-2 pt-1 inline"
                style={{ paddingTop: ".2rem" }}
                placeholder="Agregar"
                value={nTarifa.serv}
                onChange={(e) =>
                  setNTarifa({ ...nTarifa, serv: e.target.value })
                }
              />
              <div className="w-3/12 bg-gray-200 rounded-lg mt-1 mr-4 px-2 pt-1 inline">
                <div className="inline w-3/12">S/ </div>
                <input
                  type="text"
                  className="w-8/12 inline bg-gray-200"
                  placeholder="....."
                  value={nTarifa.val}
                  onChange={(e) =>
                    setNTarifa({ ...nTarifa, val: e.target.value })
                  }
                />
              </div>
              <div className="w-2/12 flex justify-center">
                <button
                  className="mt-1 mx-auto px-2 text-white font-bold text-xl outline-none"
                  style={{ lineHeight: 0, fontSize: "2.5rem", outline: "none" }}
                  onClick={() => {
                    if (nTarifa.serv && nTarifa.val) {
                      setData({
                        ...data,
                        tarifas: {
                          ...data.tarifas,
                          [nTarifa.serv]: nTarifa.val,
                        },
                      });
                      setNTarifa({ serv: "", val: "" });
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="block font-bold text-lg">Horarios:</div>
          <DayHorario data={data} setData={setData} day="Lunes" number={1} />
          <DayHorario data={data} setData={setData} day="Martes" number={2} />
          <DayHorario
            data={data}
            setData={setData}
            day="Miércoles"
            number={3}
          />
          <DayHorario data={data} setData={setData} day="Jueves" number={4} />
          <DayHorario data={data} setData={setData} day="Viernes" number={5} />
          <DayHorario data={data} setData={setData} day="Sábado" number={6} />
          <DayHorario data={data} setData={setData} day="Domingo" number={0} />
        </div>
        {/* <div className='text-center mt-8 bg-higia-bermuda text-white text-xl w-full h-16 rounded-lg flex items-center justify-center'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://auth.mercadopago.com.pe/authorization?client_id=4074260307573277&response_type=code&platform_id=mp&state=id=RANDOM_ID=&redirect_uri=http://localhost:5000/api/auth/specialistsLink'
            className=''
          >
            Vincular a Mercadopago
          </a>
        </div> */}
        <input
          type="submit"
          onClick={onSubmit}
          className="cursor-pointer mt-8 bg-higia-bermuda text-white text-xl w-full h-16 rounded-lg"
          value="Guardar Cambios"
        />
      </form>
    </section>
  );
};

const DayHorario = ({ data, setData, day, number }) => {
  const [timePick, setTimePick] = useState("");
  const removeDate = (number, horario) => {
    const { [horario]: value, ...modHorarios } = data.newHorarios[number];
    setData({
      ...data,
      newHorarios: { ...data.newHorarios, [number]: modHorarios },
    });
  };

  return (
    <>
      <div className="block font-semibold text-lg">{`${day}:`}</div>
      <div className="flex flex-col align-center mb-3">
        {data.newHorarios && data.newHorarios[number]
          ? Object.entries(data.newHorarios[number])
              .sort((a, b) => (a[1] < b[1] ? -1 : 1))
              .map(([horarioId, horarioVal]) => (
                <div className="flex mt-1" key={horarioId}>
                  <div
                    className="w-1/12  inline-flex"
                    style={{ alignItems: "center" }}
                  >
                    <div className="w-4 h-4 bg-gray-400 rounded-full inline"></div>
                  </div>
                  <div className="w-9/12">
                    <TimePicker
                      disableClock
                      value={horarioVal}
                      clearIcon={null}
                      disabled
                    />
                  </div>
                  <div className="w-2/12 flex justify-center">
                    <button
                      className="mt-1 mx-auto bg-higia-bermuda font-white rounded-lg px-2 text-white font-bold text-xl"
                      onClick={() => removeDate(number, horarioId)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))
          : null}
        <div className="flex mt-1">
          <div className="w-1/12  inline-flex" style={{ alignItems: "center" }}>
            <div className="w-4 h-4 bg-gray-400 rounded-full inline"></div>
          </div>
          <div className="w-9/12">
            <TimePicker
              value={timePick}
              onChange={(e) => setTimePick(e)}
              disableClock
            />
          </div>
          <div className="w-2/12 flex justify-center">
            <button
              className="mt-1 mx-auto bg-higia-bermuda rounded-lg font-white px-1 text-white font-bold text-xl"
              style={{ lineHeight: 0, fontSize: "2.5rem", outline: "none" }}
              onClick={() => {
                if (timePick) {
                  setData({
                    ...data,
                    newHorarios: {
                      ...data.newHorarios,
                      [number]: {
                        ...data.newHorarios[number],
                        [uuid()]: timePick,
                      },
                    },
                  });
                }
                setTimePick("");
              }}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { modifySpecialist })(
  ProfileSpecialist
);
