import {
  GET_ENCUESTA,
  ENCUESTA_ERROR,
  POST_ENCUESTA,
  GET_INITIAL_ENCUESTA,
} from "./types";
import api from "../utils/api";

// Get encuesta by nombre
export const getEncuestaByNombre = (nombre) => async (dispatch) => {
  try {
    const res = await api.get(`/surveys/getSurvey/${nombre}`);
    dispatch({
      type: GET_ENCUESTA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ENCUESTA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get encuesta results
export const getEncuestaResults = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/surveys/initialSurvey`, data);
    dispatch({
      type: GET_INITIAL_ENCUESTA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ENCUESTA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Post encuesta
export const postEncuesta = (surveyData) => async (dispatch) => {
  try {
    const res = await api.post(
      `/surveys/completed/${surveyData.id}`,
      surveyData
    );
    dispatch({
      type: POST_ENCUESTA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ENCUESTA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
