import React from "react";
import { format } from "date-fns";

const chatMsg = ({ alignRight, fstMsg, text, date }) => {
  return (
    <div
      className={`flex flex-col flex-auto flex-shrink-0 ${
        alignRight ? "items-end" : "items-start"
      } ${fstMsg ? " mt-3" : ""}`}
    >
      <div
        className="flex-none px-3 py-2 rounded-lg border border-solid border-gray-400"
        style={{ maxWidth: "60%", width: "fit-content" }}
      >
        <span className="break-words text-gray-700">{text}</span>
        <div className="text-gray-600 float-right mt-2 -mb-1 ml-3">
          <span className="text-xs">
            {date ? date : format(new Date(), "p")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default chatMsg;
