import React from 'react';
import styles from './Progress.module.css';

const Progress = ({ selected }) => {
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div
          className={styles.percent}
          style={{ width: `${33.33 * selected}%` }}
        ></div>
      </div>
      <div className={styles.steps}>
        <div
          className={`${styles.step} ${
            selected === 0 ? styles.selected : styles.completed
          }`}
        ></div>
        <div
          className={`${styles.step} ${selected === 1 ? styles.selected : ''} ${
            selected > 1 ? styles.completed : ''
          }`}
        ></div>
        <div
          className={`${styles.step} ${selected === 2 ? styles.selected : ''} ${
            selected > 2 ? styles.completed : ''
          }`}
        ></div>
        <div
          className={`${styles.step} ${selected === 3 ? styles.selected : ''} ${
            selected > 3 ? styles.completed : ''
          }`}
        ></div>
      </div>
    </div>
  );
};

export default Progress;
