import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { registerSession } from "../../actions/checkout";
import Spinner from "../layout/Spinner";
import Progress from "./Progress";
import "./Calendar.css";

const Pago = ({ user, checkout, registerSession, specialists }) => {
  const info = specialists.specialist;
  console.log(user?.b2b?.isActive);

  useEffect(() => {
    async function registerB2B() {
      if (user?.b2b?.isActive) {
        console.log("arst");
        await registerSession({ ...checkout, estado: "pagado" });
      }
    }
    registerB2B();
  }, [user]);

  if (Object.keys(checkout).length === 0) {
    return <Redirect to="/terapeutas" />;
  }

  if (checkout.success) {
    return <Redirect to="/confirmed" />;
  }

  const complete = async () => {
    await registerSession(checkout);
  };

  return specialists.loading || !info ? (
    <Spinner />
  ) : (
    <section
      className="container px-8 pt-8 flex-grow flex flex-col mx-auto font-sans"
      styles={{ height: "max-content" }}
    >
      <span className="font-bold mb-2">4. Forma de pago</span>
      <Progress selected={3} />

      {info.email === "giovanyriveraramirez@yahoo.com" ? (
        <PreciosGiovany complete={complete} />
      ) : (
        <Precios complete={complete} />
      )}

      <div className="font-bold text mt-8 mb-6">
        También aceptamos Yape/Plin y transferencia
      </div>
      <div className="font-bold ml-4">
        <div>
          Yape / Plin: <span className="font-semibold">955 301 148</span>
        </div>
        <div>
          Transferencia:
          <div className="ml-2">
            BCP: <span className="font-semibold">194 - 01534786 - 0 - 87</span>
          </div>
          <div className="ml-2">
            Interbank: <span className="font-semibold">254 - 3317360445</span>
          </div>
        </div>
      </div>

      <div className="my-3">
        Si pagas por estos medios, por favor mándanos un screenshot a nuestro
        &nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=51922277597&text=Hola, ya realicé el pago de mi paquete."
        >
          Whatsapp
        </a>
        .
      </div>
      <div className="flex justify-between mt-4 mb-10">
        <Link
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to="/casiacabamos"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current"
            width="12"
            height="18"
            style={{ transform: "scale(-1, 1)" }}
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
        <button
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          onClick={() => complete()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
  user: state.auth.user,
  checkout: state.checkout,
});

export default connect(mapStateToProps, { registerSession })(Pago);

const Precios = ({ complete }) => {
  const paquetes = [
    {
      paquete: 1,
      numConsultas: 1,
      precioTotal: 95,
      precioIndividual: 95,
      link: "https://mpago.la/26k1Rjo",
    },
    {
      paquete: 2,
      numConsultas: 3,
      dsct: "-6 %",
      precioTotal: "267.90",
      precioUsual: "285",
      precioIndividual: 95,
      ahorro: 0,
      link: "https://mpago.la/1aUA1ze",
    },
    {
      paquete: 3,
      numConsultas: 6,
      dsct: "-12 %",
      precioTotal: "501.60",
      precioUsual: "570",
      precioIndividual: 95,
      ahorro: 0,
      link: "https://mpago.la/238Xdxe",
    },
  ];
  return (
    <div className="flex flex-col mt-4 sm:mt-6 md:flex-row">
      {paquetes.map((paq, i) => (
        <div
          key={i}
          className="px-4 py-4 mx-4 my-4 max-w-sm bg-white rounded-lg border shadow-md sm:px-8 sm:pb-8"
        >
          {paq.dsct ? (
            <div className="flex flex-row-reverse">
              <div className="-mx-10 text-lg text-right px-3 py-1 bg-higia-bermuda rounded-lg text-white">
                {paq.dsct}
              </div>
            </div>
          ) : null}
          <h5
            className={`mb-4 text-xl font-medium text-gray-600 dark:text-gray-400 text-center ${
              paq.paquete === 1 ? "mt-4" : "-mt-6"
            }`}
          >
            Paquete {paq.paquete}
          </h5>
          <div
            className={`mx-auto items-baseline text-gray-700 dark:text-white text-center ${
              paq.paquete === 1 ? "mb-8" : ""
            }`}
          >
            <span className="text-4xl font-semibold">S/ {paq.precioTotal}</span>
            {paq.precioUsual ? (
              <>
                <div className="-my-2 text-gray-500 line-through font-semibold">
                  S/ {paq.precioUsual}
                </div>
              </>
            ) : null}
          </div>
          <ul className="my-6 mx-10 space-y-4">
            {[
              `${paq.numConsultas} consulta${
                paq.numConsultas > 0 ? "s" : ""
              } online`,
              "Psicoterapeuta certificado",
              "Posibilidad de cambiar de terapeuta",
              `Posibilidad de reagendar`,
              "Bienestar emocional",
            ].map((val, i) => (
              <li className="flex space-x-3" key={i}>
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-5 h-5 text-higia-bermuda"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-normal leading-tight text-gray-700 dark:text-gray-400">
                  {val}
                </span>
              </li>
            ))}
          </ul>
          <div className="flex">
            <a
              href={paq.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-higia-bermuda mx-auto py-1 px-3 rounded-lg text-white font-semibold"
              onClick={() => complete()}
            >
              Pagar con tarjeta
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

const PreciosGiovany = ({ complete }) => {
  return (
    <div className="flex flex-col mt-4 sm:mt-6 md:flex-row">
      <div className="px-4 py-4 mx-4 my-4 max-w-sm bg-white rounded-lg border shadow-md sm:px-8 sm:pb-8">
        <div
          className={`mx-auto items-baseline text-gray-700 dark:text-white text-center`}
        >
          <span className="text-4xl font-semibold">S/ 200</span>
        </div>
        <ul className="my-6 mx-10 space-y-4">
          {[
            `1 consulta online`,
            "Psicoterapeuta certificado",
            "Posibilidad de cambiar de terapeuta",
            `Posibilidad de reagendar`,
            "Bienestar emocional",
          ].map((val, i) => (
            <li className="flex space-x-3" key={i}>
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-5 h-5 text-higia-bermuda"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="text-base font-normal leading-tight text-gray-700 dark:text-gray-400">
                {val}
              </span>
            </li>
          ))}
        </ul>
        <div className="flex">
          <a
            href="https://mpago.la/1Z18uG9"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-higia-bermuda mx-auto py-1 px-3 rounded-lg text-white font-semibold"
            onClick={() => complete()}
          >
            Pagar con tarjeta
          </a>
        </div>
      </div>
    </div>
  );
};
