import React from 'react';
import Footer from '../layout/Footer';
import beneficios from '../../img/Beneficios.jpg';

const Beneficios = () => {
  return (
    <>
      <header
        className={`bg-no-repeat bg-cover relative header-beneficios flex flex-col-reverse xl:container xl:mx-auto`}
        style={{
          backgroundImage: `url(${beneficios})`,
        }}
      >
        <div className='text-white relative overflow-hidden'>
          <svg
            viewBox='0 0 640 62'
            xmlns='http://www.w3.org/2000/svg'
            className='fill-current relative'
            style={{ bottom: '-2px' }}
          >
            <path fill='none' d='M-1-1h802v602H-1z' />
            <path d='M640.043 20.486l-35.56-3.45c-35.55-3.33-106.66-10.43-177.77-5.17-71.11 5.07-142.23 22.5-213.34 22.41-71.11.09-142.22-17.34-177.77-25.83-2.37-.57-14.23-3.45-35.56-8.62v61.98h640v-41.32z' />
          </svg>
        </div>
      </header>
      <div className='flex flex-col items-center text-center mx-auto'>
        <div className='mt-2 text-higia-cyan font-bold text-5xl w-8/12 md:w-full'>
          Beneficios
        </div>
        <div style={{ width: '18rem' }}>
          <div className='flex flex-col items-center mt-10 text-lg'>
            <div className='text-higia-cyan'>
              <svg
                width='50'
                height='50'
                viewBox='0 0 50 50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.08337 43.7502H10.4167V18.7502H2.08337V43.7502ZM47.9167 20.8335C47.9167 18.5418 46.0417 16.6668 43.75 16.6668H30.6042L32.5834 7.146L32.6459 6.47933C32.6459 5.62516 32.2917 4.8335 31.7292 4.271L29.5209 2.0835L15.8125 15.8127C15.0417 16.5627 14.5834 17.6043 14.5834 18.7502V39.5835C14.5834 41.8752 16.4584 43.7502 18.75 43.7502H37.5C39.2292 43.7502 40.7084 42.7085 41.3334 41.2085L47.625 26.521C47.8125 26.0418 47.9167 25.5418 47.9167 25.0002V20.8335Z'
                  fill='#55D1F8'
                />
              </svg>
            </div>
            <div className='font-bold mt-3'>El mejor psicólogo para ti</div>
            <div className='font-semibold'>
              Quien te ayuda, comprende y con el que conectas mejor
            </div>
          </div>

          <div className='flex flex-col items-center mt-10 text-lg'>
            <div className='text-higia-cyan'>
              <svg
                width='54'
                height='50'
                viewBox='0 0 54 50'
                className='fill-current'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M3.375 12.5V43.75C3.375 44.5788 3.73058 45.3737 4.36351 45.9597C4.99645 46.5458 5.85489 46.875 6.75 46.875H47.25C48.1451 46.875 49.0035 46.5458 49.6365 45.9597C50.2694 45.3737 50.625 44.5788 50.625 43.75V12.5H3.375ZM6.75 3.125C4.95979 3.125 3.2429 3.78348 1.97703 4.95558C0.711159 6.12769 0 7.7174 0 9.375L0 43.75C0 45.4076 0.711159 46.9973 1.97703 48.1694C3.2429 49.3415 4.95979 50 6.75 50H47.25C49.0402 50 50.7571 49.3415 52.023 48.1694C53.2888 46.9973 54 45.4076 54 43.75V9.375C54 7.7174 53.2888 6.12769 52.023 4.95558C50.7571 3.78348 49.0402 3.125 47.25 3.125H6.75Z'
                  fill='#55D1F'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.8125 0C12.2601 0 12.6893 0.16462 13.0057 0.457646C13.3222 0.750671 13.5 1.1481 13.5 1.5625V3.125C13.5 3.5394 13.3222 3.93683 13.0057 4.22985C12.6893 4.52288 12.2601 4.6875 11.8125 4.6875C11.3649 4.6875 10.9357 4.52288 10.6193 4.22985C10.3028 3.93683 10.125 3.5394 10.125 3.125V1.5625C10.125 1.1481 10.3028 0.750671 10.6193 0.457646C10.9357 0.16462 11.3649 0 11.8125 0V0ZM42.1875 0C42.6351 0 43.0643 0.16462 43.3807 0.457646C43.6972 0.750671 43.875 1.1481 43.875 1.5625V3.125C43.875 3.5394 43.6972 3.93683 43.3807 4.22985C43.0643 4.52288 42.6351 4.6875 42.1875 4.6875C41.7399 4.6875 41.3107 4.52288 40.9943 4.22985C40.6778 3.93683 40.5 3.5394 40.5 3.125V1.5625C40.5 1.1481 40.6778 0.750671 40.9943 0.457646C41.3107 0.16462 41.7399 0 42.1875 0V0Z'
                  fill='#55D1F'
                />
              </svg>
            </div>
            <div className='font-bold mt-3'>Horarios flexibles</div>
            <div className='font-semibold'>
              Horarios flexibles que están alineados a tus necesidades y estilo
              de vida
            </div>
          </div>

          <div className='flex flex-col items-center mt-12 text-lg'>
            <div className='text-higia-cyan'>
              <svg
                width='50'
                height='50'
                viewBox='0 0 50 50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M36.475 13.95L20 30.425L11.025 21.475L7.5 25L20 37.5L40 17.5L36.475 13.95ZM25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM25 45C13.95 45 5 36.05 5 25C5 13.95 13.95 5 25 5C36.05 5 45 13.95 45 25C45 36.05 36.05 45 25 45Z'
                  fill='#55D1F8'
                />
              </svg>
            </div>
            <div className='font-bold mt-3'>Espacio de confianza</div>
            <div className='font-semibold'>Privado y exclusivo para ti</div>
          </div>

          <div className='flex flex-col items-center mt-12 text-lg'>
            <div className='text-higia-cyan'>
              <svg
                width='47'
                height='50'
                viewBox='0 0 47 50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M42.5882 15.2273L40.2353 17.5C44.4706 21.5909 44.4706 27.9545 40.2353 32.2727L42.5882 34.5455C48.4706 29.3182 48.4706 20.6818 42.5882 15.2273V15.2273ZM37.6471 20L35.2941 22.2727C36.4706 23.8636 36.4706 25.9091 35.2941 27.5L37.6471 29.7727C40.4706 27.0455 40.4706 22.9545 37.6471 20ZM28.2353 0H4.70588C2.11765 0 0 2.04545 0 4.54545V45.4545C0 47.9545 2.11765 50 4.70588 50H28.2353C30.8235 50 32.9412 47.9545 32.9412 45.4545V4.54545C32.9412 2.04545 30.8235 0 28.2353 0ZM28.2353 43.1818H4.70588V6.81818H28.2353V43.1818Z'
                  fill='#55D1F8'
                />
              </svg>
            </div>
            <div className='font-bold mt-3'>Menos inconvenientes</div>
            <div className='font-semibold'>
              Conéctate con el profesional a un click de distancia
            </div>
          </div>

          <div className='flex flex-col items-center mt-12 text-lg'>
            <div className='text-higia-cyan'>
              <svg
                width='72'
                height='50'
                viewBox='0 0 72 50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M52.2 25C57.168 25 61.164 21 61.164 16.0714C61.164 11.1429 57.168 7.14286 52.2 7.14286C47.232 7.14286 43.2 11.1429 43.2 16.0714C43.2 21 47.232 25 52.2 25ZM25.2 21.4286C31.176 21.4286 35.964 16.6429 35.964 10.7143C35.964 4.78571 31.176 0 25.2 0C19.224 0 14.4 4.78571 14.4 10.7143C14.4 16.6429 19.224 21.4286 25.2 21.4286ZM52.2 32.1429C45.612 32.1429 32.4 35.4286 32.4 41.9643V50H72V41.9643C72 35.4286 58.788 32.1429 52.2 32.1429ZM25.2 28.5714C16.812 28.5714 0 32.75 0 41.0714V50H25.2V41.9643C25.2 38.9286 26.388 33.6071 33.732 29.5714C30.6 28.9286 27.576 28.5714 25.2 28.5714Z'
                  fill='#55D1F8'
                />
              </svg>
            </div>
            <div className='font-bold mt-3'>Sé parte de la comunidad Higia</div>
            <div className='font-semibold'>
              y recibe tips, consejos y contenido exclusivo
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Beneficios;
