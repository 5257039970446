import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSpecialists } from "../../actions/especialistas";
import { getEncuestaResults } from "../../actions/surveys";
import Spinner from "../layout/Spinner";

const Terapeutas = ({
  auth,
  surveys,
  getSpecialists,
  specialists,
  getEncuestaResults,
}) => {
  const score = surveys?.initialSurvey?.respuestas.reduce((acc, res) => {
    acc[res] === undefined ? (acc[res] = 1) : (acc[res] += 1);
    return acc;
  }, {});

  const group = score
    ? Object.keys(score).reduce((acc, key) => (score[key] > acc ? key : acc), 0)
    : null;

  const filterRecommended = (id) =>
    auth.user?.sessions?.length > 0
      ? id === auth.user.sessions[auth.user.sessions.length - 1].especialista
      : group && score[group] >= 3
      ? group === "resp1"
        ? id === "6116c21d6425154ad40cd694" ||
          id === "620abc296425154ad40db810" ||
          id === "61528af86425154ad40d2665" ||
          id === "60b62d8c039d42143b9e5d08"
        : group === "resp2"
        ? id === "5f6dfd7083615f65740919c7" ||
          id === "607f025cfe2134662f15c238" ||
          id === "60e7970de989315812a40908" ||
          id === "5f3d51b29871617c2d4bc740"
        : id === "5f3b14b00011c31eaaa96872" ||
          id === "6090d2bffe2134662f15c32d" ||
          id === "611440f36425154ad40cd354"
      : id === "5f3b14b00011c31eaaa96872" ||
        id === "607f025cfe2134662f15c238" ||
        id === "5f6dfd7083615f65740919c7" ||
        id === "611440f36425154ad40cd354";
  useEffect(() => {
    getSpecialists();
    getEncuestaResults({ id: auth.user?._id });
  }, [getSpecialists, getEncuestaResults, auth.user]);

  return (
    <section
      className="flex-grow flex flex-col mx-auto font-sans mb-6 w-full"
      styles={{ height: "max-content" }}
    >
      <div className="w-full relative">
        <div className="mx-auto text-center text-white bg-higia-azul">
          <div className="container mx-auto px-8">
            <h2 className="pt-4 md:pt-12 text-3xl font-extrabold mx-3">
              ¡Ellos están aquí para ti!
            </h2>
            <p className="text-2xl font-bold mx-3">
              Dispuestos a escucharte y ayudarte
            </p>
          </div>
        </div>
        <div
          className="text-higia-azul overflow-hidden relative"
          style={{ top: "-2px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="fill-current shadow-lg w-full wavesShadow"
            preserveAspectRatio="none"
          >
            <path d="M0,128L80,138.7C160,149,320,171,480,154.7C640,139,800,85,960,85.3C1120,85,1280,139,1360,165.3L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
        </div>
      </div>

      <div className="container mx-auto px-8 ">
        {specialists.loading || !specialists.specialists ? (
          <Spinner />
        ) : (
          <>
            <div className="bg-higia-bermuda -mx-10 px-8 pt-6 pb-5 lg:-mx-3 lg:pt-5 lg:px-8 rounded-lg shadow-lg">
              <div className="text-white text-2xl font-bold underline">
                Recomendados para ti
              </div>
              {specialists.specialists
                .filter((specialist) => filterRecommended(specialist._id))
                .map((specialist, id) =>
                  specialist.isInactive ? null : (
                    <TerapeutaCard info={specialist} key={id} />
                  )
                )}
            </div>
            {specialists.specialists
              .filter((specialist) => !filterRecommended(specialist._id))
              .map((specialist, id) =>
                specialist.isInactive ? null : (
                  <TerapeutaCard info={specialist} key={id} />
                )
              )}
          </>
        )}
      </div>
    </section>
  );
};

const TerapeutaCard = ({ info }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mt-4 mb-0 md:mb-4">
      <div className="flex items-center">
        <img
          className="h-20 w-20 object-cover rounded-full"
          src={info.avatar}
          alt="Foto de Perfil"
        />
        <div className="ml-4 tracking-wide w-full">
          <div className="flex items-baseline justify-between">
            <h4 className="text-xl font-bold ">{info.nombres}</h4>
            <div className="flex flex-col items-center text-higia-cyan">
              <svg
                className="h5 w-5 fill-current wavesShadow"
                viewBox="0 0 32 29.6"
              >
                <path
                  d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                />
              </svg>
              <div className="text-gray-800">{info.numSes}</div>
            </div>
          </div>
          {info.longDesc ? (
            <p className="text-sm whitespace-pre-wrap">{info.longDesc}</p>
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="text-sm">
          {info.tarifas
            ? Object.keys(info.tarifas)
                .sort()
                .map((serv) => (
                  <div key={serv}>
                    {serv + ": "}
                    {info.tarifas[serv].split(" ").length > 1 ? (
                      <>
                        <span className="line-through">
                          {"S/" + info.tarifas[serv].split(" ")[0]}
                        </span>
                        <span className="text-red-500 font-bold">
                          {" S/" + info.tarifas[serv].split(" ")[1]}
                        </span>
                      </>
                    ) : (
                      <span>{"  S/" + info.tarifas[serv]}</span>
                    )}
                  </div>
                ))
            : null}
        </div>
        <Link
          to={`/terapeutas/${info.nombres.replace(" ", "-")}`}
          className="bg-higia-bermuda py-1 px-3 rounded-lg text-white font-semibold"
        >
          Agenda aquí
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
  surveys: state.surveys,
  auth: state.auth,
});

export default connect(mapStateToProps, { getSpecialists, getEncuestaResults })(
  Terapeutas
);
