import React from "react";
import insta from "../../img/insta.svg";
import facebook from "../../img/facebook.svg";
import linkedin from "../../img/linkedin.svg";

const Footer = () => {
  return (
    <footer className="mt-12 mb-10">
      <div className="text-gray-700 text-center mb-4">{`Hecho con amor por Higia <3`}</div>
      <div className="flex mx-auto justify-center">
        <a href="https://www.instagram.com/higia_psicologia/">
          <img
            className="h-8 w-8 object-cover mr-8"
            src={insta}
            alt="Instagram"
          />
        </a>
        <a href="https://www.facebook.com/higiapsicologiaperu/">
          <img
            className="h-8 w-8 object-cover mr-8"
            src={facebook}
            alt="Facebook"
          />
        </a>
        <a href="https://www.linkedin.com/company/higia-per%C3%BA/">
          <img className="h-8 w-8 object-cover" src={linkedin} alt="Linkedin" />
        </a>
      </div>
      <div className="pt-4 flex mx-auto justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://higgiaassets.s3.amazonaws.com/T%26C+Higia.pdf"
        >
          Términos y condiciones{" "}
        </a>
      </div>
      <div className="pt-2 flex mx-auto justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1H-f1UsUpV6uYtaZkcsvS83GBLcbFBkCw/view"
        >
          Política y Lineamientos
        </a>
      </div>
      <div className="pt-2 flex mx-auto justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/forms/d/1ekNvmHPEi8zXaLSR0n7F1WqBPL3F6225A83XDU5CBBw"
        >
          Libro de reclamaciones{" "}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
