import React from 'react';
import { connect } from 'react-redux';
import HomeSpecialist from './HomeSpecialist';
import HomeUser from './HomeUser';

const Home = ({ auth }) => {
  if (auth.isSpecialist) {
    return <HomeSpecialist />;
  } else {
    return <HomeUser />;
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
