import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../img/Higia.png";
import { connect } from "react-redux";
import Backdrop from "../backdrop/Backdrop";
import styles from "./Navbar.module.css";
import { logout } from "../../actions/auth";

const Navbar = ({
  isAuthenticated,
  isSpecialist,
  navBg,
  showLogo,
  previousLink,
  logout,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const Links = (isHidden) => (
    <>
      <Link
        to="/terapeutas"
        className={`block ${
          isHidden ? "hidden" : ""
        } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 hover:underline`}
        onClick={() => setIsOpen(false)}
      >
        Terapeutas
      </Link>
      <Link
        to="/beneficios"
        className={`block ${
          isHidden ? "hidden" : ""
        } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline`}
        onClick={() => setIsOpen(false)}
      >
        Beneficios
      </Link>
      <Link
        to="/preguntas"
        className={`block ${
          isHidden ? "hidden" : ""
        } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline`}
        onClick={() => setIsOpen(false)}
      >
        Preguntas
      </Link>
      <Link
        to="/descubrir"
        className={`block ${
          isHidden ? "hidden" : ""
        } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline`}
        onClick={() => setIsOpen(false)}
      >
        Descubrir
      </Link>
      {isAuthenticated ? null : (
        <>
          <Link
            to="/register"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl lg:text-white text-2xl bg-higia-cyan px-2 mx-1 my-1 rounded-lg hover:underline`}
            onClick={() => setIsOpen(false)}
          >
            Regístrate
          </Link>
          <Link
            to="/login"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl text-higia-cyan text-2xl bg-white px-2 border-higia-cyan border-2 mx-1 mt-3 lg:my-1 rounded-lg hover:underline`}
            onClick={() => setIsOpen(false)}
          >
            Ingresa
          </Link>
        </>
      )}
      {isAuthenticated && !isSpecialist ? (
        <>
          <Link
            to="/profile"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline`}
            onClick={() => setIsOpen(false)}
          >
            Mi Perfil
          </Link>
          <span
            to="/"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline cursor-pointer`}
            onClick={() => {
              logout();
              setIsOpen(false);
            }}
          >
            Cerrar Sesión
          </span>
        </>
      ) : null}
      {isAuthenticated && isSpecialist ? (
        <>
          <Link
            to="/profileSpecialist"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline`}
            onClick={() => setIsOpen(false)}
          >
            Mi Perfil
          </Link>
          <span
            to="/"
            className={`block ${
              isHidden ? "hidden" : ""
            } lg:inline font-extrabold lg:text-xl lg:text-higia-bermuda text-2xl px-3 py-1 rounded hover:underline cursor-pointer`}
            onClick={() => {
              logout();
              setIsOpen(false);
            }}
          >
            Cerrar Sesión
          </span>
        </>
      ) : null}
    </>
  );

  return (
    <div className={navBg ?? ""}>
      <header className="lg:flex lg:justify-between lg:items-center lg:px-4 lg:py-2 sm:container sm:mx-auto">
        <div className="flex items-center justify-between px-4 py-3">
          <Link
            to={previousLink ? previousLink : isAuthenticated ? "/home" : "/"}
          >
            {showLogo ? (
              <img className="h-10" src={Logo} alt="Higia" />
            ) : (
              backSvg
            )}
          </Link>
          <div className="lg:hidden">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="block text-higia-cyan hover:text-gray-900 focus:text-gray-900 focus:outline-none relative z-50"
            >
              <span
                className={`${styles.burger} ${styles.fstBar} ${
                  isOpen ? styles.fstOpen : "bg-higia-cyan"
                }`}
              />
              <span
                className={`${styles.burger} ${styles.sndBar} ${
                  isOpen ? styles.sndOpen : "bg-higia-cyan"
                }`}
              />
              <span
                className={`${styles.burger} ${styles.trdBar} ${
                  isOpen ? styles.trdOpen : "bg-higia-cyan"
                }`}
              />
            </button>
          </div>
        </div>
        <nav>{Links(true)}</nav>
        <nav
          className={`h-full fixed t-0 l-0 rounded-lg bg-higia-bermuda text-lg text-right px-2 pt-2 pb-4 pl-16 md:pr-8 md:pl-32 text-white lg:flex lg:p-0 z-40 ${
            styles.sideDrawer
          } ${isOpen ? " block" : " hidden"} ${
            isOpen ? styles.sideDrawerOpen : ""
          }`}
        >
          <div className="my-3 h-10"></div>
          {Links(false)}
        </nav>
        {isOpen ? <Backdrop onClick={() => setIsOpen(false)} /> : null}
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isSpecialist: state.auth.isSpecialist,
  navBg: state.nav.bg,
  showLogo: state.nav.showLogo,
  previousLink: state.nav.previous,
});

export default connect(mapStateToProps, { logout })(Navbar);

const backSvg = (
  <svg
    width="20"
    height="30"
    className="ml-5 h-10"
    viewBox="0 0 20 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9101 4.7422L7.82784 15.162L19.4989 24.9177L17.3413 29.0192L1.00186 15.3611L16.517 0.773491L18.9101 4.7422Z"
      fill="#55D1F8"
    />
  </svg>
);
