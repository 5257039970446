import {
  GET_SPECIALISTS,
  GET_SPECIALIST,
  SPECIALISTS_ERROR,
  SPECIALIST_ERROR,
} from './types';
import api from '../utils/api';

// Get all profiles
export const getSpecialists = () => async (dispatch) => {
  try {
    const res = await api.get('/specialists');

    dispatch({
      type: GET_SPECIALISTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SPECIALISTS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get specialist by ID
export const getSpecialistByID = (id) => async (dispatch) => {
  try {
    const res = await api.get(`./specialists/${id}`);
    dispatch({
      type: GET_SPECIALIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SPECIALIST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get specialist by Nombres
export const getSpecialistByNombres = (nombres) => async (dispatch) => {
  try {
    const res = await api.get(`./specialists/getByNombres/${nombres}`);
    dispatch({
      type: GET_SPECIALIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SPECIALIST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
