import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "typeface-nunito";
import Navbar from "./components/layout/Navbar";
import Routes from "./components/routes/Routes";
import Whatsapp from "./components/layout/Whatsapp";

import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import { loadSpecialist } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import "./App.css";

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-T3K2V7J",
};
TagManager.initialize(tagManagerArgs);

const App = () => {
  useEffect(() => {
    setAuthToken(localStorage.token);

    if (localStorage.isSpecialist === "true") {
      store.dispatch(loadSpecialist());
    } else store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Whatsapp />
        <div className="flex flex-col" style={{ minHeight: "100vh" }}>
          <Navbar />
          <Switch>
            <Route component={Routes} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};
export default App;
