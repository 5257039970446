import React from "react";
import whatsappImg from "../../img/Whatsapp.png";

const Whatsapp = () => {
  return (
    <a
      id="wsp_button"
      className="fixed"
      style={{ bottom: 25, right: 25, zIndex: 35 }}
      rel="noopener noreferrer"
      target="_blank"
      href="https://api.whatsapp.com/send?phone=51922277597&text=Hola! Tengo una consulta respecto a Higia."
    >
      <div>
        <img
          src={whatsappImg}
          className="w-3/4 h-3/4 sm:w-full sm:h-full"
          alt="whatsapp"
        />
      </div>
    </a>
  );
};

export default Whatsapp;
