const topics = {
  depresion: {
    topicTitle: 'Depresión',
    topicDescription:
      'En este espacio podrás encontrar información respecto a la depresión, como artículos, entrevistas, etc.',
    escuchar: [
      // {
      //   link: 'sfdfds',
      // },
    ],
    bg: 'bg-higia-crema',
    text: 'text-higia-crema',
    previous: '/descubrir/depresion',
    posts: [
      {
        title: '¿Cómo saber si tu niño está deprimido?',
        author: 'Giovany Rivera',
        link: 'como-saber-nino-depresion',
      },
      {
        title: '¿Cómo afrontar la pérdida de un ser querido?',
        author: 'Mari Yanez',
        link: 'como-afrontar-perdida',
      },
    ],
  },
  estres: {
    topicTitle: 'Estrés',
    topicDescription:
      'En este espacio podrás encontrar información respecto a la estrés, como artículos, entrevistas, etc.',
    escuchar: [
      // {
      //   link: 'sfdfds',
      // },
    ],
    bg: 'bg-higia-crema',
    text: 'text-higia-crema',
    previous: '/descubrir/estres',
    posts: [
      {
        title: 'El estrés como respuesta a nuestro malestar',
        author: 'Juan Carlos Guillén',
        link: 'estres-respuesta-malestar',
      },
    ],
  },
  ansiedad: {
    topicTitle: 'Ansiedad',
    topicDescription:
      'En este espacio podrás encontrar información respecto a la ansiedad, como artículos, entrevistas, etc.',
    escuchar: [
      // {
      //   link: 'sfdfds',
      // },
    ],
    bg: 'bg-higia-crema',
    text: 'text-higia-crema',
    previous: '/descubrir/ansiedad',
    posts: [
      {
        title: 'Hablemos sobre el TDAH',
        author: 'Giovany Rivera',
        link: 'hablemos-sobre-tdah',
      },
    ],
  },
  autoestima: {
    topicTitle: 'Autoestima',
    topicDescription:
      'En este espacio podrás encontrar información respecto a la autoestima, como artículos, entrevistas, etc.',
    escuchar: [
      // {
      //   link: 'sfdfds',
      // },
    ],
    bg: 'bg-higia-crema',
    text: 'text-higia-crema',
    previous: '/descubrir/autoestima',
    posts: [
      {
        title: '¿Por qué tener la autoestima es importante?',
        author: 'Kira Serguienko',
        link: 'por-que-autoestima-importante',
      },
    ],
  },
};

export default topics;
