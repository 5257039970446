import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getSpecialistByNombres } from "../../actions/especialistas";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";

const Terapeuta = ({ match, specialists, getSpecialistByNombres }) => {
  useEffect(() => {
    getSpecialistByNombres(match.params.nombres.replace("-", " "));
  }, [getSpecialistByNombres, match.params.nombres]);
  const info = specialists.specialist;

  return specialists.loading || !info ? (
    <Spinner />
  ) : (
    <section
      className="container px-8 flex-grow flex flex-col mx-auto font-sans"
      styles={{ height: "max-content" }}
    >
      <div className="flex items-center m-6">
        <img
          className="h-20 w-20 object-cover rounded-full"
          src={info.avatar}
          alt="Foto de Perfil"
        />
        <div className="ml-4 tracking-wide">
          <h4 className="text-xl font-bold text-higia-gray-3 leading-none my-2">
            <span className="text-higia-cyan">Hola</span>, soy <br />
            {info.nombres}
          </h4>
          {info.codigos
            ? Object.keys(info.codigos)
                .sort()
                .map((inst) => (
                  <div
                    className="text-sm font-bold text-higia-gray-3"
                    key={inst}
                  >
                    {inst + ": " + info.codigos[inst]}
                  </div>
                ))
            : null}
          <div className="mt-2 flex items-center text-higia-bermuda">
            <svg className="h5 w-5 fill-current" viewBox="0 0 24 24">
              <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z" />
            </svg>
            <svg className="h5 w-5 fill-current" viewBox="0 0 24 24">
              <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z" />
            </svg>
            <svg className="h5 w-5 fill-current" viewBox="0 0 24 24">
              <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z" />
            </svg>
            <svg className="h5 w-5 fill-current" viewBox="0 0 24 24">
              <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z" />
            </svg>
            <svg className="h5 w-5 fill-current" viewBox="0 0 24 24">
              <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z" />
            </svg>
          </div>
        </div>
      </div>
      <div className="m-6 mt-2">
        <div className="flex flex-col">
          {info.desc
            ? info.desc.split("\n").map((bullet, id) => (
                <div className="flex items-baseline" key={id}>
                  <div className="w-8 mr-1">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C9.48912 0 11.3968 0.790176 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5ZM11.2781 4.65937C11.2112 4.59264 11.1314 4.5401 11.0437 4.50489C10.9559 4.46968 10.862 4.45252 10.7675 4.45445C10.673 4.45637 10.5798 4.47734 10.4936 4.5161C10.4073 4.55485 10.3298 4.6106 10.2656 4.68L7.00969 8.82844L5.0475 6.86531C4.91421 6.74111 4.73792 6.6735 4.55576 6.67671C4.3736 6.67992 4.1998 6.75372 4.07098 6.88254C3.94215 7.01137 3.86836 7.18517 3.86515 7.36732C3.86193 7.54948 3.92955 7.72577 4.05375 7.85906L6.53437 10.3406C6.6012 10.4073 6.68078 10.4599 6.76836 10.4952C6.85594 10.5305 6.94973 10.5477 7.04414 10.546C7.13854 10.5442 7.23163 10.5235 7.31784 10.485C7.40405 10.4465 7.48163 10.391 7.54594 10.3219L11.2884 5.64375C11.416 5.51109 11.4865 5.3337 11.4848 5.14965C11.483 4.9656 11.4092 4.78958 11.2791 4.65937H11.2781Z"
                        fill="#BDD6ED"
                      />
                    </svg>
                  </div>
                  <div className="-mt-4">{bullet}</div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="flex justify-between mt-4 mb-6">
        <Link
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to="/terapeutas"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
            style={{ transform: "scale(-1, 1)" }}
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
        <Link
          className="w-24 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to={`/terapeutas/${info.nombres.replace(" ", "-")}/horarios`}
        >
          <span className="text-white font-semibold text-lg">Continuar</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
      </div>

      <div className="m-8 mt-2">
        <div className="font-bold">Ellos confian en mí:</div>
        <ul className="list-disc">
          {info.testimonios
            ? info.testimonios.map((test, ind) => (
                <div
                  key={ind}
                  className="bg-white p-6 rounded-lg shadow-lg mt-4 mb-8"
                >
                  <div className="flex items-center">
                    <div className="ml-4 tracking-wide w-full">
                      <div className="flex items-baseline justify-end text-higia-bermuda">
                        <svg
                          className="h5 w-5 fill-current wavesShadow"
                          viewBox="0 0 32 29.6"
                        >
                          <path
                            d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                          />
                        </svg>
                      </div>
                      <p className="text-sm whitespace-pre-wrap">{test}</p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </ul>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
});

export default connect(mapStateToProps, { getSpecialistByNombres })(Terapeuta);
