import { CHANGE_NAV_COLORS, CHANGE_NAV_LOGO } from './types';

export const changeNavColors = (bg) => (dispatch) => {
  dispatch({
    type: CHANGE_NAV_COLORS,
    payload: { bg },
  });
};

export const changeNavLogo = ({ previous, showLogo }) => (dispatch) => {
  dispatch({
    type: CHANGE_NAV_LOGO,
    payload: { previous, showLogo },
  });
};
