export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const SPECIALIST_LOADED = "SPECIALIST_LOADED";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const MODIFY_CLIENT_SUCCESS = "MODIFY_CLIENT_SUCCESS";
export const MODIFY_SPECIALIST_SUCCESS = "MODIFY_SPECIALIST_SUCCESS";
export const MODIFY_FAIL = "MODIFY_FAIL";
export const GET_SPECIALISTS = "GET_SPECIALISTS";
export const GET_SPECIALIST = "GET_SPECIALIST";
export const GET_ENCUESTA = "GET_ENCUESTA";
export const POST_ENCUESTA = "POST_ENCUESTA";
export const SPECIALISTS_ERROR = "SPECIALISTS_ERROR";
export const SPECIALIST_ERROR = "SPECIALIST_ERROR";
export const ENCUESTA_ERROR = "ENCUESTA_ERROR";
export const SELECT_DAY = "SELECT_DAY";
export const REGISTER_SESSION_SUCCESS = "REGISTER_SESSION_SUCCESS";
export const REGISTER_SESSION_FAILURE = "REGISTER_SESSION_FAILURE";
export const UPSERT_APUNTE_SUCCESS = "UPSERT_APUNTE_SUCCESS";
export const CHANGE_NAV_COLORS = "CHANGE_NAV_COLORS";
export const CHANGE_NAV_LOGO = "CHANGE_NAV_LOGO";
export const SET_CAME_FROM = "SET_CAME_FROM";
export const GET_INITIAL_ENCUESTA = "GET_INITIAL_ENCUESTA";
