import api from "../utils/api";
import { setAlert } from "../actions/alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  SPECIALIST_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  MODIFY_CLIENT_SUCCESS,
  MODIFY_SPECIALIST_SUCCESS,
  MODIFY_FAIL,
  UPSERT_APUNTE_SUCCESS,
  SET_CAME_FROM,
} from "./types";

// Load Client
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/clients");
    localStorage.setItem("isSpecialist", "false");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Came from
export const setCameFrom = (cameFrom) => async (dispatch) => {
  dispatch({
    type: SET_CAME_FROM,
    payload: cameFrom,
  });
};

// Register Client
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/clients", formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Modify Client
export const modifyClient = (data) => async (dispatch) => {
  try {
    const res = await api.post("/clients/profile", data);

    dispatch({
      type: MODIFY_CLIENT_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MODIFY_FAIL,
    });
  }
};

// Login Client
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth/clients", body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Upsert Client apuntes
export const upsertApuntes = (data) => async (dispatch) => {
  try {
    const res = await api.post("/clients/apuntes", data);

    dispatch({
      type: UPSERT_APUNTE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MODIFY_FAIL,
    });
  }
};

// Load Specialist
export const loadSpecialist = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/specialists");
    localStorage.setItem("isSpecialist", "true");

    dispatch({
      type: SPECIALIST_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login Specialist
export const loginSpecialist = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth/specialists", body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadSpecialist());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Modify Specialist
export const modifySpecialist = (data) => async (dispatch) => {
  try {
    const res = await api.post("/specialists/profile", data);

    dispatch({
      type: MODIFY_SPECIALIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MODIFY_FAIL,
    });
  }
};

// Logout
export const logout = () => ({ type: LOGOUT });
