/* eslint-disable no-undef */
// ^ needed for global import of jitsy

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { parseISO, isWithinInterval, format, add, isAfter } from "date-fns";
import { es } from "date-fns/locale";

const Sesions = ({ match, auth }) => {
  const user = auth.user;

  const currentSession = user.sessions.filter(
    (session) => session._id === match.params.sesionId
  )[0];

  const showMeetLink = isWithinInterval(new Date(), {
    start: add(parseISO(currentSession.horaPactada), { minutes: -5 }),
    end: add(parseISO(currentSession.horaPactada), { minutes: 200 }),
  });

  const isPaid = currentSession.estado === "pagado";

  return (
    <>
      <div className="w-9/12 mx-auto">
        <div className="mt-8 md:mt-32 lg:mt-40 mx-auto">
          <svg
            width="75"
            height="75"
            viewBox="0 0 75 75"
            fill="none"
            className="mx-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.4625 0C16.7625 0 0 16.8 0 37.5C0 58.2 16.7625 75 37.4625 75C58.2 75 75 58.2 75 37.5C75 16.8 58.2 0 37.4625 0ZM37.5 67.5C20.925 67.5 7.5 54.075 7.5 37.5C7.5 20.925 20.925 7.5 37.5 7.5C54.075 7.5 67.5 20.925 67.5 37.5C67.5 54.075 54.075 67.5 37.5 67.5Z"
              fill="#55D1F8"
            />
            <path
              d="M35.9376 17.2554H30.8425V37.6358L48.6754 48.3355L51.223 44.1575L35.9376 35.0883V17.2554Z"
              fill="#55D1F8"
            />
          </svg>
        </div>

        {isPaid ? (
          showMeetLink ? (
            <div className="flex flex-col justify-center">
              <div className="text-center mt-12 text-xl font-bold text-higia-gray-3">
                La cita está por comenzar
              </div>
              <a
                href={`https://meet.jit.si/${currentSession._id}`}
                target="_blank"
                className="mt-8 mx-auto font-bold w-6/12 md:w-40 text-center rounded-lg px-auto py-4 bg-higia-bermuda text-white cursor-pointer text-lg"
              >
                Ingresar
              </a>
            </div>
          ) : (
            <>
              <div className="text-center mt-8 text-higia-gray-3 md:w-8/12 lg:w-6/12 mx-auto font-bold text-2xl">
                Cinco minutos antes de que inicie la sesión, se habilitará la
                sala para la videollamada.
              </div>
              <div className="font-bold text-lg text-center mt-6 mb-4">
                Te recordamos que tu sesión es el*:
              </div>
              <div className="text-center mt-1 font-bold text-2xl">
                {format(parseISO(currentSession.horaPactada), "d 'de' LLLL", {
                  locale: es,
                })}
              </div>
              <div className="text-center font-bold text-2xl">
                {format(parseISO(currentSession.horaPactada), "hh:mm aaaa", {
                  locale: es,
                })}
              </div>
            </>
          )
        ) : (
          <>
            <div className="text-center mt-8 text-red-400 md:w-8/12 lg:w-6/12 mx-auto font-bold text-2xl">
              El pago de esta sesión todavía no está confirmado, por favor
              envíanos el comprobante del pago a nuestro &nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                className="underline"
                href="https://api.whatsapp.com/send?phone=51922277597&text=Hola, ya realicé el pago de mi paquete."
              >
                Whatsapp
              </a>
              &nbsp; para poder habilitar la sala.
            </div>
            <div className="flex justify-center">
              <Link
                to={
                  currentSession.reagendo ||
                  isAfter(
                    new Date(),
                    add(parseISO(currentSession.horaPactada), {
                      minutes: -60 * 3,
                    })
                  )
                    ? "/home"
                    : `/reagendar/${match.params.sesionId}`
                }
                className={`block mt-8 font-bold mr-2 w-6/12 md:w-40 md:mr-8 text-center text-xl rounded-lg px-auto py-4 ${
                  currentSession.reagendo ||
                  isAfter(
                    new Date(),
                    add(parseISO(currentSession.horaPactada), {
                      minutes: -60 * 3,
                    })
                  )
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-higia-bermuda"
                } text-white cursor-pointer text-lg`}
              >
                Reagendar
              </Link>
              <Link
                to="/home"
                className="mt-8 font-bold ml-2 w-6/12 md:w-40 md:ml-8 text-center rounded-lg px-auto py-4 bg-higia-bermuda text-white cursor-pointer text-lg"
              >
                Regresar
              </Link>
            </div>
          </>
        )}

        {auth.isSpecialist ? (
          <div className="text-center mt-8">
            El/la paciente dejó el siguiente mensaje: <br />
            {currentSession.motivo}
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Sesions);
