import React, { useEffect } from 'react';
import { changeNavColors, changeNavLogo } from '../../../actions/nav';
import { connect } from 'react-redux';
import topics from '../TopicsIndex';

const PrimerPost = ({ match, changeNavColors, changeNavLogo }) => {
  const topic = match.params.topic;
  const post = topics[topic].posts.filter(
    (post) => post.link === 'como-afrontar-perdida'
  )[0];

  useEffect(() => {
    changeNavColors(topics[topic].bg);
    changeNavLogo({ showLogo: false, previous: topics[topic].previous });
    return () => {
      changeNavColors('');
      changeNavLogo({ showLogo: true, previous: '' });
    };
  }, [changeNavColors, changeNavLogo, topic]);

  return (
    <div className='container mx-auto md:w-6/12'>
      <div className='mx-8 md:mx-0'>
        <div className='font-bold mt-6 text-lg'>{post.title}</div>
        <div className='flex items-center justify-between mt-4'>
          <div className='h-16 w-16 flex justify-center items-center rounded-full bg-higia-bermuda'>
            {post.img ?? (
              <svg
                height='25'
                viewBox='0 0 25 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M21.8751 1.62754H3.12585C2.71147 1.62754 2.31406 1.79901 2.02104 2.10424C1.72803 2.40946 1.56342 2.82343 1.56342 3.25508V22.1118L4.68829 18.8567C5.27419 18.2462 6.06888 17.9031 6.89758 17.9029H21.8751C22.2895 17.9029 22.6869 17.7315 22.9799 17.4263C23.2729 17.121 23.4376 16.7071 23.4376 16.2754V3.25508C23.4376 2.82343 23.2729 2.40946 22.9799 2.10424C22.6869 1.79901 22.2895 1.62754 21.8751 1.62754ZM3.12585 0C2.29709 0 1.50226 0.342945 0.916232 0.953391C0.330204 1.56384 0.000976563 2.39178 0.000976562 3.25508L0.000976563 24.0762C0.0010094 24.2372 0.0469007 24.3946 0.132842 24.5285C0.218784 24.6623 0.340912 24.7666 0.483769 24.8281C0.626627 24.8896 0.783791 24.9056 0.935371 24.874C1.08695 24.8424 1.22613 24.7647 1.3353 24.6507L5.79294 20.0074C6.08588 19.7021 6.48323 19.5306 6.89758 19.5305H21.8751C22.7039 19.5305 23.4987 19.1875 24.0847 18.5771C24.6708 17.9666 25 17.1387 25 16.2754V3.25508C25 2.39178 24.6708 1.56384 24.0847 0.953391C23.4987 0.342945 22.7039 0 21.8751 0L3.12585 0Z'
                  fill='black'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4.68823 5.69658C4.68823 5.48076 4.77054 5.27377 4.91705 5.12116C5.06355 4.96855 5.26226 4.88281 5.46945 4.88281H19.5314C19.7386 4.88281 19.9373 4.96855 20.0838 5.12116C20.2303 5.27377 20.3126 5.48076 20.3126 5.69658C20.3126 5.91241 20.2303 6.11939 20.0838 6.27201C19.9373 6.42462 19.7386 6.51035 19.5314 6.51035H5.46945C5.26226 6.51035 5.06355 6.42462 4.91705 6.27201C4.77054 6.11939 4.68823 5.91241 4.68823 5.69658ZM4.68823 9.76543C4.68823 9.54961 4.77054 9.34262 4.91705 9.19001C5.06355 9.0374 5.26226 8.95166 5.46945 8.95166H19.5314C19.7386 8.95166 19.9373 9.0374 20.0838 9.19001C20.2303 9.34262 20.3126 9.54961 20.3126 9.76543C20.3126 9.98126 20.2303 10.1882 20.0838 10.3409C19.9373 10.4935 19.7386 10.5792 19.5314 10.5792H5.46945C5.26226 10.5792 5.06355 10.4935 4.91705 10.3409C4.77054 10.1882 4.68823 9.98126 4.68823 9.76543ZM4.68823 13.8343C4.68823 13.6185 4.77054 13.4115 4.91705 13.2589C5.06355 13.1063 5.26226 13.0205 5.46945 13.0205H13.2816C13.4888 13.0205 13.6875 13.1063 13.8341 13.2589C13.9806 13.4115 14.0629 13.6185 14.0629 13.8343C14.0629 14.0501 13.9806 14.2571 13.8341 14.4097C13.6875 14.5623 13.4888 14.6481 13.2816 14.6481H5.46945C5.26226 14.6481 5.06355 14.5623 4.91705 14.4097C4.77054 14.2571 4.68823 14.0501 4.68823 13.8343Z'
                  fill='black'
                />
              </svg>
            )}
          </div>
          <div className='flex flex-col ml-5 flex-grow'>
            <div>{post.author}</div>
            <div>Psicóloga</div>
          </div>
        </div>
        <div className='mt-6 mb-8 prose'>
          {/* start */}
          <p>
            El duelo es una reacción (emocional, cognitiva y comportamental)
            natural y normal que permite adaptarnos ante la pérdida, nos ayuda
            restablecer el equilibrio en nuestra vida. No existe un sentimiento
            único sino una serie de emociones, preocupaciones y etapas que la
            persona necesitará vivir para lograr aliviar su dolor.
          </p>
          <p>
            La finalidad de un duelo es dar expresión y cauce sano a los
            sentimientos, serenar el sufrimiento dominando la pena de la
            separación, aceptar la realidad de la muerte y amar con un nuevo
            lenguaje de amor al fallecido. (Grau, 2013)
          </p>
          <h3 id='si-usted-está-viviendo-una-pérdida'>
            Si usted está viviendo una pérdida:
          </h3>
          <ul>
            <li>
              <p>
                Primero mi más sentido pésame, quiero que recuerde que a pesar
                que el dolor que está sintiendo intenso poco a poco irá
                disminuyendo.
              </p>
            </li>
            <li>
              <p>
                Exprese sus emociones como se le haga más conveniente, quizás
                hablando con alguien y si no desea hablar puede escribir,
                pintar, escuchar música, etc.
              </p>
            </li>
            <li>
              <p>
                No hay una manera única de vivir el duelo, puede que quieras
                quedarte acostado todo el día, que no quieras hacer nada, que
                quieras estar solo(a) y llorar, etc. Sin embargo, recuerda que
                puedes contar con el apoyo de la familia, amigos que pueden
                ayudarte y acompañarte en este momento porque ellos también se
                preocupan por tu bienestar.
              </p>
            </li>
            <li>
              <p>
                Aunque en el momento las actividades que realiza puedan verse
                pausadas es importante reiniciarlas en el lapso de 3 a 6 semanas
              </p>
            </li>
            <li>
              <p>No tomar decisiones importantes en medio de este proceso.</p>
            </li>
            <li>
              <p>
                No recurrir al alcohol o las drogas como formas de consuelo.
              </p>
            </li>
            <li>
              <p>
                Permitir que las personas que queremos nos ayuden porque
                recuerda que ellos también se preocupan por ti
              </p>
            </li>
            <li>
              <p>
                Muchas veces no tuvimos la oportunidad de despedirnos por ello
                puedes escribirle a la persona fallecida, le puedes decir que lo
                perdonas o le pides perdón o le agradecer por lo que te ha
                enseñado y finalmente le puedes escribir que es lo que harás
                para seguir adelante.
              </p>
            </li>
          </ul>
          <h3 id='si-usted-quiere-ayudar-a-una-persona-que-está-viviendo-una-pérdida'>
            Si usted quiere ayudar a una persona que está viviendo una pérdida:
          </h3>
          <ul>
            <li>
              <p>
                Sea empático, comprenda que la situación que está viviendo la
                persona es difícil como para juzgarlo.
              </p>
            </li>
            <li>
              <p>
                Escúchelo, acompáñelo y ofrézcale ayuda, no trate de presionar a
                la persona para salir del duelo.
              </p>
            </li>
            <li>
              <p>
                Muchas veces no sabemos que palabras utilizar y caemos en un
                error tratando de calmar a la persona con frases como: “ánimo “o
                “es la ley de la vida “”no llores porque si lloras no lo dejas
                descansar”, en cambio puedes decirle: “Comprendo el dolor que
                puedes estar sintiendo y quiero que sepas que estoy aquí para
                acompañarte y ayudarte si así lo deseas”.
              </p>
            </li>
            <li>
              <p>
                Algunas veces no se necesitan palabras, un simple abrazo o una
                mano en el hombro puede hacerle saber a la persona que estás ahí
                para brindarle soporte. Respeta si la persona habla mucho acerca
                de lo sucedido, muchas veces eso le ayuda a reprocesar el
                evento, o quizas no desea hablar en ambos casos debemos respetar
                la forma en la que desea vivir su duelo.
              </p>
            </li>
          </ul>
          <p>
            Lo que se busca con el apoyo psicológico es que la persona pueda
            establecer nuevos vínculos con el fallecido aceptando su partida.
          </p>

          {/* end */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { changeNavColors, changeNavLogo })(
  PrimerPost
);
