import {
  SELECT_DAY,
  REGISTER_SESSION_SUCCESS,
  REGISTER_SESSION_FAILURE,
} from '../actions/types';

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECT_DAY:
      return { ...state, ...payload };
    case REGISTER_SESSION_SUCCESS:
      return { ...state, success: true };
    case REGISTER_SESSION_FAILURE:
      return { ...state, success: false };
    default:
      return state;
  }
}
