import {
  GET_ENCUESTA,
  ENCUESTA_ERROR,
  GET_INITIAL_ENCUESTA,
} from "../actions/types";

const initialState = {
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ENCUESTA:
      return { ...state, survey: payload, loading: false };
    case GET_INITIAL_ENCUESTA:
      return { ...state, initialSurvey: payload, loading: false };
    case ENCUESTA_ERROR:
      return { ...state };
    default:
      return state;
  }
}
