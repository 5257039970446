import React from 'react';

const Backdrop = (props) => {
  let classes = 'fixed top-0 left-0 w-full h-full z-30 ';

  if (props.darken) {
    classes = classes + 'bg-gray-400 bg-opacity-75 ';
  }

  if (props.className) {
    classes = classes + props.className;
  }

  return <div className={classes} onClick={props.onClick}></div>;
};

export default Backdrop;
