import React, { useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateCheckout } from "../../actions/checkout";
import Spinner from "../layout/Spinner";
import Progress from "./Progress";
import "./Calendar.css";
import api from "../../utils/api";

const Casiacabamos = ({ match, checkout, updateCheckout, specialists }) => {
  useEffect(() => {
    api.post("/sessions/clientAlmostFinished");
  }, []);

  const motivoRef = useRef(null);
  const medioPrefRef = useRef(null);
  const servicioRef = useRef(null);
  const info = specialists.specialist;

  if (Object.keys(checkout).length === 0) {
    return <Redirect to="/terapeutas" />;
  }
  const completed = () => {
    updateCheckout({
      motivo: motivoRef.current.innerText,
      servicio: servicioRef.current?.value ?? Object.keys(info.tarifas)[0],
      medioPref: medioPrefRef.current.value,
      tarifa:
        info.tarifas[
          servicioRef.current?.value ?? Object.keys(info.tarifas)[0]
        ],
    });
  };

  return specialists.loading || !info ? (
    <Spinner />
  ) : (
    <section
      className="container px-8 pt-8 flex-grow flex flex-col mx-auto font-sans"
      styles={{ height: "max-content" }}
    >
      <span className="font-bold mb-2">3. Ya casi acabamos</span>
      <Progress selected={2} />
      <div className="font-bold text-lg mt-8 mb-6">
        Por favor completa esta información para continuar.
      </div>

      <div className="flex flex-col text-higia-gray-3">
        <div className="font-bold">¿Cómo podemos ayudarte?</div>
        <div className="font-bold">Cuéntanos tu caso acá: (Opcional)</div>
        <div
          ref={motivoRef}
          tabIndex="0"
          className="overflow-auto p-2 shadow flex-auto outline-none mt-2 whitespace-pre-wrap border-higia-gray-2 border rounded"
          role="textbox"
          style={{ minHeight: "12rem" }}
          contentEditable
        ></div>
      </div>

      <div className="flex flex-col text-higia-gray-3 mt-6">
        <div className="font-bold mb-2">¿Cómo te gustaría tomar la sesión?</div>
        <select
          ref={medioPrefRef}
          name="medio"
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="videollamada">Videollamada</option>
          <option value="llamada">Llamada</option>
        </select>
      </div>

      {Object.keys(info.tarifas).length >= 2 ? (
        <div className="flex flex-col text-higia-gray-3 mt-6">
          <div className="font-bold mb-2">
            ¿Qué tipo de servicio te gustaría tomar?
          </div>
          <select
            ref={servicioRef}
            name="servicio"
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            {Object.keys(info.tarifas)
              .sort()
              .map((key) => (
                <option
                  key={key}
                  value={key}
                >{`${key}: S/ ${info.tarifas[key]}`}</option>
              ))}
          </select>
        </div>
      ) : null}

      <div className="flex justify-between mt-4 mb-10 mt-8">
        <Link
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to={`/terapeutas/${match.params.id}/horarios`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
            style={{ transform: "scale(-1, 1)" }}
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
        <Link
          className="w-20 text-white p-2 rounded flex justify-center bg-higia-bermuda"
          to="/pagoseguro"
          onClick={() => completed()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 12"
            className="fill-current "
            width="12"
            height="18"
          >
            <path
              d="M2.173 1l4.584 4.725-4.615 4.615-1.103-1.103 3.512-3.512L1 2.173
          2.173 1z"
            />
          </svg>
        </Link>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  specialists: state.specialists,
  user: state.auth.user,
  checkout: state.checkout,
});

export default connect(mapStateToProps, { updateCheckout })(Casiacabamos);
